import React, { useState } from "react";

type Props = {
  children: string;
};
const ReadMore = ({ children }: Props) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 500) : text}
      <span
        onClick={toggleReadMore}
        className="read-or-hide"
        style={{ color: "green", cursor: "pointer" }}
      >
        {text.length > 500 ? (isReadMore ? "...Read more" : " Show less") : ""}
      </span>
    </p>
  );
};

export default ReadMore;
