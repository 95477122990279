import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ServiceList from "./service-list";
import Header from "../../components/core/header";
import ServiceCard from "./service-card";
import ServiceCardEdit from "./service-card-edit";
import { setRole, setBookingStoreDetails, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import Addservice from "./add-service";
import { ToastContainer, toast } from "react-toastify";
import SearchList from "./search-list";
import "./index.scss";
import MenuIcon from "@mui/icons-material/Menu";
import { theme } from "../../utils/theme";

const backArrow =
  require("../../assets/icons/icon-arrow-back-circle-outline.svg").default;

interface Category {
  id: string;
  name: string;
  short_description: string;
  description: string;
  image_url: string;
}

interface Service {
  id: string;
  name: string;
  short_description: string;
  description: string;
  image_url: string;
}

const ServicePage = (props: any) => {
  const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useSelector((state: RootState) => state.role);
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const userDetail = useSelector((state: RootState) => state.userDetail);
  const [previewCart, setPreviewCart] = useState<boolean>(false);
  const service = sessionStorage.getItem("service");
  const [selectedService, setSelectedService] = useState<null | any>("");
  const [editService, setEditService] = useState<boolean>(false);
  const [addService, setAddService] = useState<boolean>(false);
  const [bookingDetails, setBookingDetails] = useState<any | null>(null);
  const [selectedServiceId, setSelectedServiceId] = useState<string | null>("");
  const [activeCategory, setActiveCategory] = useState<Category | null>(null);

  useEffect(() => {
    if (service) {
      let services = JSON.parse(service);
      setSelectedService(services);
    }
  }, []);

  const serviceSearchText = useSelector(
    (state: RootState) => state.searchService
  );
  const [isSearch, setIsSearch] = useState<boolean>(
    serviceSearchText ? true : false
  );
  const [searchText, setSearchText] = useState(
    serviceSearchText ? serviceSearchText : ""
  );
  const [searchServicesList, setSearchServicesList] = useState<
    Service[] | null
  >(null);
  const [finalSearchValue, setFinalSearchValue] = useState(
    serviceSearchText ? serviceSearchText : ""
  );

  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const ActionButton = styled(Button)`
    margin-left: 20px;
    text-align: left;
    height: 48px;
    color: #ffffff;
    background: #71a187;
    font-size: 16px;
    font: Kufam;
    font-weight: 600;
    border-radius: 30px;
    padding: 10px 20px;
    &:hover {
      background: #568a60; /* Change the background color on hover */
    }

    &:active {
      background: #718875; /* Change the background color on click */
    }
  `;

  const addToCartService = async (accessToken: string) => {
    try {
      let url: string = `${apiEndpoint}/bookings/`;

      const requestBody: any = {
        services: [selectedServiceId],
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      if (data) {
        getCartDetails(accessToken);
      } else {
        throw new Error("Error in booking service");
      }
    } catch (error) {
      console.error("Error fetching Services:", error);
    }
  };

  const getCartDetails = async (accessToken: string) => {
    try {
      let url: string = `${apiEndpoint}/bookings/${
        userDetail?.id ? userDetail.id : "1234"
      }/get_booking_cart`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();

      if (data) {
        dispatch(setBookingStoreDetails(data));
        setBookingDetails(data);
      } else {
        throw new Error("Error in booking service");
      }
    } catch (error) {
      console.error("Error fetching Services:", error);
    }
  };

  useEffect(() => {
    const role = sessionStorage.getItem("role");
    const accessToken = sessionStorage.getItem("access_token");
    if (accessToken) {
      dispatch(setRole(role ? role : ""));
      addToCartService(accessToken);
    }
  }, [addService]);

  useEffect(() => {
    const accessToken = sessionStorage.getItem("access_token");
    let serviceFromSession: any = sessionStorage.getItem("service");
    if (accessToken && serviceFromSession) {
      serviceFromSession = JSON.parse(serviceFromSession);
      setSelectedService(serviceFromSession);
      sessionStorage.removeItem("service");
    }
  }, []);

  const isMobileScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box sx={{ px: isAuthenticated ? 0 : 3, mb: 8 }}>
      <ToastContainer />
      <Header>
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "5px 0px",
              columnGap: "5px",
              width: "100%",
            }}
          >
            {!selectedService && !editService && !addService && (
              <React.Fragment>
                <Typography
                  component="div"
                  sx={{
                    fontSize: isMobileScreen ? "32px" : "25px",
                    color: "#000000",
                  }}
                >
                  Services
                </Typography>
              </React.Fragment>
            )}
            {selectedService && !editService && !addService && (
              <React.Fragment>
                <Typography
                  component="div"
                  sx={{
                    fontSize: isMobileScreen ? "32px" : "25px",
                    color: "#000000",
                  }}
                >
                  <img
                    src={backArrow}
                    onClick={() => {
                      setSelectedService(null);
                      sessionStorage.removeItem("service");
                    }}
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      top: "9px",
                      right: "8px",
                    }}
                    alt=""
                  />
                  Services / {selectedService.name}
                </Typography>
              </React.Fragment>
            )}

            {editService && (
              <React.Fragment>
                <Typography
                  component="div"
                  sx={{ fontSize: "32px", color: "#000000" }}
                >
                  <img
                    src={backArrow}
                    onClick={() => {
                      setSelectedService(null);
                      setEditService(false);
                    }}
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      top: "9px",
                      right: "8px",
                    }}
                    alt=""
                  />
                  Services / {selectedService.title} / Edit
                </Typography>
                <Typography
                  component="div"
                  sx={{ fontSize: "16px", color: "#A7A7A7" }}
                >
                  Create new Service
                </Typography>
              </React.Fragment>
            )}

            {(addService || previewCart) && (
              <React.Fragment>
                <Typography
                  component="div"
                  sx={{ fontSize: "32px", color: "#000000" }}
                >
                  <img
                    src={backArrow}
                    onClick={() => {
                      if (previewCart) {
                        setPreviewCart(false);
                      } else {
                        setAddService(false);
                      }
                    }}
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      top: "9px",
                      right: "8px",
                    }}
                    alt=""
                  />
                  Bookings
                </Typography>
                {!previewCart && (
                  <Typography
                    component="div"
                    sx={{ fontSize: "16px", color: "#A7A7A7" }}
                  >
                    Add details for the services requested
                  </Typography>
                )}
              </React.Fragment>
            )}
          </Box>
          <Box
            sx={{
              float: "right",
              marginLeft: "auto",
              paddingY: "10px",
              alignItems: "flex-end",
            }}
          >
            {role === "user" &&
              isAuthenticated &&
              bookingDetails &&
              bookingDetails.service_list &&
              bookingDetails.service_list.length > 0 && (
                <IconButton
                  color="inherit"
                  sx={{
                    // width: "70px",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "transparent !important",
                    },
                    position: "absolute",
                    right: "1.5rem"
                  }}
                  onClick={() => {
                    if (bookingDetails.service_list.length > 0) {
                      setAddService(true);
                    } else {
                      toast.warning(
                        `Cart is empty. Add services to view cart`,
                        {
                          position: "top-right",
                          autoClose: 5000,
                        }
                      );
                    }
                  }}
                >
                  <Badge
                    badgeContent={
                      bookingDetails &&
                      bookingDetails.service_list &&
                      bookingDetails.service_list.length > 0
                        ? bookingDetails.service_list.length
                        : 0
                    }
                    color="primary"
                  >
                    <ShoppingCartIcon sx={{ color: "#666666" }} />
                  </Badge>
                </IconButton>
              )}
          </Box>
        </Box>
      </Header>

      {!selectedService && !editService && !addService && (
        <Grid container sx={{ mt: 3, mb: 1 }}>
          <SearchList
            setSelectedService={setSelectedService}
            isSearch={isSearch}
            setIsSearch={setIsSearch}
            searchText={searchText}
            setSearchText={setSearchText}
            searchServicesList={searchServicesList}
            setSearchServicesList={setSearchServicesList}
            finalSearchValue={finalSearchValue}
            setFinalSearchValue={setFinalSearchValue}
          />

          <ServiceList
            selectedService={selectedService}
            setSelectedService={setSelectedService}
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
          />
        </Grid>
      )}

      {selectedService && !editService && !addService && (
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
        >
          <ServiceCard
            selectedService={selectedService}
            setAddService={setAddService}
            setSelectedServiceId={setSelectedServiceId}
            handleOpen={handleOpen}
            setSelectedService={setSelectedService}
            activeCategory={activeCategory}
          />
        </Grid>
      )}

      {editService && !addService && (
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
        >
          <ServiceCardEdit
            selectedService={selectedService}
            setSelectedService={setSelectedService}
            setEditService={setEditService}
          />
        </Grid>
      )}

      {addService && (
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 3,
            width: "100%",
          }}
        >
          <Addservice
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            setSelectedService={setSelectedService}
            setAddService={setAddService}
            getCartDetails={getCartDetails}
            setEditService={setEditService}
            previewCart={previewCart}
            setPreviewCart={setPreviewCart}
            setSelectedServiceId={setSelectedServiceId}
          />
        </Grid>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        maxWidth="sm"
      >
        <DialogContent sx={{ textAlign: "center", padding: "50px" }}>
          <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <span>Signup or Login to add services to cart</span>
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Button
              variant="outlined"
              type="button"
              onClick={handleClose}
              sx={{ borderRadius: "30px", px: 0, py: '0.5rem', m: '0.5rem', width: '120px', fontWeight: 'normal', height: '2.5rem', fontSize: '1rem' }}
            >
              Cancel
            </Button>
            <ActionButton
              sx={{ borderRadius: "30px", px: 0, py: '0.5rem', m: '0.5rem', width: '120px', fontWeight: 'normal', height: '2.5rem', fontSize: '1rem' }}
              onClick={() => {
                navigate("/login");
                sessionStorage.removeItem("service");
              }}
            >
              Proceed
            </ActionButton>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ServicePage;
