import {
  Box,
  Fade,
  Modal,
  Typography,
  Button,
  OutlinedInput,
} from "@mui/material";
import ReadMore from "../../components/shared/readmore-readless";
import { GetQuotationResponse } from "../../types";

type Props = {
  viewQuotation: boolean;
  setViewQuotation: (view: false) => void;
  style: object;
  data: GetQuotationResponse[] | null;
};

export default function ViewQuotationModal({
  viewQuotation,
  setViewQuotation,
  style,
  data,
}: Props) {
  const quotationDetails = data?.map((item) => item.quotation_details);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={viewQuotation}
      onClose={() => setViewQuotation(false)}
      closeAfterTransition
      style={{ overflow: "auto" }}
    >
      <Fade in={viewQuotation}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Quotation
          </Typography>

          <Box>
            <Box
              sx={{
                display: "grid",
                columnGap: 1,
                rowGap: 1,
                gridTemplateColumns: "repeat(4, 4fr)",
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#1F261F",
                  textAlign: "center",
                }}
              >
                Item
              </Typography>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#1F261F",
                  textAlign: "center",
                }}
              >
                Price
              </Typography>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#1F261F",
                  textAlign: "center",
                }}
              >
                Quantity
              </Typography>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#1F261F",
                  textAlign: "center",
                }}
              >
                Total
              </Typography>
            </Box>

            {quotationDetails?.map((item, i) =>
              item.map((allValues) => {
                return (
                  <Box
                    sx={{
                      display: "grid",
                      columnGap: 1,
                      rowGap: 1,
                      gridTemplateColumns: "repeat(4, 4fr)",
                      mt: 2,
                    }}
                  >
                    <OutlinedInput
                      name="item"
                      sx={{ height: 30 }}
                      value={allValues.item}
                      disabled
                    />
                    <OutlinedInput
                      name="unit_price"
                      sx={{ height: 30 }}
                      value={allValues.unit_price}
                      disabled
                    />
                    <OutlinedInput
                      name="quantity"
                      sx={{ height: 30 }}
                      value={allValues.quantity}
                      disabled
                    />
                    <OutlinedInput
                      name="total"
                      sx={{ height: 30 }}
                      value={allValues.total}
                      disabled
                    />
                  </Box>
                );
              })
            )}
          </Box>
          <Box sx={{ display: "flex", columnGap: "8px", marginTop: "8px" }}>
            <OutlinedInput
              type="text"
              sx={{ height: 30 }}
              value={"Tax"}
              disabled
            />
            <OutlinedInput
              name="taxes"
              sx={{ height: 30 }}
              type="number"
              value={data?.map((item) => item.taxes)}
              disabled
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 400,
                mt: 2,
                mb: 2,
              }}
            >
              {data?.map((item) => {
                if (item.total)
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        float: "right",
                      }}
                    >
                      {`Total: ₹${item.total + item.taxes}`}
                    </Box>
                  );
              })}
            </Typography>
            <Box
              sx={{
                gridRow: "1",
                gridColumn: "span 5",
                display: "flex",
                flexDirection: "column",
                rowGap: 2,
              }}
            >
              <Typography sx={{ fontSize: "16px" }}>Comments</Typography>
              <Box sx={{ fontSize: 14, color: "#a6a6a6", lineHeight: "25px" }}>
                {data ? <ReadMore>{data[0]?.notes}</ReadMore> : ""}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                columnGap: 2,
                justifyContent: "space-between",
                marginLeft: "auto",
                mt: 4,
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setViewQuotation(false);
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
