import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";
import { Button, DialogTitle, Typography } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  ImageSliderModalProps,
  formatedImages,
} from "../../../types/imageSlider";

const ImageSliderModal: React.FC<ImageSliderModalProps> = (
  props: ImageSliderModalProps
) => {
  const {
    isImageSliderModalOpen,
    onImageSliderModalClose,
    images,
    startIndex,
    setStartIndex,
    deleteImage,
    imagePreviewFlag,
    flag,
  } = props;

  const [imageCarousel, setImageCarousel] = useState<formatedImages[] | null>(
    null
  );

  useEffect(() => {
    if (images.length > 0 && isImageSliderModalOpen) {
      if (flag === "string") {
        const formatedImages = images.map((img) => {
          return {
            original: img,
            thumbnail: img,
            originalHeight: "420px",
            originalWidth: "420px",
            thumbnailHeight: "80px",
            thumbnailWidth: "80px",
            width: "420",
            height: "420",
          };
        });
        setImageCarousel(formatedImages);
      } else if (flag === "object") {
        const formatedImages = images.map((img) => {
          return {
            original: img.image_url,
            thumbnail: img.image_url,
            originalHeight: "420px",
            originalWidth: "420px",
            thumbnailHeight: "80px",
            thumbnailWidth: "80px",
            width: "420",
            height: "420",
          };
        });
        setImageCarousel(formatedImages);
      }
    } else {
      onImageSliderModalClose(false);
    }
  }, [images, flag, isImageSliderModalOpen]);

  const _renderCustomControls = () => {
    return (
      <React.Fragment>
        {imagePreviewFlag === "edit" ? (
          <Typography
            variant="h6"
            sx={{ textAlign: "right", fontSize: "14px", mr: 12, mb: 2 }}
          >
            <Button
              sx={{ textAlign: "right", fontSize: "14px" }}
              onClick={deleteImage}
            >
              <IconButton aria-label="close" onClick={() => {}}>
                <DeleteOutlineIcon sx={{ fontSize: "1.5rem" }} />
              </IconButton>
              Delete this image
            </Button>
          </Typography>
        ) : null}
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (!isImageSliderModalOpen) {
      setImageCarousel([]);
    }
  }, [isImageSliderModalOpen]);

  return (
    <Dialog
      open={isImageSliderModalOpen}
      onClose={() => onImageSliderModalClose(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
          Photos
        </Typography>
        <IconButton
          onClick={() => onImageSliderModalClose(false)}
          sx={{ position: "absolute", right: 3, top: 8, mr: 2 }}
        >
          <CloseIcon sx={{ fontSize: "1.5rem" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {imageCarousel && (
          <ImageGallery
            startIndex={startIndex}
            items={imageCarousel}
            showFullscreenButton={false}
            showPlayButton={false}
            lazyLoad={true}
            renderCustomControls={_renderCustomControls}
            onSlide={(currentIndex) => setStartIndex(currentIndex)}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ImageSliderModal;
