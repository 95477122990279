import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Box,
  Paper,
  TextField,
  Button,
  InputLabel,
  InputBase,
  useMediaQuery,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import "react-phone-number-input/style.css";
import "./index.scss";
import { setBookingStoreDetails, setRole, setUserDetail } from "../../store";
import { theme } from "../../utils/theme";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const Logo = require("../../assets/Logo-hello-fixy-green.svg").default;
const LoginBg = require("../../assets/login-bg.svg").default;
const LoginImage = require("../../assets/login-image.svg").default;

// Styled components
const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  box-shadow: none;
`;

const Header = styled(AppBar)`
  flex-shrink: 0;
  background-color: transparent;
  width: 100%;
  color: #36423c;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: none;
  z-index: 9999;
`;

const HeaderContent = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #a4d2b9;
  padding: 0px !important;
`;

const LogoContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 15px;
  cursor: pointer;
`;

const LocationContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

const LeftPane = styled(Box)`
  color: #36423c;
  position: absolute;
  top: 0;
  width: 50vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const RightPane = styled(Box)`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FormContainer = styled(Box)`
  padding-right: 50px;
  padding-top: 50px;
`;

const InputField = styled(TextField)`
  border-radius: 8px;
  margin-top: 8px;
  letter-spacing: 2px;
  margin-bottom: 16px;
  & .MuiOutlinedInput-root {
    fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &:hover fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &.Mui-focused fieldset {
      border-color: ${theme.palette.primary.main};
    }

    &.Mui-error fieldset {
      border-color: #your-error-color; /* Error state */
    }
  }
`;

const InputFieldPhone = styled(PhoneInput)`
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
  letter-spacing: 1px;
  outline: none !important;
  border: 1px solid ${theme.palette.primary.contrastText};
  height: 60px;
  display: flex;
  align-items: center;

  .PhoneInputInput {
    flex: 1;
    border: none;
    outline: none;
    padding: 8px;
    font-size: 16px;
  }

  .PhoneInputCountry {
    padding-left: 15px;
  }
  
  .PhoneInput--focus {
    border-color: ${theme.palette.primary.main} !important;
  }

`;

const LoginButton = styled(Button)`
  margin-top: 20px;
  color: #ffffff;
  background: #71a187;
  font-size: 1rem;
  font-family: "Kufam", sans-serif;
  font-weight: bold;
  height: 3rem;
  width: 125px;
  border-radius: 30px;
  &:hover {
    background: #539b7b; /* Change the background color on hover */
  }
  &:active {
    background: #3b7257; /* Change the background color on click */
  }
`;

const CancelButton = styled(Button)`
  margin-top: 20px;
  text-align: left;
  height: 40px;
  font-size: 16px;
  font: Kufam;
  font-weight: 600;
  border-radius: 30px;
  padding: 10px 20px;
`;

const LocationText = styled(Typography)`
  margin-left: 1px;
`;

const OtpInputContainer = styled(Paper)`
  display: flex;
  justify-content: space-between;
  box-shadow: none;
  text-align: center;
`;

const OtpDigitInput = styled(InputBase)`
  margin-top: 8px;
  margin-bottom: 16px;
  width: 60px;
  height: 55px;
  text-align: center;
  font-size: 16px;
  border: 1px solid ${theme.palette.primary.contrastText};
  border-radius: 4px;
  padding: 5px 10px;
`;

const ErrorLabel = styled(Typography)(() => ({
  color: "red",
  fontSize: "0.875rem",
  fontWeight: 500,
  marginBottom: "15px",
  textAlign: "left",
}));

const CustomerLogin: React.FC = () => {
  const mobile = useMediaQuery(theme.breakpoints.up("lg"));
  const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSignIn, setIsSignIn] = useState(true); // State to toggle between Sign In and Sign Up
  const [otpSent, setOtpSent] = useState(false); // State to track whether OTP is sent
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [focusedIndex, setFocusedIndex] = React.useState(0);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
  };

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    country_code: "",
    password: "",
    confirm_password: "",
  });

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    password: "",
    confirm_password: "",
    otp: "",
  });

  useEffect(() => {
    if (!isSignIn) {
      if (values.phone_number) {
        const parsedNumber = parsePhoneNumber(values.phone_number);
        const countryCode: any = `+${parsedNumber?.countryCallingCode}`;
        const phoneNumber: any = parsedNumber?.nationalNumber;
        if (countryCode && phoneNumber) {
          setValues({
            ...values,
            ["phone_number"]: phoneNumber,
            ["country_code"]: countryCode,
          });
        }
      }
    }
  }, [isSignIn]);

  useEffect(() => {
    if (isSignIn || forgotPassword) {
      setOtpSent(false);
      setOtp(["", "", "", "", "", ""]);
    }
  }, [isSignIn, forgotPassword]);

  const handleFocus = (index: number) => {
    setFocusedIndex(index);
  };

  const handleBlur = () => {
    setFocusedIndex(0);
  };

  const handleInputChange = (index: number, value: string) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = value;
    setOtp(updatedOtp);
  };

  const handleToggle = () => {
    setIsSignIn(!isSignIn);
  };

  const handleSendOtp = async (e: any) => {
    let newErrors = "";
    if (!values.phone_number) {
      newErrors = "Mobile number is required";
    } else if (!validateMobileNumber(values.phone_number)) {
      newErrors = "Invalid mobile number format";
    }
    
    try {
      const response = await fetch(`${apiEndpoint}/users/generate_otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          country_code: values.country_code,
          phone_number: values.phone_number,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        toast.success("OTP sent", {
          position: "top-center",
          autoClose: 5000,
        });
        setOtpSent(true);
      } else {

        if (data.success === false || data.success === "False" && data.message) {
          toast.error(data.message, {
            position: "top-center",
            autoClose: 5000,
          });
          newErrors = data.message
        }
      }
    } catch (error) {
      console.error("Error during generating OTP:", error);
    }

    setErrors({ ...errors, ["otp"]: newErrors });

    if (newErrors === "") {
      setOtpSent(true);
    } else {
      setOtpSent(false);
    }
  };

  function generateRandomEmail() {
    const username = Math.random().toString(36).substring(7);
    const domain = ["gmail.com", "yahoo.com", "outlook.com", "example.com"]; // Add more domains as needed
    const randomDomain = domain[Math.floor(Math.random() * domain.length)];

    return `${username}@${randomDomain}`;
  }

  const handleSignup = async (e: any) => {
    if (handleValidation()) {
      e.preventDefault();
      try {
        const response = await fetch(`${apiEndpoint}/users/profile/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            first_name: values.first_name,
            last_name: values.last_name,
            phone_number: values.phone_number,
            otp: otp?.join(''),
            country_code: values.country_code,
            email: generateRandomEmail(),
            password: values.password,
          }),
        });

        const data = await response.json();
        if (response.ok) {
          toast.success("Your Account is created. Sign in now", {
            position: "top-center",
            autoClose: 5000,
          });
          setValues({
            ...values,
            phone_number: values.country_code + values.phone_number,
          });
          setIsSignIn(true);
        } else {
          let errorMessage = "An error occurred during signup. Please try again later.";
  
          if (data.success === false || data.Success === "False" || data.success === "False" && data.message) {
            for (const key in data.message) {
              if (data.message.hasOwnProperty(key) && Array.isArray(data.message[key]) && data.message[key].length > 0) {
                errorMessage = data.message[key][0];
                break;
              }
            }
          }
  
          toast.error(errorMessage, {
            position: "top-center",
            autoClose: 5000,
          });
  
          console.error("Signup failed:", data.message);
        }
      } catch (error) {
        console.error("Error during login:", error);
      }
    }
  };

  const getCartDetails = async (accessToken: string) => {
    try {
      let url: string = `${apiEndpoint}/bookings/12345/get_booking_cart`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();

      if (data) {
        dispatch(setBookingStoreDetails(data));
      } else {
        throw new Error("Error in booking service");
      }
    } catch (error) {
      console.error("Error fetching Services:", error);
    }
  };

  const handleLogin = async (e: any) => {
    if (handleValidation()) {
      e.preventDefault();
      try {
        const response = await fetch(`${apiEndpoint}/users/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: values.phone_number,
            password: values.password,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          const accessToken = data.access;
          const refreshToken = data.refresh;

          // Store the access token in session storage
          sessionStorage.setItem("access_token", accessToken);
          sessionStorage.setItem("isAuthenticated", "true");
          sessionStorage.setItem("refresh_token", refreshToken);
          sessionStorage.setItem("role", "user");
          getCartDetails(accessToken);
          // dispatch(setAuthenticated(true));
          dispatch(setRole("user"));
          await fetchData(accessToken);
          navigate("/all-services");
        } else {
          const errorMessage = data.detail ? data.detail[0] : "Login failed";
          toast.error(errorMessage, {
            position: "top-center",
            autoClose: 5000,
          });
          // let errormessage=data.detail
          // toast.error(errormessage, {
          //   position: "top-center",
          //   autoClose: 5000,
          // });
          // console.error("Login failed");
        }
      } catch (error) {
        console.error("Error during login:", error);
      
      toast.error("An unexpected error occurred. Please try again.", {
        position: "top-center",
        autoClose: 5000,
      });
    }
    }
  };

  async function fetchData(accessToken: string) {
    try {
      const response = await fetch(`${apiEndpoint}/users/profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        dispatch(setUserDetail(data));
        return;
      } else {
        console.error("Login failed");
        return;
      }
    } catch (error) {
      console.error("Error during login:", error);
      return;
    }
  }

  const validateMobileNumber = (mobileNumber: string): boolean => {
    const mobileNumberRegex = /^\+?\d+$/;
    return mobileNumberRegex.test(mobileNumber);
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrors({ ...errors, [prop]: "" });
  };

  const handleValidation = () => {
    const newErrors = {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      confirm_password: "",
      otp: "",
    };
    if (!values.phone_number) {
      newErrors.phone_number = "Mobile number is required";
    } else if (!validateMobileNumber(values.phone_number)) {
      newErrors.phone_number = "Invalid mobile number format";
    }

    if (!values.password.length) {
      newErrors.password = "Password is required";
    }
    if (forgotPassword){
      if (values.password.length < 8) {
        newErrors.password = "Password must be at least 8 characters";
      }

      if (values.password !== values.confirm_password) {
        newErrors.confirm_password = "Passwords are not matching";
      }
    }
    if (!isSignIn && !forgotPassword) {
      if (!values.first_name) {
        newErrors.first_name = "First name is required";
      }

      if (!values.last_name) {
        newErrors.last_name = "Last name is required";
      }
      if (values.password.length < 8) {
        newErrors.password = "Password must be at least 8 characters";
      }

      if (values.password !== values.confirm_password) {
        newErrors.confirm_password = "Passwords are not matching";
      }
    }

    setErrors(newErrors);

    return (
      !newErrors.phone_number &&
      !newErrors.password &&
      !newErrors.confirm_password &&
      !newErrors.first_name &&
      !newErrors.last_name
    );
  };

  const onPhoneNumberChange = (value: string) => {
    const parsedNumber = parsePhoneNumber(value);
    const countryCode: any = `+${parsedNumber?.countryCallingCode}`;
    const phoneNumber: any = parsedNumber?.nationalNumber;

    setValues({
      ...values,
      ["phone_number"]: phoneNumber,
      ["country_code"]: countryCode,
    });
    setErrors({
      ...errors,
      ["phone_number"]: "",
    });
  };

  const onPhoneNumberChangeLogin = (value: string) => {
    setValues({
      ...values,
      ["phone_number"]: value,
    });
    setErrors({
      ...errors,
      ["phone_number"]: "",
    });
  };

  const handleForgotPasswordChange = () => {
    setForgotPassword(true);
    setIsSignIn(false);
  };

  const handleForgotPassword = async (e: any) => {
    if (handleValidation()) {
      e.preventDefault();
      let newErrors = "";
      try {
        const response = await fetch(`${apiEndpoint}/users/forgotpassword`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            country_code: values.country_code,
            phone_number: values.phone_number,
            password: values.password,
            confirm_password: values.confirm_password,
            otp: otp?.join('')
          }),
        });

        const data = await response.json();
        if (response.ok) {
          toast.success(data.message, {
            position: "top-center",
            autoClose: 5000,
          });
          setForgotPassword(false);
          setValues({
            ...values,
            phone_number: values.country_code + values.phone_number,
          });
          setIsSignIn(true);
        }else {
          let errorMessage = "An error occurred during changing password. Please try again later.";

          if (data.success === false || data.Success === "False" || data.success === "False" && data.message) {
            for (const key in data.message) {
              if (data.message.hasOwnProperty(key) && Array.isArray(data.message[key]) && data.message[key].length > 0) {
                errorMessage = data.message[key][0];
                break;
              }
              else{
                errorMessage = data.message
              }

            }
          }

          toast.error(errorMessage, {
            position: "top-center",
            autoClose: 5000,
          });

          console.error("Forgot password failed:", data.message);
        }
        
      } catch (error) {
        console.error("Error during changing password:", error);
      }
    };
  };
  return (
    <Container elevation={3} sx={{ height: '100vh' }}>
      <ToastContainer />
      <Header position="static">
        <HeaderContent>
          <LogoContainer
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              src={Logo}
              alt="Company Logo"
              style={{ marginRight: 8, height: 40 }}
            />
            <Typography
              variant="h2"
              sx={{
                fontSize: "20px",
              }}
            >
              HelloFixy
            </Typography>
          </LogoContainer>
        </HeaderContent>
      </Header>
      <Grid container sx={{ display: 'flex', justifyContent: 'space-between', overflow: 'hidden'}}>
        {mobile && (
          <Grid md={6}>
            <LeftPane sx={{ backgroundImage: `url(${LoginBg})`}}>
              <img
                src={LoginImage}
                alt="Background"
              />
            </LeftPane>
          </Grid>
        )}
        <Grid item xs={12} md={mobile ? 5 : 12} sx={{ maxHeight: '100%', overflowY: 'auto'}}>
          <RightPane>
            <FormContainer>
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                sx={{
                  textAlign: "left",
                  color: "#36423c",
                  fontSize: "36px",
                  font: "kefa",
                  marginBottom: "20px",
                }}
              >
                {isSignIn
                  ? "Sign In"
                  : forgotPassword
                  ? "Forgot Password?"
                  : "Sign Up"}
              </Typography>
              {isSignIn ? (
                // Sign In Form
                <>
                  <Typography
                    variant="body2"
                    style={{ marginTop: 10, marginBottom: 30 }}
                  >
                    New user?
                    <span
                      style={{
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                        marginLeft: "5px",
                      }}
                      onClick={handleToggle}
                    >
                      Sign Up
                    </span>
                  </Typography>
                  <InputLabel htmlFor="mobile"> Mobile number *</InputLabel>
                  <InputFieldPhone
                    style={{ height: "60px" }}
                    defaultCountry="IN" // Set the default country
                    placeholder="Enter phone number"
                    onChange={(value) => onPhoneNumberChangeLogin(value || "")}
                  />
                  <ErrorLabel>{errors.phone_number}</ErrorLabel>
                  <InputLabel htmlFor="password"> Password *</InputLabel>
                  <InputField
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    margin="normal"
                    placeholder="Enter password"
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handleLogin(event);
                      }
                    }}
                    onChange={handleChange("password")}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }}
                  />
                  <ErrorLabel>{errors.password}</ErrorLabel>
                  <Box sx={{ textAlign: "right" }}>
                    <span
                      style={{ cursor: "pointer", color: "#A4D2B9" }}
                      onClick={handleForgotPasswordChange}
                    >
                      Forgot Password?
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: mobile ? "" : "center",
                      alignItems: mobile ? "" : "center",
                    }}
                  >
                    <LoginButton
                      variant="contained"
                      color="primary"
                      onClick={handleLogin}
                    >
                      Sign In
                    </LoginButton>
                  </Box>
                </>
              ) : (
                // Sign Up Form
                <>
                  {!forgotPassword && (
                    <Typography
                      variant="body2"
                      style={{ marginTop: 10, marginBottom: 30 }}
                    >
                      Already have an account?{" "}
                      <span
                        style={{
                          cursor: "pointer",
                          color: theme.palette.primary.main,
                        }}
                        onClick={handleToggle}
                      >
                        Sign In
                      </span>
                    </Typography>
                  )}

                  <InputLabel htmlFor="mobile"> Mobile number *</InputLabel>
                  <InputFieldPhone
                    style={{ height: "60px" }}
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    onChange={(value) => onPhoneNumberChange(value || "")}
                  />
                  <ErrorLabel>{errors.phone_number}</ErrorLabel>
                  {!otpSent ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: mobile ? "" : "center",
                        alignItems: mobile ? "" : "center",
                      }}
                    >
                      <LoginButton
                        variant="contained"
                        color="primary"
                        onClick={handleSendOtp}
                      >
                        Send OTP
                      </LoginButton>
                    </Box>
                  ) : (
                    <>
                      <InputLabel htmlFor="otp"> OTP *</InputLabel>
                      <OtpInputContainer elevation={3}>
                        {otp.map((digit, index: number) => (
                          <OtpDigitInput
                            key={index}
                            id={`otp_${index}`}
                            value={digit}
                            onChange={(e) => {
                              handleInputChange(index, e.target.value);
                              if (e.target.value) {
                                const nextField = document.getElementById(index < 5 ? `otp_${index+1}` : (forgotPassword ? 'new-password' :'first-name'));
                                nextField?.focus();
                              } else if (index > 0) {
                                const previousField = document.getElementById(`otp_${index - 1}`);
                                previousField?.focus();
                              }
                            }}
                            onFocus={() => handleFocus(index)}
                            onBlur={handleBlur}
                            className={focusedIndex === index ? 'otp-input-focused' : ''}
                            inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                          />
                        ))}
                      </OtpInputContainer>
                      <ErrorLabel>{errors.otp}</ErrorLabel>
                      {!forgotPassword && (
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ padding: "0 10px 0px 0px" }}
                          >
                            <InputLabel htmlFor="first-name">
                              {" "}
                              First Name *
                            </InputLabel>
                            <InputField
                              id="first-name"
                              fullWidth
                              margin="normal"
                              onChange={handleChange("first_name")}
                            />
                            <ErrorLabel>{errors.first_name}</ErrorLabel>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ paddingLeft: {
                              xs: 0,
                              md: "0.75rem"
                            }}}
                          >
                            <InputLabel htmlFor="last-name">
                              {" "}
                              Last name *
                            </InputLabel>
                            <InputField
                              id="last-name"
                              type="text"
                              fullWidth
                              margin="normal"
                              onChange={handleChange("last_name")}
                            />
                            <ErrorLabel>{errors.last_name}</ErrorLabel>
                          </Grid>
                        </Grid>
                      )}
                      <InputLabel htmlFor="new-password">
                        {" "}
                        Create password *
                      </InputLabel>
                      <InputField
                        id="new-password"
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        margin="normal"
                        onChange={handleChange("password")}
                        InputProps={{
                        endAdornment: <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                        }}
                      />
                      <ErrorLabel>{errors.password}</ErrorLabel>
                      <InputLabel htmlFor="confirm-password">
                        {" "}
                        Re-enter your password *
                      </InputLabel>
                      <InputField
                        id="confirm-password"
                        type="password"
                        fullWidth
                        margin="normal"
                        onChange={handleChange("confirm_password")}
                      />
                      <ErrorLabel>{errors.confirm_password}</ErrorLabel>
                      <LoginButton
                        variant="contained"
                        color="primary"
                        onClick={forgotPassword? handleForgotPassword: handleSignup}
                      >
                        {forgotPassword ? "Change Password" : "Sign Up"}
                      </LoginButton>
                      <CancelButton
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setForgotPassword(false)
                          setIsSignIn(true);
                          setOtpSent(false);
                        }}
                        sx={{ ml: 1 }}
                      >
                        Cancel
                      </CancelButton>
                    </>
                  )}
                </>
              )}
            </FormContainer>
          </RightPane>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CustomerLogin;
