import React from "react";
import {
    Box,
    Grid, 
    Typography,
    Card,
    Button,
    CardContent,
    styled,
} from "@mui/material";

const StyledCard = styled(Card)`
width: 100%;
display: flex;
align-items: flex-start;
box-shadow: 0px 0px 12px #ccc;
`;

const CardContentWrapper = styled(CardContent)`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
padding: 10px 15px !important;
height: 155px;
width: 100%;
`;

const ViewDetailsLink = styled(Button)`
text-align: right;
padding: 0px 15px !important;
align-items: flex-end;
text-transform: capitalize;
margin-left: auto;
min-width: max-content;
`;

const ServiceCard = ({
    service,
    setSelectedService,
}: {service: any, setSelectedService: any}) => {

    return (
        <Grid item key={service.id} 
                sx={{width: 
                {xs:"100vw",sm:"480px",md:"480px" ,lg:"460px",xl:"480px"}}}>
                <StyledCard>
                  <Box
                    component="img"
                    src={service.image_url}
                    alt="Service Logo"
                    sx={{
                      width: {xs: '130px', sm: '130px', md: '148px'},
                      height: {xs: '130px', sm: '130px', md: '148px'},
                    }}
                  ></Box>
                  <CardContentWrapper
                      sx={{height: {xs: '130px', sm: '130px', md: '148px'}}}>
                    <Typography
                      sx={{
                        color: "#36423C",
                        fontSize: {xs: '14px', sm: '14px', md: '16px'},
                        fontWeight: 700,
                        lineHeight: "20px",
                        // width: "200px",
                        whiteSpace: "nowrap",
                        textWrap: "wrap",
                      }}
                    >
                      {service.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#A7A7A7",
                        fontSize: {xs: '12px', sm: '12px', md: '14px'},
                        fontWeight: 400,
                        lineHeight: {xs: '16px', sm: '16px', md: '20px'},
                        minHeight: {xs: '50px', sm: '50px', md: '80px'},
                        maxHeight: {xs: '50px', sm: '50px', md: '100px'}, // Adjust the max height as needed
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        WebkitLineClamp: {xs: 3, sm: 3, md: 4},
                      }}
                    >
                      {service.short_description}
                    </Typography>

                    <ViewDetailsLink
                      color="primary"
                      onClick={() => {
                        setSelectedService(service);
                      }}
                      sx={{fontSize: {xs: '12px', sm: '14px', md: '14px'}}}
                    >
                      View Details
                    </ViewDetailsLink>
                  </CardContentWrapper>
                </StyledCard>
              </Grid>
    );
}

export default ServiceCard;
