import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React from "react";
import { BookingDetailTimelineResponse, selectedTimeSlots } from "../../types";
import { StepperType } from "../../types/StepperType";
import { PrimaryButton } from "./Steps";

type Props = {
  bookingDetails: BookingDetailTimelineResponse;
  timeSlotSlicedData: false | selectedTimeSlots[] | null;
  role: string | null;
  setSlot: (slot: boolean) => void;
};
export const ScheduledInspection = ({
  bookingDetails,
  timeSlotSlicedData,
  role,
  setSlot,
}: Props) => {
  let scheduledInspection: StepperType = {
    label: (
      <Box>
        <Typography
          variant="caption"
          sx={{
            fontSize: "16px",
            color: "#568A60",
            fontWeight: 600,
            cursor: "pointer",
          }}
        >
          {`${
            bookingDetails &&
            bookingDetails?.slots_provided.hasOwnProperty("slots_detail") &&
            bookingDetails?.slots_provided.slots_detail?.length > 0
              ? "Slots Provided"
              : "Schedule Inspection"
          }`}
        </Typography>
      </Box>
    ),
    roles: ["user"],
    description: (
      <>
        {timeSlotSlicedData && timeSlotSlicedData?.length > 0 ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                columnGap: "10px",
                rowGap: "12px",
                mt: 2,
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                The selected slots.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  columnGap: "20px",
                  overflowX: "auto",
                  width: "100%"
                }}
              >
                {timeSlotSlicedData &&
                  timeSlotSlicedData.map((item: any) => {
                    return (
                      <Box
                        style={{
                          fontSize: "14px",
                          fontWeight: 400,
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "8px",
                          alignItems: "center",
                          border: "2px solid #C1D0C4",
                          borderRadius: "18px",
                          paddingLeft: 25,
                          paddingRight: 25,
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                          {dayjs(item.date).format("dddd")}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#A7A7A7",
                            fontWeight: 400,
                          }}
                        >
                          {dayjs(item.date).format("DD MMM YYYY")}
                        </Typography>
                        <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                          {item.time_slot}
                        </Typography>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </>
        ) : role === "user" ? (
          <Box sx={{ rowGap: 2 }}>
            <Box>
              <Typography>Please choose three time slots</Typography>
              <PrimaryButton
                variant="contained"
                sx={{ fontSize: 12, height: 30, marginTop: 2 }}
                onClick={() => setSlot(true)}
              >
                Choose time slot
              </PrimaryButton>
            </Box>
          </Box>
        ) : (
          ""
        )}
      </>
    ),
  };
  return scheduledInspection;
};
