import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { AuthState } from "./store/authReducer";
import Dashboard from "./layouts/dashboard-layouts";
import CustomerLogin from "./pages/login";
import VendorLogin from "./pages/login/vendor-login";
import NewHome from "./pages/home/newHome";
import Employees from "./pages/employees";
import DashboardCard from "./pages/dashboard";
import HelpCenter from "./pages/help-center";
import Profile from "./pages/profile";
import AllServices from "./pages/all-services";
import Address from "./pages/address";
import AboutPage from "./pages/about";
import {setUserDetail} from "./store";

const PrivateRoute: React.FC<{ element: React.ReactElement }> = ({
    element,
}) => {
    const isAuthenticated: boolean = useSelector(
        (state: any) => state?.userDetail
    )
        ? true
        : false;
    return isAuthenticated ? element : <Navigate to="/login" />;
};

export const Routers: React.FC = () => {

    const dispatch = useDispatch();
    const userDetail = useSelector((state: any) => state.userDetail);
    const isAuthenticated: boolean = userDetail ? true : false;

    const accessToken = sessionStorage.getItem("access_token");

    useEffect(() => {
        if (accessToken && !userDetail) {
            fetchUserData(accessToken, dispatch);
        }
    }, [userDetail]);



    const fetchUserData = async (accessToken: string, dispatch: any) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_LOGIN_API_ENDPOINT}/users/profile`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          });
    
          const data = await response.json();
          if (response.ok) {
            dispatch(setUserDetail(data));
            return;
          } else {
            console.error('Login failed');
            return;
          }
        } catch (error) {
          console.error('Error during login:', error);
          return;
        }
      }

    return (
        <Routes>
            {/* Other routes */}
            {isAuthenticated ? (
                <>
                    <Route
                        path="/"
                        element={
                            <PrivateRoute
                                element={
                                    <Dashboard>
                                        <DashboardCard />
                                    </Dashboard>
                                }
                            />
                        }
                    />
                    <Route
                        path="/all-services"
                        element={
                            <Dashboard>
                                <AllServices />
                            </Dashboard>
                        }
                    />
                    <Route
                        path="/projects"
                        element={
                            <PrivateRoute
                                element={
                                    <Dashboard>
                                        <DashboardCard />
                                    </Dashboard>
                                }
                            />
                        }
                    />
                    <Route
                        path="/help-center"
                        element={
                            <Dashboard>
                                <HelpCenter />
                            </Dashboard>
                        }
                    />
                    <Route
                        path="/customer-dashboard"
                        element={
                            <PrivateRoute
                                element={
                                    <Dashboard>
                                        <DashboardCard />
                                    </Dashboard>
                                }
                            />
                        }
                    />
                    <Route
                        path="/address"
                        element={
                            <PrivateRoute
                                element={
                                    <Dashboard>
                                        <Address />
                                    </Dashboard>
                                }
                            />
                        }
                    />
                    <Route
                        path="/services"
                        element={
                            <PrivateRoute
                                element={
                                    <Dashboard>
                                        {/* <Services /> */}
                                        <AllServices />
                                    </Dashboard>
                                }
                            />
                        }
                    />
                    <Route
                        path="/employees"
                        element={
                            <PrivateRoute
                                element={
                                    <Dashboard>
                                        <Employees />
                                    </Dashboard>
                                }
                            />
                        }
                    />
                    <Route
                        path="/profile"
                        element={
                            <PrivateRoute
                                element={
                                    <Dashboard>
                                        <Profile />
                                    </Dashboard>
                                }
                            />
                        }
                    />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </>
            ) : (
                <>
                    <Route path="/login" element={<CustomerLogin />} />
                    <Route path="/" element={<NewHome />} />
                    <Route path="/vendor-login" element={<VendorLogin />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/all-services" element={<AllServices />} />
                    <Route path="/help-center" element={<HelpCenter />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </>
            )}
        </Routes>
    );
};
