import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import ReadMore from "../../components/shared/readmore-readless";
import { BookingDetailTimelineResponse } from "../../types";
import { StepperType } from "../../types/StepperType";

export default function RejectProject(props: {
  bookingDetails: BookingDetailTimelineResponse;
}) {
  const { bookingDetails } = props;
  let rejectProject: StepperType = {
    label: (
      <Box>
        <Typography
          variant="caption"
          sx={{
            fontSize: "16px",
            color: "#568A60",
            fontWeight: 600,
            cursor: "pointer",
          }}
        >
          {bookingDetails?.rejected.label}
        </Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: "10px",
          }}
        >
          <Typography
            sx={{ fontSize: "12px", fontWeight: 400, color: "#1F261F" }}
          >
            {bookingDetails &&
              dayjs(bookingDetails.rejected.created_at).format("DD MMM YYYY")}
          </Typography>
          <Typography
            sx={{ fontSize: "12px", color: "#A7A7A7", fontWeight: 400 }}
          >
            {bookingDetails &&
              dayjs(bookingDetails.rejected.created_at).format("hh:mm A")}
          </Typography>
        </Box>
      </Box>
    ),
    roles: ["user", "vendor"],
    status: bookingDetails ? bookingDetails.request_acceptance.status : "",
    description: (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: 1,
        }}
      >
        <Typography sx={{ fontSize: "14px" }} color="text.primary" gutterBottom>
          <ReadMore>{`Vendor : ${
            bookingDetails && bookingDetails.rejected?.rejected_details
          }`}</ReadMore>
        </Typography>
      </Box>
    ),
  };
  return rejectProject;
}
