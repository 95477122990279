import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { BookingDetailTimelineResponse } from "../../types";
import { StepperType } from "../../types/StepperType";

export const RequestBooking = (props: {
  bookingDetails: BookingDetailTimelineResponse;
}) => {
  const { bookingDetails } = props;
  let request: StepperType = {
    label: (
      <Box>
        <Typography
          variant="caption"
          sx={{
            fontSize: "16px",
            color: "#568A60",
            fontWeight: 600,
            cursor: "pointer",
          }}
        >
          {bookingDetails ? bookingDetails.request_acceptance.label : ""}
        </Typography>
        <Box
          sx={{
            display: "flex",
            columnGap: "10px",
          }}
        >
          <Typography
            sx={{ fontSize: "12px", fontWeight: 400, color: "#1F261F" }}
          >
            {bookingDetails &&
              dayjs(bookingDetails.request_acceptance.created_at).format(
                "DD MMM YYYY"
              )}
          </Typography>
          <Typography
            sx={{ fontSize: "12px", color: "#A7A7A7", fontWeight: 400 }}
          >
            {bookingDetails &&
              dayjs(bookingDetails.request_acceptance.created_at).format(
                "hh:mm A"
              )}
          </Typography>
        </Box>
      </Box>
    ),
    roles: ["user", "vendor"],
    status: bookingDetails ? bookingDetails.request_acceptance.status : "",
    description: <></>,
  };
  return request;
};
