import axiosInstance from "../axiosInstance";
import Endpoint from "../Endpoints/timeline";

export const getBookingDetailsTimeline = (id: string) => {
  return axiosInstance.get(Endpoint.GET_BOOKING_TIMELINE(id));
};

export const getBooking = () => {
  return axiosInstance.get(Endpoint.GET_BOOKING);
};

export const acceptRequest = (id: string) => {
  return axiosInstance.put(Endpoint.ACCEPT_VENDOR(id));
};

export const postTimeSlot = (id: string, body: object) => {
  return axiosInstance.post(Endpoint.POST_TIMESLOT(id), body);
};

export const getEmployee = () => {
  return axiosInstance.get(Endpoint.GET_EMPLOYEE);
};

export const saveEmployee = (id: string, body: object) => {
  return axiosInstance.post(Endpoint.SAVE_EMPLOYEE(id), body);
};

export const saveTimeSlot = (id: string, body: object) => {
  return axiosInstance.patch(Endpoint.SAVE_TIMESLOT(id), body);
};

export const confirmTimeAndEmployee = (id: string) => {
  return axiosInstance.put(Endpoint.CONFIRM_TIME_EMPLOYEE(id));
};

export const postInspectionDetails = (id: string, body: object) => {
  return axiosInstance.post(Endpoint.CREATE_INSPECTION_DETAILS(id), body);
};

export const prepareQuotation = (id: string, body: object) => {
  return axiosInstance.post(Endpoint.PREPARE_QUOTATION(id), body);
};

export const sendQuotation = (id: string) => {
  return axiosInstance.patch(Endpoint.SEND_QUOTATION(id));
};

export const editQuotation = (id: string, body: object) => {
  return axiosInstance.put(Endpoint.EDIT_QUOTATION(id), body);
};

export const acceptQuotation = (id: string) => {
  return axiosInstance.patch(Endpoint.ACCEPT_QUOTATION(id));
};

export const postWorkSlot = (id: string, body: object) => {
  return axiosInstance.post(Endpoint.POST_WORK_SLOT(id), body);
};

export const saveWorkSlot = (id: string, body: object) => {
  return axiosInstance.patch(Endpoint.SAVE_WORK_SLOT(id), body);
};

export const confirmWorkSlot = (id: string) => {
  return axiosInstance.put(Endpoint.CONFIRM_WORK_SLOT(id));
};

export const postWorkDetails = (id: string, body: object) => {
  return axiosInstance.post(Endpoint.CREATE_WORK_DETAILS(id), body);
};

export const startWork = (id: string) => {
  return axiosInstance.patch(Endpoint.START_WORK(id));
};

export const completeWork = (id: string, body: object) => {
  return axiosInstance.post(Endpoint.COMPLETE_WORK(id), body);
};

export const closeProject = (id: string) => {
  return axiosInstance.patch(Endpoint.CLOSE_PROJECT(id));
};

export const rejectProjectWithReasons = (id: string, body: object) => {
  return axiosInstance.put(Endpoint.REJECT_PROJECT(id), body);
};

export const postFeeback = (id: string, body: object) => {
  return axiosInstance.post(Endpoint.POST_FEEDBACK(id), body);
};

export const getFeedback = (id: string) => {
  return axiosInstance.get(Endpoint.GET_FEEDBACK(id));
};

export const postPayment = (id: string) => {
  return axiosInstance.patch(Endpoint.POST_PAYMENT(id));
};

export const deleteQuotation = (id: string, quotation_id: number) => {
  return axiosInstance.delete(Endpoint.DELETE_QUOTATION(id, quotation_id));
};

export const deleteEmployee = (id: string, employee_id: number) => {
  return axiosInstance.delete(Endpoint.DELETE_EMPLOYEE(id, employee_id));
};
