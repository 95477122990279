import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { phoneNumber1, phoneNumber2 } from "../../utils/constant";
import ServiceCard from "./service-card-new";

interface Service {
  id: string;
  name: string;
  short_description: string;
  description: string;
  image_url: string;
}

const SearchList = (props: any) => {
  const {
    isSearch,
    setIsSearch,
    setSelectedService,
    searchText,
    setSearchText,
    searchServicesList,
    setSearchServicesList,
    finalSearchValue,
    setFinalSearchValue,
  } = props;

  const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT;
  const [defaultPageSize, setDefaultPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const handleSearch = async () => {
    try {
      if (finalSearchValue && finalSearchValue.length > 0) {
        let url = `${apiEndpoint}/services?page=${currentPage}&page_size=${defaultPageSize}&search_term=${finalSearchValue}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        if (data && data.results && data.count > 0) {
          setSearchServicesList(data.results);
          setTotalCount(data.count);
        } else {
          setSearchServicesList(null);
          setTotalCount(0);
        }
      }
    } catch (error: any) {
      console.error("Error:", error.message);
      setSearchServicesList(null);
      setTotalCount(0);
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      if (searchText.length > 1) {
        setFinalSearchValue(searchText);
        setCurrentPage(1);
        setIsSearch(true);
      } else {
        toast.warning(`Enter at least 2 characters to trigger search.`, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    }
  };

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    handleSearch();
  }, [finalSearchValue, currentPage, defaultPageSize]);

  const handlePaste = (event: any) => {
    setIsSearch(true);
  };

  useEffect(() => {
    if (searchText) {
      setSearchText("");
    }
  }, []);

  return (
    <Box sx={{
      width: "100%",
      alignItems: {
        xs: 'center',
        sm: "flex-start"
      }
    }}>
      <TextField
        id="search_service"
        name="search"
        placeholder="Search services"
        variant="outlined"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
          if (e.target.value === "") {
            setIsSearch(false);
            setFinalSearchValue("");
            setSearchServicesList(null);
          }
        }}
        onPaste={handlePaste}
        onKeyDown={handleKeyPress}
        InputProps={{
          endAdornment: (
            <>
              {searchText && (
                <IconButton
                  onClick={() => {
                    setSearchText("");
                    setIsSearch(false);
                    setFinalSearchValue("");
                    setSearchServicesList(null);
                    setTotalCount(0);
                  }}
                  edge="end"
                  sx={{ mr: 0.2 }}
                >
                  <CloseIcon sx={{ fontSize: "16px" }} />
                </IconButton>
              )}
              <IconButton
                onClick={() => {
                  if (searchText === "") {
                    toast.warning(`Search value is empty`, {
                      position: "top-right",
                      autoClose: 3000,
                    });
                  } else if (searchText.length > 1) {
                    setFinalSearchValue(searchText);
                    setCurrentPage(1);
                    setIsSearch(true);
                  } else {
                    toast.warning(`Enter at least 2 characters to trigger searrch.`, {
                      position: "top-right",
                      autoClose: 3000,
                    });
                  }
                }}
                edge="end"
                sx={{ backgroundColor: "#EEFEF1", mr: 0.2 }}
              >
                <SearchIcon />
              </IconButton>
            </>
          ),
          sx: {
            background: "white", // Set background color to white
            border: "none", // Remove the border
            borderRadius: "30px",
            padding: "0 10px",
            mixWidth: '300px',
            width: {xs: '100%', sm: '400px'},
            maxWidth: {xs: '100%', sm: '400px'},
            marginBottom: 2,
          },
        }}
      />
      {isSearch && searchServicesList && searchServicesList.length > 0 ? (
        <Grid container spacing={2} sx={{flexDirection: "row", alignContent: "center", alignItems:"center", justifyContent:"center", mb: 2 }}>
          <Grid item xs={12}>
            {finalSearchValue && (
              <React.Fragment>
                Search result for : <b>{finalSearchValue}</b>
              </React.Fragment>
            )}
          </Grid>
          {searchServicesList.map((service: Service, index: number) => (
            <ServiceCard setSelectedService={setSelectedService} service={service}/>
          ))}

          {totalCount / defaultPageSize > 0 && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: {
                  xs: "column-reverse",
                  sm: "column-reverse",
                  md: "row",
                  lg: "row",
                  xl: "row"
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  mr: 3,
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  Rows per page
                </InputLabel>
                <Select
                  value={defaultPageSize}
                  onChange={(e) => {
                    setDefaultPageSize(Number(e.target.value));
                  }}
                  sx={{ ml: 2, height: "30px" }}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={55}>50</MenuItem>
                </Select>
              </Box>
              <Pagination
                variant="outlined"
                color="primary"
                count={Math.ceil(totalCount / defaultPageSize)}
                page={currentPage}
                onChange={handlePaginationChange}
                sx={{ mt: 2, mb: 2 }}
              />
            </Grid>
          )}
        </Grid>
      ) : (
        <React.Fragment>
          <Grid item xs={12}>
            {finalSearchValue && finalSearchValue.length > 1 && (
              <React.Fragment>
                <Box>
                  Search result for : <b>{finalSearchValue}</b>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                    mb: 2,
                    fontSize: "14px",
                    color: "gray",
                    fontWeight: "bold",
                  }}
                >
                  {`Cannot find your service. Please call ${phoneNumber1} / ${phoneNumber2} to find an expert for you.`}
                </Box>
              </React.Fragment>
            )}
          </Grid>
        </React.Fragment>
      )}
    </Box>
  );
};

export default SearchList;
