// store.ts
import { createStore } from 'redux';

import {
  AssignEmployee,
  BookingDetailsResponse,
  BookingDetailTimelineResponse,
  EmployeeResponse,
  timeSlot,
  UserResponse,
  categoryResposeType,
  FeedbackType,
} from '../types';

export interface RootState {
  role: string;
  userDetail: UserResponse | null;
  bookingDetail: BookingDetailsResponse | null;
  bookingDetailTimeline: BookingDetailTimelineResponse | null;
  getTimeSlot: timeSlot[] | null;
  getemployeeList: EmployeeResponse[] | null;
  activeCategory: categoryResposeType | null | undefined;
  getWorkEmployee: EmployeeResponse[] | null;
  searchService: string | null;
  feedback: FeedbackType | null;
  activeProjectTabIndex: number;
  activeEmployeeTabIndex: number;
}

const initialState: RootState = {
  role: '',
  userDetail: null,
  bookingDetail: null,
  searchService: '',
  bookingDetailTimeline: null,
  getTimeSlot: null,
  getemployeeList: null,
  activeCategory: null,
  getWorkEmployee: null,
  feedback: null,
  activeProjectTabIndex: 0,
  activeEmployeeTabIndex: 0,
};

const rootReducer = (state: RootState = initialState, action: any): RootState => {
  switch (action.type) {
    case 'SET_ROLE':
      return { ...state, role: action.payload };
    case 'SET_USER_DETIAL':
      return { ...state, userDetail: action.payload };
    case 'SET_BOOKING_DETIAL': {
      return { ...state, bookingDetail: action.payload };
    }
    case 'SET_SERVICE_SEARCH': {
      return { ...state, searchService: action.payload };
    }
    case 'SET_BOOKING_DETAIL_TIMELINE': {
      return { ...state, bookingDetailTimeline: action.payload };
    }
    case 'SET_TIMESLOT': {
      return { ...state, getTimeSlot: action.payload };
    }
    case 'SET_EMPLOYEE_LIST': {
      return { ...state, getemployeeList: action.payload };
    }
    case 'ACTIVE_CATEGORY': {
      return { ...state, activeCategory: action.payload };
    }
    case 'SET_WORK_EMPLOYEE_LIST': {
      return { ...state, getWorkEmployee: action.payload };
    }
    case 'GET_FEEDBACK': {
      return { ...state, feedback: action.payload };
    }
    case 'ACTIVE_PROJECT_TAB': {
      return { ...state, activeProjectTabIndex: action.payload };
    }
    case 'ACTIVE_EMPLOYEE_TAB': {
      return { ...state, activeEmployeeTabIndex: action.payload };
    }

    default:
      return state;
  }
};

const store = createStore(rootReducer);

export const setRole = (role: string) => {
  return {
    type: 'SET_ROLE',
    payload: role,
  } as const;
};

export const setUserDetail = (userDetail: UserResponse | null) => {
  return {
    type: 'SET_USER_DETIAL',
    payload: userDetail,
  } as const;
};

export const setBookingStoreDetails = (bookingDetail: BookingDetailsResponse | null) => {
  return {
    type: 'SET_BOOKING_DETIAL',
    payload: bookingDetail,
  } as const;
};

export const setBookingDetailsTimeline = (bookingDetailTimeline: BookingDetailTimelineResponse) => {
  return {
    type: 'SET_BOOKING_DETAIL_TIMELINE',
    payload: bookingDetailTimeline,
  } as const;
};

export const getEmployeeList = (employeeList: EmployeeResponse[]) => {
  return {
    type: 'SET_EMPLOYEE_LIST',
    payload: employeeList,
  } as const;
};

export const getAssignEmployees = (getAssignEmployee: AssignEmployee[]) => {
  return {
    type: 'GET_ASSIGN_EMPLOYEE',
    payload: getAssignEmployee,
  } as const;
};

export const setActiveSelectedCategory = (activeCategory: categoryResposeType | null | undefined) => {
  return {
    type: 'ACTIVE_CATEGORY',
    payload: activeCategory,
  } as const;
};
export const getWorkEmployeeList = (getWorkEmployee: EmployeeResponse[]) => {
  return {
    type: 'SET_WORK_EMPLOYEE_LIST',
    payload: getWorkEmployee,
  } as const;
};

export const getFeedbackData = (feedback: FeedbackType) => {
  return {
    type: 'GET_FEEDBACK',
    payload: feedback,
  } as const;
};
export const setSearchService = (searchService: string) => {
  return {
    type: 'SET_SERVICE_SEARCH',
    payload: searchService,
  } as const;
};
export const setActiveProjectTabIndex = (activeProjectTabIndex: number) => {
  return {
    type: 'ACTIVE_PROJECT_TAB',
    payload: activeProjectTabIndex,
  } as const;
};
export const setActiveEmployeeTabIndex = (activeEmployeeTabIndex: number) => {
  return {
    type: 'ACTIVE_EMPLOYEE_TAB',
    payload: activeEmployeeTabIndex,
  } as const;
};

export default store;
