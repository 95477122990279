/*
Created By Shanthi Palani
*/

import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import store from './store';
import './styling/App.scss';
import { Routers } from './routers';

function App() {
  return (
    <Provider store={store}>
      <Routers />
    </Provider>
  );
}

export default App;
