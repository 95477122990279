import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  styled,
  Tab,
  Tabs,
  TextField,
  Theme,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RootState, setActiveProjectTabIndex } from "../../store";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  sx: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, sx, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={sx}
      {...other}
    >
      {value === index && <Box sx={{ mt: 3 }}>{children}</Box>}
    </Box>
  );
}

const StyledTabs = styled(({ className, ...other }: any) => (
  <Tabs
    {...other}
    classes={{
      root: className,
      flexContainer: "flexContainer",
      indicator: "indicator",
    }}
  ></Tabs>
))(({}: { theme: Theme }) => ({
  "& .MuiButtonBase-root": {
    // margin: '0 0px',
    textTransform: "capitalize",
  },
  "& .MuiTabs-indicator": {
    height: "3px",
    borderRadius: "6px 6px",
  },
}));

const ProjectList = (props: any) => {
  const {
    setProjectList,
    projectList,
    setSelectedService,
    setStepperProfile,
    setProjectId,
    setbookingStatus,
    isSearch,
    setIsSearch,
    searchText,
    setSearchText,
    finalSearchValue,
    setFinalSearchValue,
  } = props;

  const StyledCard = styled(Card)`
    min-width: 325px;
    width: 325px;
    max-width: 100%;
    padding: 10px 15px;
    margin: 0.5rem;
    box-shadow: 0px 0px 2rem #ccc;
    border-radius: 12px;
  `;

  const StyledGrid = styled(Grid)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    ${props => props.theme.breakpoints.down("lg")} {
      justify-content: center;
    }
  `;

  const ViewDetailsLink = styled(Button)`
    text-align: left;
    align-items: flex-start;
    text-transform: capitalize;

    &:hover {
      background: #568a60;
      opacity: 0.8;

    }
  `;

  const Loader = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:'20vw', width: '75vw' }}>
      <CircularProgress />
    </div>
  )

  const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT;
  const accessToken = sessionStorage.getItem("access_token");
  let role = sessionStorage.getItem("role");

  const dispatch = useDispatch();
  const activeProjectTabIndex = useSelector(
    (state: RootState) => state.activeProjectTabIndex
  );
  type StatusType = "true" | "false" | "none";

  const [defaultPageSize, setDefaultPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showPaymentOptions, setShowPaymentOptions] = useState<boolean>(true);
  const [paymentValue, setPaymentValue] = useState<StatusType>("none");
  const [statusValues, setStatusValues] = useState<string[]>([]);
  const [filterClicked, setFilterClicked] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setActiveProjectTabIndex(newValue));
    setCurrentPage(1);
  };
  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const tabList =
    role === "user"
      ? ["All Projects", "In Progress", "Completed"]
      : ["All Projects", "New Request", "In Progress", "Completed"];

  useEffect(() => {
    fetchProjects(activeProjectTabIndex);
  }, [
    finalSearchValue,
    currentPage,
    activeProjectTabIndex,
    defaultPageSize,
    filterClicked,
  ]);

  const fetchProjects = async (index: number) => {
    try {
      let flag;
      if (index === 0) {
        flag = "all";
      }
      if (index === 1) {
        flag = role === "user" ? "in_progress" : "new";
      }

      if (index === 2) {
        flag = role === "user" ? "completed" : "in_progress";
      }

      if (index === 3) {
        flag = role === "user" ? "all" : "completed";
      }

      let url = `${apiEndpoint}/bookings/?page=${currentPage}&page_size=${defaultPageSize}&status=${flag}`;

      if (paymentValue !== undefined && paymentValue !== "none") {
        url = `${url}&payment_status=${paymentValue}`;
      }
      if (statusValues.length > 0) {
        statusValues.forEach((status) => {
          url = `${url}&stage=${status}`;
        });
      }

      if (isSearch && finalSearchValue && finalSearchValue.length > 3) {
        url = `${url}&search_term=${finalSearchValue}`;
      }
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response && response.data && response.data.results) {
        setProjectList(response.data.results);
        setTotalCount(response.data.count);
      } else {
        console.log("No projects found");
      }
    } catch (error) {
      console.error("Error fetching Projects:", error);
    }
  };

  const redirectToMap = (address: any) => {
    const latitude = address.latitude;
    const longitude = address.longitude;
    const zoomLevel = 20;
    let mapUrl;
    if (latitude && longitude)
      // Construct the Google Maps URL with the provided latitude and longitude
      mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}&ll=${latitude},${longitude}&z=${zoomLevel}`;
    else {
      const street = address.address;
      const city = address.city;
      const state = address.state;
      const county = address.country;
      const postalCode = address.pincode;
      mapUrl = `https://www.google.com/maps?q=${street},${city},${state},${county},${postalCode}`;
    }
    // Open the URL in a new tab or window
    window.open(mapUrl, "_blank");
  };

  function convertToTitleCase(inputString: string) {
    // Split the string into words
    const words = inputString.split("_");

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the words back together with a space
    const resultString = capitalizedWords.join(" ");

    return resultString;
  }

  const formatDate = (inputDate: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const formattedDate: string = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      if (searchText.length > 3) {
        dispatch(setActiveProjectTabIndex(0));
        setFinalSearchValue(searchText);
        setCurrentPage(1);
        setIsSearch(true);
      } else {
        toast.warning(`Search value should be more than 3 characters`, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    }
  };
  const handlePaste = (event: any) => {
    setIsSearch(true);
    setFinalSearchValue(searchText);
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setStatusValues((prevStatusValues: string[]) => {
      if (prevStatusValues.includes(value)) {
        return prevStatusValues.filter((status: string) => status !== value);
      } else {
        return [...prevStatusValues, value];
      }
    });
  };

  const handleSubmit = () => {
    setCurrentPage(1);
    setFilterClicked(filterClicked + 1);
    setOpenModal(false);
  };

  const updatePaymentValue = (newStatus: StatusType) => {
    setPaymentValue(newStatus);
  };

  const handleClearFilters = () => {
    updatePaymentValue("none");
    setStatusValues([]);
    setFilterClicked(filterClicked + 1);
    setOpenModal(false);
    setCurrentPage(1);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: {
            xs: "column-reverse",
            lg: "row"
          }
        }}
      >
        <StyledTabs value={activeProjectTabIndex} onChange={handleChange}>
          {tabList.map((tab) => {
            return <Tab label={tab} />;
          })}
        </StyledTabs>
        <Box
          sx={{
            textAlign: "right",
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxWidth: "400px"
          }}
        >
          <TextField
            placeholder="Search for projects"
            variant="outlined"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              if (e.target.value === "") {
                setIsSearch(false);
                setFinalSearchValue("");
              }
            }}
            sx={{width: "100%"}}
            onPaste={handlePaste}
            onKeyDown={handleKeyPress}
            InputProps={{
              endAdornment: (
                <>
                  {searchText && (
                    <IconButton
                      onClick={() => {
                        setSearchText("");
                        setIsSearch(false);
                        setFinalSearchValue("");
                        setTotalCount(0);
                        dispatch(setActiveProjectTabIndex(0));
                      }}
                      edge="end"
                      sx={{ mr: 0.2 }}
                    >
                      <CloseIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => {
                      if (searchText === "") {
                        toast.warning(`Search value is empty`, {
                          position: "top-right",
                          autoClose: 3000,
                        });
                      } else {
                        setCurrentPage(1);
                        setIsSearch(true);
                        setFinalSearchValue(searchText);
                      }
                    }}
                    edge="end"
                    sx={{ backgroundColor: "#EEFEF1", mr: 0.2 }}
                  >
                    <SearchIcon />
                  </IconButton>
                  {role === "vendor" && (
                    <IconButton onClick={() => setOpenModal(true)}>
                      <FilterListIcon />
                    </IconButton>
                  )}
                </>
              ),
              sx: {
                background: "white", // Set background color to white
                border: "none", // Remove the border
                borderRadius: "30px",
                padding: "0 10px",
                width: "100%",
                my: "2rem",
              },
            }}
          />
        </Box>
      </Box>
      <TabPanel
        value={activeProjectTabIndex}
        index={activeProjectTabIndex}
        sx={{ mb: 2 }}
      >
        {finalSearchValue && projectList && projectList.length > 0 && (
          <Box sx={{ mb: 3 }}>
            Search result for : <b>{finalSearchValue}</b>
          </Box>
        )}
        <StyledGrid container spacing={0}>
          {!projectList ? (<Loader />) :
          projectList && projectList.length > 0 ? (
            projectList.map((project: any) => (
              <StyledCard sx={{width: {xs: '100%', sm: '375px'}}}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        flex: "1",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "8px",
                      }}
                    >
                      <span style={{ fontSize: "16px", color: "#1F261F" }}>
                        {project.project_id}
                      </span>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      background: "#A8DBDE",
                      padding: "10px",
                      borderRadius: "8px",
                      fontSize: "12px",
                    }}
                  >
                    {project?.booking_status &&
                      convertToTitleCase(project?.booking_status)}
                  </Box>
                </Box>
                {/* {role === 'vendor' && ( */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  {role === "vendor" ? (
                    <Box>
                      {project.contact_name && (
                        <div
                          style={{
                            marginLeft: "5px",
                            fontSize: "12px",
                            color: "#A7A7A7",
                          }}
                        >
                          {project.contact_name}
                        </div>
                      )}
                      {project.contact_number && (
                        <div
                          style={{
                            marginLeft: "5px",
                            fontSize: "12px",
                            color: "#A7A7A7",
                          }}
                        >
                          {project.contact_number}
                        </div>
                      )}
                    </Box>
                  ) : (
                    <Box>
                    <Box
                      sx={{
                        marginLeft: "5px",
                        fontSize: "12px",
                        color: "#1F261F",
                      }}
                    >
                      {`Services (${project?.service_list.length})`}
                    </Box>
                    <Box sx={{ height: "35px" }}>
                      {project?.service_list
                        ?.slice(0, 2)
                        .map((service: any, index: number) => {
                          return (
                            <Box sx={{ ml: 1, mt: 0.2 }}>
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#A7A7A7",
                                }}
                              >
                                {service.name}{" "}
                                {index ===
                                project?.service_list?.slice(0, 2).length -
                                  1
                                  ? ""
                                  : ","}
                                {index ===
                                  project?.service_list?.slice(0, 2)
                                    .length -
                                    1 && project.service_list.length > 2
                                  ? " ..."
                                  : ""}
                              </span>
                            </Box>
                          );
                        })}
                    </Box>
                  </Box>
                  )}
                  <Box
                      sx={{
                        background: "#A8DBDE",
                        width: "120px",
                        textAlign: "center",
                        p: "5px",
                        borderRadius: "8px",
                      }}
                    >
                      <div style={{ fontSize: "16px", color: "#1F261F" }}>
                        OTP
                      </div>
                      <div style={{ fontSize: "32px", color: "#1F261F" }}>
                        {project.otp ? project.otp : "-"}
                      </div>
                    </Box>
                  {/* <Box>
                        <span style={{ marginLeft: '5px', fontSize: '14px', color: '#1F261F' }}>No of services</span>
                        <div style={{ marginLeft: '5px', fontSize: '12px', color: '#A7A7A7', textAlign: 'center' }}>
                          {project.service_list.length}
                        </div>
                      </Box> */}
                  {/* <Box sx={{ display: 'flex' }}>3</Box> */}
                </Box>
                {/* )} */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Box>
                    <span
                      style={{
                        marginLeft: "5px",
                        fontSize: "12px",
                        color: "#1F261F",
                      }}
                    >
                      Requested date : {formatDate(project.submitted_at)}
                    </span>
                  </Box>

                  {/* OTP section (conditionally rendered based on 'role') */}
                  {role === "vendor" && (
                    <ViewDetailsLink
                      color="primary"
                      onClick={() => {
                        redirectToMap(project?.address_details);
                      }}
                    >
                      Get directions
                    </ViewDetailsLink>
                  )}
                </Box>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 3,
                  }}
                >
                  {/* <Grid item xs={12} md={6}> */}
                  <Box
                    sx={{
                      background: "#A8DBDE",
                      padding: "10px",
                      borderRadius: "8px",
                      fontSize: "12px",
                    }}
                  >
                    {project?.stage && convertToTitleCase(project.stage)}
                  </Box>

                  <ViewDetailsLink
                    // color="primary"
                    variant="contained"
                    onClick={() => {
                      let selectedService = {
                        selectedService: project.id,
                        projectId: project.project_id,
                        status: project.booking_status,
                      };
                      sessionStorage.setItem(
                        "selectedService",
                        JSON.stringify(selectedService)
                      );
                      setSelectedService(project.id);
                      setStepperProfile(true);
                      setProjectId(project.project_id);
                      setbookingStatus(project.booking_status);
                    }}
                  >
                    View Project
                  </ViewDetailsLink>
                </Grid>
              </StyledCard>
            ))
          ) : (
            <Box sx={{ pl: 3, mt: 4 }}>No data</Box>
          )}
        </StyledGrid>

        {/* {totalCount / defaultPageSize > 1 && ( */}
        {totalCount && totalCount > 0 ? (
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: {
            xs: "column-reverse",
            lg: "row"
          } }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                mr: 3,
              }}
            >
              <InputLabel id="demo-simple-select-label">
                Rows per page
              </InputLabel>
              <Select
                value={defaultPageSize}
                onChange={(e) => {
                  setDefaultPageSize(Number(e.target.value));
                }}
                sx={{ ml: 2, height: "30px" }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={55}>50</MenuItem>
              </Select>
            </Box>
            <Pagination
              variant="outlined"
              color="primary"
              count={Math.ceil(totalCount / defaultPageSize)}
              page={currentPage}
              onChange={handlePaginationChange}
              sx={{ mt: 2, mb: 2 }}
            />
          </Grid>
        ) : null}
      </TabPanel>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingRight: "55px",
          minWidth: "500px",
        }}
      >
        <Box
          sx={{
            width: "450px",
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: "0px 4px 25px 0px #00000040",
              outline: "none",
              padding: "16px",
              fontSize: "14px",
              display: "flex",
            }}
          >
            <Box
              sx={{
                width: "30%",
                backgroundColor: "#F3F3F3",
                borderRadius: "10px 0 0 10px",
                padding: "16px",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontSize: "17px",
                  marginBottom: "8px",
                  lineHeight: "24px",
                  cursor: "pointer",
                  fontWeight: showPaymentOptions ? "bold" : "normal",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  setShowPaymentOptions(true);
                }}
              >
                Payment
                {showPaymentOptions && (
                  <ArrowForwardIosIcon
                    sx={{
                      fontSize: "15px",
                      verticalAlign: "middle",
                      transform: "rotate(180deg)",
                      marginLeft: "15px",
                    }}
                  />
                )}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontSize: "17px",
                  lineHeight: "24px",
                  cursor: "pointer",
                  fontWeight: !showPaymentOptions ? "bold" : "normal",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  setShowPaymentOptions(false);
                }}
              >
                Status
                {!showPaymentOptions && (
                  <ArrowForwardIosIcon
                    sx={{
                      fontSize: "15px",
                      verticalAlign: "middle",
                      transform: "rotate(180deg)",
                      marginLeft: "35px",
                    }}
                  />
                )}
              </Typography>
            </Box>
            <Box sx={{ width: "50%", padding: "16px" }}>
              {showPaymentOptions ? (
                <FormControl component="fieldset" sx={{ mb: 2 }}>
                  <RadioGroup
                    aria-label="payment"
                    name="payment"
                    value={paymentValue}
                    onChange={(event) =>
                      updatePaymentValue(event.target.value as StatusType)
                    }
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="True"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="False"
                    />
                  </RadioGroup>
                </FormControl>
              ) : (
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={statusValues.includes(
                            "slot_pending_from_customer"
                          )}
                          onChange={handleStatusChange}
                          value="slot_pending_from_customer"
                        />
                      }
                      label="Inspection: pending slots"
                      sx={{ whiteSpace: "nowrap" }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={statusValues.includes(
                            "work_slot_pending_from_customer"
                          )}
                          onChange={handleStatusChange}
                          value="work_slot_pending_from_customer"
                        />
                      }
                      label="Work: pending slots"
                      sx={{ whiteSpace: "nowrap" }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={statusValues.includes(
                            "quotation_acceptance_pending"
                          )}
                          onChange={handleStatusChange}
                          value="quotation_acceptance_pending"
                        />
                      }
                      label="Quotation: pending acceptance"
                      sx={{ whiteSpace: "nowrap" }}
                    />
                  </FormGroup>
                </FormControl>
              )}
              <div style={{ display: "flex", gap: "16px", width: "230px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  style={{ whiteSpace: "nowrap", flex: "1" }}
                >
                  Apply filters
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleClearFilters();
                    dispatch(setActiveProjectTabIndex(0));
                  }}
                  style={{ whiteSpace: "nowrap", flex: "1" }}
                >
                  Clear filters
                </Button>
              </div>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ProjectList;
