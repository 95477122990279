import React from 'react';
import { Box, Card, CardContent, CardHeader, Avatar, Typography, Button, Divider, IconButton } from '@mui/material';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';

const EmployeeCardContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
});

const ViewDetailsLink = styled(Button)`
  text-align: left;
  padding: 0;
  align-items: flex-start;
  text-transform: capitalize;
`;

const DetailsBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  padding: '0 20px',
});

const EmployeeCard = (props: any) => {
  const formatDate = (inputDate: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    const formattedDate: string = new Date(inputDate).toLocaleDateString('en-US', options);
    return formattedDate;
  };

  const getInitials = (fullName: string): string => {
    const words = fullName.split(' ');

    if (words.length === 1) {
      return words[0].slice(0, 2);
    }

    return words
      .map((word) => word.charAt(0))
      .slice(0, 2)
      .join('');
  };

  const { employee, setSelectedEmployeeId, setEditEmployee } = props;
  return (
    <Card sx={{ mt: 4, mr: 1.5 }}>
      {employee && (
        <>
          <CardHeader
            action={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ViewDetailsLink
                  color="primary"
                  onClick={() => {
                    setEditEmployee(true);
                  }}
                >
                  Edit
                </ViewDetailsLink>
                <IconButton
                  onClick={() => setSelectedEmployeeId(null)}
                  color="primary"
                  aria-label="call"
                  sx={{ border: '1px solid #718875', borderRadius: '50%', padding: '3px', mr: 1 }}
                >
                  <CloseIcon sx={{ fontSize: '12px' }} />
                </IconButton>
              </Box>
            }
          />
          <CardContent sx={{ p: 0 }}>
            <EmployeeCardContainer>
              {employee?.profile_picture_url && employee.profile_picture_url !== '' ? (
                <Avatar sx={{ width: 80, height: 80, marginBottom: 1 }} src={employee.profile_picture_url}></Avatar>
              ) : (
                <Avatar sx={{ bgcolor: 'orange', width: 72, height: 72, marginBottom: 1, textTransform: 'capitalize' }}>
                  {getInitials(`${employee.first_name} ${employee.last_name}`)}
                </Avatar>
              )}

              <Typography
                variant="body1"
                sx={{
                  color: '#1F261F',
                  //   fontFamily: 'Kufam',
                  fontSize: '14px',
                  fontWeight: 400,
                  textTransform: 'capitalize',
                }}
              >
                {employee.name}
              </Typography>
              <Typography
                sx={{
                  color: '#1F261F',
                  //   fontFamily: 'Kufam',
                  fontSize: '14px',
                  fontWeight: 400,
                  textTransform: 'capitalize',
                }}
                variant="body1"
              >
                #{employee.emp_code}
              </Typography>
              <Typography
                sx={{
                  color: '#1F261F',
                  //   fontFamily: 'Kufam',
                  fontSize: '14px',
                  fontWeight: 400,
                  textTransform: 'capitalize',
                }}
                variant="body1"
              >
                {employee.designation}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: '#A7A7A7',
                  fontSize: '12px',
                  fontWeight: 400,
                  textTransform: 'capitalize',
                }}
              >
                {employee.phone_number}
              </Typography>

              <Divider flexItem />
              <DetailsBox>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#A7A7A7',
                    fontSize: '12px',
                    fontWeight: 400,
                    textTransform: 'capitalize',
                  }}
                >
                  Gender:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#1F261F',
                    fontSize: '12px',
                    fontWeight: 400,
                    textTransform: 'capitalize',
                  }}
                >
                  {`${employee.gender[0]}${employee.gender.slice(1).toLowerCase()}`}
                </Typography>
              </DetailsBox>
              <DetailsBox>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#A7A7A7',
                    fontSize: '12px',
                    fontWeight: 400,
                    textTransform: 'capitalize',
                  }}
                >
                  Date of Birth:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#1F261F',
                    fontSize: '12px',
                    fontWeight: 400,
                  }}
                >
                  {formatDate(employee.dob)}
                </Typography>
              </DetailsBox>
              <DetailsBox>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#A7A7A7',
                    fontSize: '12px',
                    fontWeight: 400,
                    textTransform: 'capitalize',
                  }}
                >
                  Address:
                </Typography>
                <Box
                  // variant="body1"
                  sx={{
                    color: '#1F261F',
                    fontSize: '12px',
                    fontWeight: 400,
                    textAlign: 'right',
                    overflow: 'hidden',
                  }}
                >
                  {/* <Typography
                    variant="body1"
                    sx={{
                      color: '#1F261F',
                      fontSize: '12px',
                      fontWeight: 400,
                      textAlign: 'right',
                      wordWrap: 'break-word',
                      mb: 1,
                    }}
                  >
                    {employee?.addresses[0]?.address}
                  </Typography> */}
                  {employee?.addresses && employee.addresses.length > 0 && (
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#1F261F',
                        fontSize: '12px',
                        fontWeight: 400,
                        textAlign: 'right',
                        mb: 1,
                      }}
                    >
                      {employee?.addresses[0]?.address}, {employee?.addresses[0]?.city}, {employee?.addresses[0]?.state}
                      , {employee?.addresses[0]?.country}, {employee?.addresses[0]?.pincode}
                    </Typography>
                  )}
                  {/* <Typography
                    variant="body1"
                    sx={{
                      color: '#1F261F',
                      fontSize: '12px',
                      fontWeight: 400,
                      textAlign: 'right',
                      mb: 1,
                    }}
                  >
                    {employee?.addresses[0]?.state}
                  </Typography> */}
                  {/* <Typography
                    variant="body1"
                    sx={{
                      color: '#1F261F',
                      fontSize: '12px',
                      fontWeight: 400,
                      textAlign: 'right',
                      mb: 1,
                    }}
                  >
                    {employee?.addresses[0]?.country}
                  </Typography> */}
                  {/* <Typography
                    variant="body1"
                    sx={{
                      color: '#1F261F',
                      fontSize: '12px',
                      fontWeight: 400,
                      textAlign: 'right',
                      mb: 1,
                    }}
                  >
                    {employee?.addresses[0]?.pincode}
                  </Typography> */}
                </Box>
              </DetailsBox>
              {/* <DetailsBox>
            <Typography
              variant="body1"
              sx={{
                color: '#A7A7A7',
                fontSize: '12px',
                fontWeight: 400,
                textTransform: 'capitalize',
              }}
            >
              Aadhar:
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: '#1F261F',
                fontSize: '12px',
                fontWeight: 400,
                textTransform: 'capitalize',
              }}
            >
              {employee.aadhar}
            </Typography>
          </DetailsBox> */}
              {/* <Divider flexItem />
              <DetailsBox>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#A7A7A7',
                    fontSize: '12px',
                    fontWeight: 400,
                    textTransform: 'capitalize',
                  }}
                >
                  Experience:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#1F261F',
                    fontSize: '12px',
                    fontWeight: 400,
                    textTransform: 'capitalize',
                  }}
                >
                  {employee.experience}
                </Typography>
              </DetailsBox>
              <DetailsBox>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#A7A7A7',
                    fontSize: '12px',
                    fontWeight: 400,
                    textTransform: 'capitalize',
                  }}
                >
                  Joining Date:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#1F261F',
                    fontSize: '12px',
                    fontWeight: 400,
                    textTransform: 'capitalize',
                  }}
                >
                  {formatDate(employee.joining_date)}
                </Typography>
              </DetailsBox>
              <Divider flexItem />
              <DetailsBox>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#A7A7A7',
                    fontSize: '12px',
                    fontWeight: 400,
                    textTransform: 'capitalize',
                  }}
                >
                  Skills:
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    color: '#1F261F',
                    fontSize: '12px',
                    fontWeight: 400,
                    textTransform: 'capitalize',
                  }}
                >
                  {employee &&
                    employee.skills &&
                    employee.skills.length > 0 &&
                    employee.skills.map((skill: string, index: number) => {
                      return (
                        <React.Fragment>
                          <span
                            style={{
                              border: '1px solid #568A60',
                              backgroundColor: '#fff',
                              padding: '5px 10px',
                              borderRadius: '5px',
                              marginRight: index === employee.skills.length - 1 ? 0 : 5,
                              color: '#568A60',
                            }}
                          >
                            {skill}
                          </span>
                        </React.Fragment>
                      );
                    })}
                </Typography>
              </DetailsBox>
              <Divider flexItem /> */}
              {/* {employee.skills.map((skill: string, index: number) => {
            return (
              <React.Fragment>
                <DetailsBox>
                  <Typography
                    variant="body1"
                    sx={{
                      color: '#1F261F',
                      fontSize: '12px',
                      fontWeight: 400,
                      textTransform: 'capitalize',
                    }}
                  >
                    {skill}
                  </Typography>
                </DetailsBox>
                <Divider flexItem />
              </React.Fragment>
            );
          })} */}
            </EmployeeCardContainer>
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default EmployeeCard;
