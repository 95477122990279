import { Typography, Box, useMediaQuery } from "@mui/material";
import { Logo } from "../home/newHome";
import { theme } from "../../utils/theme";
import {
  Container,
  Header,
  LogoContainer,
  Text,
  TextBox,
  Title,
  SubTitle,
} from "./aboutStyles";
import NavHeader from "../../components/core/header";
import { useNavigate } from "react-router-dom";

const Person = require("../../assets/aboutPerson.svg").default;
const Person1 = require("../../assets/aboutPerson2.svg").default;
const Person2 = require("../../assets/aboutPerson3.svg").default;
const Person3 = require("../../assets/aboutPerson4.svg").default;
const Person4 = require("../../assets/aboutPerson5.svg").default;

export default function AboutPage() {
  const navigate = useNavigate();
  const mobileS = useMediaQuery(theme.breakpoints.up("xs"));
  const mobile = useMediaQuery(theme.breakpoints.up("sm"));
  const tabelt = useMediaQuery(theme.breakpoints.up("md"));
  const laptop = useMediaQuery(theme.breakpoints.up("lg"));
  const desktop = useMediaQuery(theme.breakpoints.up("xl"));

  const allScreens = mobileS || mobile || tabelt || laptop || desktop;

  return (
    <Container sx={{ width: "100vw", m:0, p:0 }}>
      <Box sx={{ px: 3, mb: 0, width: "100%", backgroundColor: '#96c19b5e'}}><NavHeader /></Box>
      <Header
        position="static"
        style={{
          borderRadius:
            desktop || laptop ? "0px 0px 240px 0px" : "0px 0px 120px 0px",
        }}
      >
        <Box>
          {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <LogoContainer>
              <img
                src={Logo}
                alt="Company Logo"
                style={{ marginRight: 4, height: 40 }}
                onClick={() => {
                  navigate("/");
                }}
              />
              <Typography
                variant="h2"
                sx={{
                  fontSize: allScreens ? "20px" : "12px",
                }}
              >
                HelloFixy
              </Typography>
            </LogoContainer>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: allScreens ? "36px" : "25px",
                color: "#36423C",
              }}
            >
              About us
            </Typography>
            <Typography sx={{ textAlign: "center", opacity: 0 }}>
              About us
            </Typography>
          </Box> */}

          {/* <Box sx={{ border: "1px solid #A4D2B9" }}></Box> */}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              paddingTop: "50px",
              paddingBottom: "50px",
              rowGap: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: allScreens ? "24px" : "18px",
                textAlign: "center",
              }}
            >
              Our story
            </Typography>
            <Typography
              sx={{
                fontSize: allScreens ? "40px" : "30px",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              Trusted services, reliable experts
            </Typography>

            <Typography
              sx={{
                textAlign: "center",
                fontSize: "1rem",
                lineHeight: "2rem",
                margin: {
                  xl: '0 15rem',
                  lg: '0 10rem',
                  md: '0 5rem',
                  sm: '0 2.5rem',
                  xs: '0 0',
                }
              }}
            >
              HelloFixy is your go-to destination for reliable and trusted
              all-in-one-stop service! Our platform connects you with skilled
              and experienced professionals who are dedicated to tackling a wide
              range of household tasks. Whether you need repairs, installations,
              or maintenance, our handpicked team of trustworthy handymen is
              ready to assist.
            </Typography>
          </Box>
        </Box>
      </Header>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingLeft: laptop ? "148px" : tabelt ? "50px" : "10px",
          paddingRight: laptop ? "148px" : tabelt ? "50px" : "10px",
          paddingTop: "50px",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: desktop || laptop || tabelt ? "40px" : "30px",
            textAlign: "center",
            marginBottom: 15,
          }}
        >
          Our Valuable Honorary Advisors
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: laptop || tabelt ? "row" : "column",
            columnGap: 4,
            marginBottom: 4,
            justifyContent: "center",
            alignItems: laptop || desktop ? "" : "center",
          }}
        >
          <img
            src={Person}
            alt="CoverImage"
            style={{
              height: "275px",
              width: "275px",
            }}
          />
          <TextBox style={{ rowGap: 30, marginTop: laptop || tabelt ? 0 : 20 }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: tabelt || laptop || desktop ? "" : "center",
                justifyContent: "center",
              }}
            >
              <Title>MD JAYASURIYA</Title>
              <SubTitle>Jayasuriya Aero Private Limited</SubTitle>
              <SubTitle>
                Manufacturing Aerospace and Defence products in Chennai.
              </SubTitle>
            </Box>
            <TextBox>
              <Text>Vision for the Future:</Text>
              <Text>
                Under MD Jayasuriya's leadership, Jayasuriya Aero Private
                Limited continues to innovate, pushing the boundaries of
                aerospace and defense technology. With a vision for a
                self-reliant and technologically advanced India, MD Jayasuriya
                remains at the forefront of shaping the future of the Aerospace
                and Defense industry.
              </Text>
            </TextBox>
          </TextBox>
        </Box>
        <Box>
          <Text>
            MD Jayasuriya is the driving force behind Jayasuriya Aero Private
            Limited, a leading manufacturing company based in Chennai
            specializing in Aerospace and Defense products. With a passion for
            innovation and a commitment to excellence, MD Jayasuriya has played
            a pivotal role in establishing the company as a key player in the
            industry.
          </Text>
          <TextBox
            sx={{
              marginTop: 3,
            }}
          >
            <Text>Awards and Recognitions</Text>
            <Text>
              Under MD Jayasuriya's leadership, Jayasuriya Aero Private Limited
              has received numerous accolades, showcasing the company's
              commitment to excellence and indigenization in the Aerospace and
              Defense sector. Excellence in Aerospace Indigenization (2016):
              Recognizing MD Jayasuriya's outstanding contribution to
              indigenization, the company was honored with the 'Excellence in
              Aerospace Indigenization' award by SIATI in 2016. This prestigious
              award was presented by Dr. K.S. Kiran Kumar, Chairman of ISRO and
              the Department of Space, acknowledging the company's significant
              achievements in advancing aerospace technology. Best in
              Indigenization of Aerospace Components (2020): The commitment to
              indigenization continued to be acknowledged when Jayasuriya Aero
              received the 'Best in Indigenization of Aerospace Components'
              award from AeSI in 2020. Dr. R.K. Tyagi, President of AeSI,
              presented the award, recognizing MD Jayasuriya's leadership in
              promoting self-reliance in aerospace manufacturing. Best Import
              Substitute Award (Year): MD Jayasuriya's strategic vision and
              emphasis on research and development earned the company the 'Best
              Import Substitute' award from AIDIAT. This recognition, presented
              by Dr. S. Christopher, Chairman of DRDO, underscores the company's
              commitment to reducing dependence on imports in the defense
              sector. Best MSME-Indigenization Award (2023): The most recent
              feather in the cap is the 'Best MSME-Indigenization' award in
              2023, presented by Shri. Ajay Bhatt, Minister of State for
              Defense. This award highlights MD Jayasuriya's consistent efforts
              in steering the company towards excellence, innovation, and
              self-sufficiency in defense manufacturing.
            </Text>
          </TextBox>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: laptop || tabelt ? "row" : "column",
            columnGap: 4,
            marginBottom: 4,
            marginTop: 8,
            justifyContent: "center",
            alignItems: laptop || desktop ? "" : "center",
          }}
        >
          <img
            src={Person1}
            alt="CoverImage"
            style={{
              height: "275px",
              width: "275px",
            }}
          />
          <TextBox>
            <Box
              sx={{
                marginTop: laptop || tabelt ? 0 : 5,
                display: "flex",
                flexDirection: "column",
                alignItems: tabelt || laptop || desktop ? "" : "center",
                justifyContent: "center",
              }}
            >
              <Title>Dr. Anandhi Muralidharan</Title>
              <SubTitle>MBA, PhD</SubTitle>
            </Box>
            <TextBox
              sx={{
                marginTop: 3,
              }}
            >
              <Text>Vision and Impact:</Text>
              <Text>
                Dr. Anandhi Muralidharan continues to be a driving force in the
                industry, advocating for advancements in logistics practices and
                contributing to the development of professionals worldwide. Her
                vision for the future includes fostering sustainable and
                resilient supply chains, leveraging technology, and championing
                innovation.
              </Text>
            </TextBox>
          </TextBox>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 3 }}>
          <TextBox>
            <Text>Background:</Text>
            <Text>
              Dr. Anandhi Muralidharan is a distinguished professional with a
              profound impact on the Logistics & Supply Chain Management (LSCM)
              domain. Armed with an MBA and a PhD, her academic credentials
              complement her extensive practical experience in the industry.
            </Text>
          </TextBox>
          <TextBox>
            <Text>Professional Journey:</Text>
            <Text>
              With an illustrious career spanning 22 years, Dr. Anandhi has
              emerged as a trailblazer in her field. She has served as a United
              Nations trainer and consultant, specializing in Logistics & Supply
              Chain management. Her role involved imparting valuable insights
              and knowledge to professionals across the globe, contributing to
              the enhancement of international standards in the field.
            </Text>
            <Text>
              As the former Deputy Director of the Confederation of Indian
              Industry (CII) Supply Chain and Logistics vertical, Dr. Anandhi
              played a pivotal role in shaping the industry landscape. Her
              strategic vision and leadership significantly impacted CII's
              initiatives, fostering collaboration, innovation, and best
              practices in the supply chain domain.
            </Text>
          </TextBox>
          <TextBox>
            <Text>Corporate Consultancy:</Text>
            <Text>
              Dr. Anandhi Muralidharan is not only recognized for her
              institutional contributions but also as a seasoned corporate
              consultant. Her expertise extends to both domestic and
              international projects in the LSCM domain. As a consultant, she
              has been instrumental in steering organizations towards
              operational excellence and sustainable growth. Her advisory role
              has been crucial in optimizing supply chain processes, reducing
              costs, and enhancing overall efficiency.
            </Text>
          </TextBox>
          <TextBox>
            <Text>Awards and Recognition:</Text>
            <Text>
              Dr. Anandhi's commitment and excellence have earned her
              recognition in the form of awards and accolades. Her contributions
              to the United Nations training programs and her leadership at CII
              have been acknowledged as significant milestones in the
              development of Logistics & Supply Chain Management.
            </Text>
            <Text>
              In summary, Dr. Anandhi Muralidharan's journey is a testament to
              her unwavering commitment to excellence in Logistics & Supply
              Chain Management. Her combined academic prowess, extensive
              experience, and visionary leadership position her as a luminary in
              the field, shaping the trajectory of the industry for years to
              come.
            </Text>
          </TextBox>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: laptop || tabelt ? "row" : "column",
            columnGap: 4,
            marginBottom: 4,
            marginTop: 8,
            justifyContent: "center",
            alignItems: laptop || desktop ? "" : "center",
          }}
        >
          <img
            src={Person2}
            alt="CoverImage"
            style={{
              height: "275px",
              width: "275px",
            }}
          />
          <TextBox
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 4,
              marginTop: laptop || tabelt ? 0 : 5,
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: tabelt || laptop || desktop ? "" : "center",
                justifyContent: "center",
              }}
            >
              <Title>D. Victor Isaac</Title>
              <SubTitle>
                B.E (Electricals) M.Sc Engineering ( Power Systems)
              </SubTitle>
            </Box>
            <TextBox sx={{ marginTop: 3 }}>
              <Text>Vision for the Future:</Text>
              <Text>
                Even in retirement, Victor remains engaged in the field,
                contributing his expertise to the next generation of
                professionals. His vision for the future revolves around
                sustainable practices, technological advancements, and the
                continued growth of the industries he has been a part of.
              </Text>
            </TextBox>
          </TextBox>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 3 }}>
          <TextBox>
            <Text>Education:</Text>
            <Text>
              D. Victor Isaac is an accomplished professional with a solid
              educational foundation. Holding a Bachelor's degree in Electrical
              Engineering (B.E) and a Master's degree in Engineering with a
              specialization in Power Systems (M.Sc Engineering), his academic
              qualifications reflect his dedication to excellence in the field.
            </Text>
          </TextBox>
          <TextBox>
            <Text>Professional Journey:</Text>
            <Text>
              With a career spanning over four decades, Victor Isaac has emerged
              as a stalwart in the realm of Electrical Engineering and Plant
              Management.
            </Text>
            <Text>
              Dy. Manager Engineering - Aavin, Madavaram Dairy (7 Years): In the
              initial phase of his career, Victor served as the Deputy Manager
              in Engineering at Aavin, Madavaram Dairy, where he contributed
              significantly to the development and maintenance of electrical
              systems. His tenure at Aavin laid the foundation for his expertise
              in the intricate workings of dairy production and management
            </Text>
          </TextBox>
          <TextBox>
            <Text>
              DGM - Plant Engineering, L & T Group of Companies (25 Years -
              Retired):
            </Text>
            <Text>
              Victor's professional journey reached new heights during his
              impressive 25-year tenure at the L&T Group of Companies. As Deputy
              General Manager (DGM) in Plant Engineering, he played a pivotal
              role in overseeing and managing complex electrical systems within
              the L&T industrial framework. His leadership, strategic acumen,
              and technical prowess became instrumental in the efficient
              functioning of various plants under the L&T umbrella.
            </Text>
          </TextBox>
          <TextBox>
            <Text>
              Consultant for Projects in Food Processing Industry, Chennai (10
              Years):
            </Text>
            <Text>
              Post-retirement, Victor Isaac continued to share his wealth of
              knowledge and experience as a consultant. Specializing in projects
              within the Food Processing Industry in Chennai, he provided
              valuable insights and guidance for a decade. His consultancy
              services bridged the gap between industry practices and innovative
              solutions, contributing to the growth and efficiency of the food
              processing sector in the region.
            </Text>
          </TextBox>
          <Box
            sx={{
              marginTop: 3,
              marginBottom: 4,
              fontSize: "14px",
              columnGap: 4,
              display: "flex",
              flexDirection: "column",
              rowGap: 1,
            }}
          >
            <Text>Legacy and Impact:</Text>
            <Text>
              Victor Isaac's legacy is characterized by his unwavering
              dedication to the field of Electrical Engineering and Plant
              Management. His innovative contributions to the L&T Group and
              subsequent consultancy services have left an indelible mark on the
              industry.
            </Text>
            <Text>
              In conclusion, D. Victor Isaac's illustrious career reflects not
              only a deep understanding of electrical engineering and plant
              management but also a passion for fostering growth and excellence
              within the industries he has serve
            </Text>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: laptop || tabelt ? "row" : "column",
            columnGap: 4,
            marginBottom: 4,
            justifyContent: "center",
            alignItems: laptop || desktop ? "" : "center",
          }}
        >
          <img
            src={Person3}
            alt="CoverImage"
            style={{
              height: "275px",
              width: "275px",
            }}
          />
          <TextBox style={{ marginTop: laptop || tabelt ? 0 : 5 }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: tabelt || laptop || desktop ? "" : "center",
                justifyContent: "center",
              }}
            >
              <Title>Commodore Viswanath Venugopalan (Retd.),</Title>
            </Box>
            <TextBox sx={{ marginTop: 3 }}>
              <Text>Vision for the Future:</Text>
              <Text>
                Commodore Viswanath Venugopalan, a distinguished retired officer
                with over 34 years of exemplary service in both the Private and
                Government sectors, brings a wealth of expertise and leadership
                to the table.
              </Text>
            </TextBox>
          </TextBox>
        </Box>
        <Box>
          <TextBox
            sx={{
              marginTop: 3,
            }}
          >
            <Text>Academic Qualifications:</Text>
            <Text>
              Bachelor of Engineering (BE) in Electronics from Bangalore
              University. Masters in Financial Management from JBIMS, Mumbai.
              MPhil in Defence & Strategic Studies from the University of
              Mumbai.
            </Text>
          </TextBox>
          <TextBox
            sx={{
              marginTop: 4,
              marginBottom: 4,
            }}
          >
            <Text>Experience:</Text>
            <Text>
              Commodore Venugopalan's career is marked by a unique blend of
              techno-managerial excellence in both the Private and Government
              sectors. His extensive experience spans over 31 years in the
              Indian Navy, where he demonstrated outstanding proficiency in Ship
              Construction, Electrical, Electronics, and Weapon Systems.
            </Text>
            <Text>
              During his tenure in the Indian Navy, Commodore Venugopalan played
              a pivotal role in various capacities, contributing significantly
              to the fields of ship construction, electrical engineering, and
              advanced weapon systems. His leadership and technical acumen have
              left an indelible mark on the maritime domain.
            </Text>
            <Text>
              In addition to his illustrious military career, Commodore
              Venugopalan has also dedicated himself to fostering innovation and
              growth in the Defence Electronics sector. As a mentor for
              startups, he has been instrumental in guiding and nurturing
              emerging talents, thereby contributing to the development of
              cutting-edge technologies within the defense industry.
            </Text>
            <Text>
              Commodore Viswanath Venugopalan's commitment to excellence,
              strategic thinking, and mentorship has not only shaped his own
              successful career but has also left a lasting impact on the
              broader landscape of Defence and Strategic Studies. His
              multifaceted journey reflects a deep passion for both
              technological advancement and managerial leadership.
            </Text>
            <Text>
              In his well-deserved retirement, Commodore Venugopalan continues
              to be a source of inspiration for aspiring professionals and
              remains actively engaged in promoting innovation and excellence in
              Defence Electronics.
            </Text>
          </TextBox>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: laptop || tabelt ? "row" : "column",
            columnGap: 4,
            marginBottom: 4,
            justifyContent: "center",
            alignItems: laptop || desktop ? "" : "center",
          }}
        >
          <img
            src={Person4}
            alt="CoverImage"
            style={{
              height: "275px",
              width: "275px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 4,
              marginTop: laptop || tabelt ? 0 : 5,
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: tabelt || laptop || desktop ? "" : "center",
                justifyContent: "center",
              }}
            >
              <Title>R. Christopher Danielraj, M.E.</Title>
              <SubTitle>
                Assistant Professor (Selection Grade) & Deputy Head – Projects
                Division (Civil), Hindustan Groups of Institution, Chennai
              </SubTitle>
            </Box>
            <TextBox>
              <Text>Vision for the Future:</Text>
              <Text>
                R. Christopher Danielraj is a seasoned professional with a
                wealth of experience in Civil Engineering, both in academia and
                the industry. His journey is a testament to his dedication,
                expertise, and passion for contributing to the field of
                construction and project management
              </Text>
            </TextBox>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            columnGap: 4,
            marginBottom: 10,
          }}
        >
          <TextBox
            sx={{
              marginTop: 3,
            }}
          >
            <Text>Academic Background:</Text>
            <Text>
              R. Christopher Danielraj embarked on his academic journey by
              completing his Diploma in Civil Engineering in 1978. Building on
              this foundation, he pursued his undergraduate degree in Civil
              Engineering and later obtained a master's degree in Construction
              Engineering and Management from the prestigious College of
              Engineering Guindy.
            </Text>
          </TextBox>
          <TextBox
            sx={{
              marginTop: 3,
            }}
          >
            <Text>Professional Experience:</Text>
            <Text>
              With a solid educational background, Christopher Danielraj began
              his professional career as an Assistant Engineer in the Central
              Public Works Department, where he dedicated an impressive 36 years
              of service. His commitment and contributions were recognized, and
              he voluntarily opted for retirement in 2015.
            </Text>
            <Text>
              Post-retirement, he transitioned to the academic realm, joining
              the Civil Engineering Department of Hindustan Institute of
              Technology and Science as a Selection Grade Assistant Professor.
              In this role, he has been imparting knowledge to both graduate and
              postgraduate students in Civil Engineering. His teaching extends
              beyond the classroom, as he actively guides numerous students in
              their project works.
            </Text>
            <Text>
              In addition to his academic responsibilities, Christopher
              Danielraj serves as the Deputy Head – Projects (Civil) for the
              Hindustan Groups of Institution. In this capacity, he oversees
              construction projects across multiple locations, including
              Chennai, Mysore, Kerala, and Pondicherry. His leadership has been
              instrumental in ensuring the successful execution of various
              construction initiatives undertaken by the institution.
            </Text>
            <Text>
              After seven years of dedicated service at Hindustan Groups,
              Christopher Danielraj took on a new challenge by joining the Aachi
              Masala Group of Companies as the General Manager (Civil). In this
              role, he brings his extensive experience to contribute to the
              success and development of the company's civil engineering
              projects.
            </Text>
            <Text>
              Christopher Danielraj's professional journey reflects a remarkable
              blend of academic excellence and practical industry experience.
              His commitment to education and project management has left a
              lasting impact on both students and the construction projects
              under his supervision.
            </Text>
          </TextBox>
        </Box>
      </Box>
    </Container>
  );
}
