import { useCallback, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import Header from "../../components/core/header";
import { theme } from "../../utils/theme";
import {
  getEmployeeList,
  getFeedbackData,
  getWorkEmployeeList,
  setBookingDetailsTimeline,
  setBookingStoreDetails,
} from "../../store";
import { useDispatch } from "react-redux";
import VerticalStepper from "../../components/shared/stepper";
import ProjectList from "../projects/project-list";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  acceptRequest,
  getBooking,
  getBookingDetailsTimeline,
  getEmployee,
  acceptQuotation,
  getFeedback,
  rejectProjectWithReasons,
  postPayment,
} from "../../action/timeline";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ToastContainer } from "react-toastify";
import {
  errorNotification,
  successNotification,
} from "../../components/shared/Toast/toast";
import {
  BookingDetailsResponse,
  EmployeeResponse,
  quotation,
  timeSlot,
} from "../../types";
import ScheduleInspection from "./ScheduleInspectionModal";
import AssignEmployeeModal from "./assignEmployeeModal";
import AssignQuotationModal from "./AssignQuotationModal";
import ViewQuotationModal from "./ViewQuotationModal";
import EditQuotationModal from "./EditQuotation";
import { Steps } from "./Steps";
import FeedbackModal from "./FeedbackModal";
import CompleteWorkModal from "./CompleteWorkModal";
import ReadMore from "../../components/shared/readmore-readless";
import StarIcon from "@mui/icons-material/Star";
import { useMediaQuery } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

export const employeeInitialValue = {
  email: "",
  first_name: "",
  last_name: "",
  emp_code: "",
  dob: "",
  phone_number: "",
  status: "",
  designation: "",
  gender: "",
  addresses: [],
  id: "",
  profile_picture_url: "",
};

export const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: '95vw',
  width: '500px',
  minWidth: '320px',
  bgcolor: "background.paper",
  border: "2px solid #C1D0C4",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [stepperProfile, setStepperProfile] = useState(false);
  
  
  if (location.pathname !== '/customer-dashboard') navigate('/customer-dashboard');
  console.log(location);
  if (location.search === '?reset') {
    stepperProfile && setStepperProfile(false);
    sessionStorage.removeItem("selectedService");
    navigate('/customer-dashboard');
  }
  
                    
  let serviceFromSession: any = sessionStorage.getItem("selectedService");
  serviceFromSession = serviceFromSession ? JSON.parse(serviceFromSession) : "";
  const [selectedService, setSelectedService] = useState<string>(
    serviceFromSession ? serviceFromSession.selectedService : ""
  );
  
  const [projectId, setProjectId] = useState<string>(
    serviceFromSession ? serviceFromSession.projectId : ""
  );
  const [bookingStatus, setbookingStatus] = useState<string>(
    serviceFromSession ? serviceFromSession.status : ""
  );
  const [quotation, setQuotation] = useState<boolean>(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [slot, setSlot] = useState(false);
  const [assignEmployeeModal, setAssignEmployeeModal] = useState(false);
  const [assignEmployee, setAssignEmployee] = useState<EmployeeResponse | null>(
    employeeInitialValue
  );
  const [assignSlot, setAssignSlot] = useState<timeSlot>();
  const [workSlot, setWorkSlot] = useState(false);
  const [assignWorkSlot, setAssignWorkSlot] = useState<timeSlot>();
  const [assignWorkEmployee, setAssignWorkEmployee] = useState(false);
  const [editQuotation, setEditQuotation] = useState(false);
  const [viewQuotation, setViewQuotation] = useState(false);
  const [projectList, setProjectList] = useState<any | null>(null);
  const [addNewProject, setAddNewProject] = useState<boolean>(false);
  const [savedQuotation, setSavedQuotation] = useState<quotation>({
    item: [],
    unit_price: [],
    quantity: [],
    total: [],
  });
  const [insecptionDetails, setInsecptionDetails] = useState("");
  const [bgColor, setBgColor] = useState<[{ id: number; color: string }]>([
    {
      id: 0,
      color: "",
    },
  ]);
  const [bgWorkColor, setBgWorkColor] = useState<
    [{ id: number; color: string }]
  >([
    {
      id: 0,
      color: "",
    },
  ]);
  const [feedback, setFeedBack] = useState(false);
  const [rejectWithReason, setRejectWithReason] = useState(false);
  const [feedbackValue, setFeedbackValue] = useState<number>(0);
  const [selectedFile, setSelectedFile] = useState<string[]>([]);
  const [selectedWorkFile, setSelectedWorkFile] = useState<string[]>([]);
  const [selectedFileDisplay, setSelectedFileDisplay] = useState<any[]>([]);
  const [selectedWorkFileDisplay, setSelectedWorkFileDisplay] = useState<any[]>(
    []
  );
  const [isImageUpdated, setIsImageUpdated] = useState<boolean>(false);
  const fileInputRef = useRef(null);
  const [isImageSliderModalOpen, setIsImageSliderModalOpen] =
    useState<boolean>(false);
  const [imagePreviewFlag, setImagePreviewFlag] = useState<string>(""); // edit or view
  const [startIndex, setStartIndex] = useState(0);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [finalSearchValue, setFinalSearchValue] = useState("");
  const dispatch = useDispatch();
  const bookingDetails = useSelector(
    (state: RootState) => state.bookingDetailTimeline
  );
  const feedbackDetails = useSelector((state: RootState) => state.feedback);
  const [imgInspectionSlider, setImgInspectionSlider] =
    useState<boolean>(false);
  const [imgCompleteSlider, setImgCompleteSlider] = useState<boolean>(false);

  useEffect(() => {
    if (feedbackDetails?.Success) {
      setFeedbackValue(feedbackDetails?.Feedback.rating);
    } else setFeedbackValue(0);
  }, [feedbackDetails]);

  const role = sessionStorage.getItem("role");
  const medium = useMediaQuery(theme.breakpoints.down("lg"));

  const timeSlotSlicedData =
    bookingDetails &&
    bookingDetails.slots_provided.hasOwnProperty("slots_detail") &&
    bookingDetails.slots_provided.slots_detail;

  const quotationDetailsData =
    bookingDetails &&
    bookingDetails?.inspection_complete?.hasOwnProperty("quotation_details") &&
    bookingDetails?.inspection_complete?.quotation_details;

  const scheduledSelectedSlots =
    bookingDetails &&
    Object.keys(bookingDetails?.inspection_scheduled)?.length > 0 &&
    bookingDetails?.inspection_scheduled.selected_slot_detail.map(
      (item) => item.id
    );

  const selectedSlotFromSlotsProvided =
    bookingDetails &&
    Object.keys(bookingDetails?.slots_provided)?.length > 0 &&
    bookingDetails.slots_provided.slots_detail
      .map((item) => {
        if (item.selected === true) return item.id;
        return null;
      })
      .filter((id) => id !== null);

  const scheduledSelectedWorkSlots =
    bookingDetails &&
    Object.keys(bookingDetails?.in_progress)?.length > 0 &&
    bookingDetails.in_progress.hasOwnProperty("selected_slot") &&
    bookingDetails?.in_progress.selected_slot.map((item) => item.id);

  const selectedSlotFromWorkSlotsProvided =
    bookingDetails &&
    Object.keys(bookingDetails?.scheduled)?.length > 0 &&
    bookingDetails.scheduled.hasOwnProperty("slots") &&
    bookingDetails.scheduled.slots
      .map((item) => {
        if (item.selected === true) return item.id;
        return null;
      })
      .filter((id) => id !== null);

  const getInitials = (fullName: string): string => {
    const words = fullName.split(" ");

    if (words.length === 1) {
      return words[0].slice(0, 2);
    }

    return words
      .map((word) => word.charAt(0))
      .slice(0, 2)
      .join("");
  };

  const deleteImage = async () => {
    const selectedImage = selectedFileDisplay[startIndex];
    const selectedWorkImage = selectedWorkFileDisplay[startIndex];
    if (!completeModal) {
      const newArray =
        selectedFileDisplay.length > 0
          ? selectedFileDisplay.filter((value) => value !== selectedImage)
          : [];
      setSelectedFileDisplay(newArray);
      const selectedImageFile = selectedFile[startIndex];
      const newArrayUpload = selectedFile.filter(
        (value) => value !== selectedImageFile
      );
      setSelectedFile(newArrayUpload);
      setStartIndex(0);
    } else {
      const newArray =
        selectedWorkFileDisplay.length > 0
          ? selectedWorkFileDisplay.filter(
              (value) => value !== selectedWorkImage
            )
          : [];
      setSelectedWorkFileDisplay(newArray);
      const selectedImageFile = selectedFile[startIndex];
      const newArrayUpload = selectedFile.filter(
        (value) => value !== selectedImageFile
      );
      setSelectedFile(newArrayUpload);
      setStartIndex(0);
    }
    if (
      selectedFileDisplay.length === 0 ||
      selectedWorkFileDisplay.length === 0
    ) {
      setIsImageSliderModalOpen(false);
    }
  };

  useEffect(() => {
    if (bookingDetails && bookingDetails.complete.complete) {
      feedBackDataApi(selectedService);
    }
  }, [bookingDetails, dispatch, selectedService, role]);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setIsImageUpdated(true);

    if (file) {
      if (completeModal) {
        setSelectedWorkFile([file, ...selectedWorkFile]);
      } else if (completeModal === false) {
        setSelectedFile([file, ...selectedFile]);
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrl = reader.result;

        if (completeModal) {
          setSelectedWorkFileDisplay([dataUrl, ...selectedWorkFileDisplay]);
        } else if (completeModal === false) {
          setSelectedFileDisplay([dataUrl, ...selectedFileDisplay]);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (bookingDetails || stepperProfile) {
      if (
        bookingDetails &&
        bookingDetails?.inspection_complete &&
        bookingDetails?.inspection_complete?.inspection_images &&
        bookingDetails?.inspection_complete?.inspection_images?.length > 0
      ) {
        const imageList =
          bookingDetails?.inspection_complete?.inspection_images.map(
            (image: any) => {
              return image.image_url;
            }
          );

        setSelectedFileDisplay(imageList);
      } else {
        setSelectedFileDisplay([]);
      }
    }
  }, [bookingDetails, projectId, stepperProfile]);

  useEffect(() => {
    if (
      bookingDetails &&
      bookingDetails?.complete.complete &&
      bookingDetails?.complete?.job_progress_images.length > 0
    ) {
      const imageList = bookingDetails?.complete?.job_progress_images.map(
        (image: any) => {
          return image.image_url;
        }
      );

      setSelectedWorkFileDisplay(imageList);
    } else setSelectedWorkFileDisplay([]);
  }, [bookingDetails, selectedService]);

  const timeLineData = useCallback(
    (id: string) => {
      getBookingDetailsTimeline(id ? id : selectedService)
        .then((res) => {
          dispatch(setBookingDetailsTimeline(res.data));
        })
        .catch(() => {});
    },
    [dispatch, selectedService]
  );

  const feedbackButton = () => {
    return (
      <Button
        variant={feedbackDetails ? "text" : "outlined"}
        onClick={() => {
          setFeedBack(true);
        }}
        sx={{
          cursor: "pointer",
        }}
      >
        {`${feedbackDetails ? "View Feedback Details" : "Feedback"}`}
      </Button>
    );
  };

  const paymentDoneButton = () => {
    if (
      bookingDetails &&
      bookingDetails?.complete?.complete &&
      bookingDetails?.payment_status
    ) {
      return (
        <Typography
          variant="caption"
          sx={{
            fontSize: "16px",
            color: "#568A60",
            fontWeight: 600,
          }}
        >
          Payment Done
        </Typography>
      );
    } else
      return (
        <Button
          variant={bookingDetails?.payment_status ? "text" : "outlined"}
          sx={{
            cursor: "pointer",
            mt: 2,
          }}
          onClick={() =>
            postPayment(selectedService)
              .then(() => {
                successNotification("Payment marked as done");
                timeLineData(selectedService);
              })
              .catch((err) => {
                errorNotification("Error in making payment.Please try again");
              })
          }
        >
          Mark Payment Done
        </Button>
      );
  };

  useEffect(() => {
    if (
      role === "vendor" &&
      bookingDetails &&
      bookingDetails?.request_acceptance?.label
    ) {
      getEmployee()
        .then((res) => {
          dispatch(
            getEmployeeList(
              res.data.results.filter(
                (item: EmployeeResponse) =>
                  item.designation === "ESTIMATOR" ||
                  item.designation === "BOTH"
              )
            )
          );
          dispatch(
            getWorkEmployeeList(
              res.data.results.filter(
                (item: EmployeeResponse) =>
                  item.designation === "HANDYMAN" || item.designation === "BOTH"
              )
            )
          );
        })
        .catch(() => {});
    }
  }, [role, dispatch, selectedService, assignEmployeeModal, bookingDetails]);

  useEffect(() => {
    if (stepperProfile) {
      timeLineData(selectedService);
    }
  }, [stepperProfile, selectedService, timeLineData]);

  useEffect(() => {
    getBooking()
      .then((res) => {
        dispatch(
          setBookingStoreDetails(res.data.results as BookingDetailsResponse)
        );
      })
      .catch((err) => {});
  }, [dispatch]);

  useEffect(() => {
    const calculateBgColor = (item: any) => {
      let bg = "white";
      if (
        (selectedSlotFromSlotsProvided &&
          selectedSlotFromSlotsProvided.includes(item)) ||
        (scheduledSelectedSlots && scheduledSelectedSlots.includes(item)) ||
        (assignSlot && assignSlot?.id === item)
      ) {
        bg = "#EEFFF1";
      }
      return bg;
    };

    if (timeSlotSlicedData) {
      let updatedBgColor = { id: 0, color: "" };
      timeSlotSlicedData.forEach((item) => {
        const itemBgColor = calculateBgColor(item.id);
        if (itemBgColor === "#EEFFF1") {
          updatedBgColor = { id: item.id, color: "#EEFFF1" };
          return;
        }
      });
      setBgColor([updatedBgColor]);
    }
  }, [bookingDetails]);

  useEffect(() => {
    const calculateBgColor = (item: any) => {
      let bg = "white";
      if (
        (selectedSlotFromWorkSlotsProvided &&
          selectedSlotFromWorkSlotsProvided.includes(item)) ||
        (scheduledSelectedWorkSlots &&
          scheduledSelectedWorkSlots.includes(item)) ||
        (assignWorkSlot && assignWorkSlot?.id === item)
      ) {
        bg = "#EEFFF1";
      }
      return bg;
    };

    if (
      bookingDetails &&
      Object.keys(bookingDetails.scheduled)?.length > 0 &&
      bookingDetails.scheduled.slots?.length > 0
    ) {
      let updatedBgColor = { id: 0, color: "" };
      bookingDetails.scheduled.slots.forEach((item) => {
        const itemBgColor = calculateBgColor(item.id);
        if (itemBgColor === "#EEFFF1") {
          updatedBgColor = { id: item.id, color: "#EEFFF1" };
          return;
        }
      });
      setBgWorkColor([updatedBgColor]);
    }
  }, [bookingDetails, assignWorkSlot]);

  const employeeSlicedData =
    bookingDetails &&
    Object.keys(bookingDetails?.slots_provided).length > 0 &&
    bookingDetails?.slots_provided.hasOwnProperty("assigned_employees") &&
    bookingDetails?.slots_provided.assigned_employees?.length > 0
      ? bookingDetails?.slots_provided.assigned_employees.map(
          (item) => item.employee_details
        )
      : [];

  const employeeWorkSlicedData =
    bookingDetails?.scheduled.hasOwnProperty("assigned_employee") &&
    bookingDetails?.scheduled.assigned_employee?.length > 0
      ? bookingDetails?.scheduled.assigned_employee.map(
          (item) => item.employee_details
        )
      : [];
  const completeButton = () => {
    if (
      bookingDetails &&
      bookingDetails?.request_acceptance.label &&
      !bookingDetails?.complete.complete &&
      role === "vendor"
    )
      return (
        <Button
          variant="outlined"
          onClick={() => {
            setCompleteModal(true);
          }}
          sx={{
            cursor: "pointer",
            mt: 2,
            marginLeft: "auto",
            width: "200px",
          }}
        >
          Mark as Complete
        </Button>
      );
  };

  const feedBackDataApi = (selectedService: string) => {
    getFeedback(selectedService)
      .then((res) => {
        dispatch(getFeedbackData(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (selectedService) {
      setStepperProfile(true);
    }
  }, [selectedService]);

  const PaymentDoneCard = ({bookingDetails}: any) => {

    return (
      <Card>
        <CardContent sx={{ padding: 0 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              textAlign: "center",
              rowGap: 1,
              padding: 2,
            }}
          >
            {role === "vendor" && paymentDoneButton()}
            {role === "user" &&
              bookingDetails?.payment_status &&
              paymentDoneButton()}
            {role === "user" &&
              bookingDetails.complete.complete &&
              feedbackButton()}
            <Box
              sx={{
                display: "flex",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              {feedbackDetails?.Success &&
                Array.from({ length: feedbackValue }).map(
                  (_, index) => (
                    <StarIcon
                      key={index}
                      style={{
                        fontSize: "25px",
                        color: "orange",
                        display: "flex",
                      }}
                    />
                  )
                )}
            </Box>

            {role === "vendor" &&
              feedbackDetails &&
              Object.keys(feedbackDetails).length > 0 &&
              feedbackDetails.Success &&
              feedbackButton()}
          </Box>
        </CardContent>
      </Card>
    )
  }

  const dashboardStepper = useCallback(() => {
    if (stepperProfile && bookingDetails && selectedService) {
      return (
        <Box
          sx={{
            px: 0,
            m: 1,
            display: "flex",
            rowGap: 4,
            flexDirection: "column",
          }}
        >
          <Header title={"Emp"} subtitle={"subtitle"}>
            <Box sx={{ display: "flex", rowGap: 1, flexDirection: "column" }}>
              <Box sx={{ display: "flex", columnGap: 1 }}>
                <ArrowBackIcon
                  fontSize="large"
                  sx={{ mt: 1, cursor: "pointer" }}
                  onClick={() => {
                    setStepperProfile(false);
                    sessionStorage.removeItem("selectedService");
                  }}
                />
                <Typography
                  component="div"
                  sx={{ fontSize: "32px", color: "#000000", fontWeight: 700 }}
                >
                  Project #{projectId}
                </Typography>
              </Box>

              <Box
                sx={{
                  alignItems: "center",
                  border: "1 solid #C1D0C4",
                  borderRadius: 6,
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  backgroundColor: "#A8DBDE",
                  height: 36,
                  width: 300,
                }}
              >
                {bookingStatus}
              </Box>
            </Box>
          </Header>
          <div style={{ width: "100%" }}>
            <Box
              sx={{
                display: "grid",
                gridAutoColumns: "1fr",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  gridRow: "1",
                  gridColumn: "span 4",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 2,
                }}
              >
                {medium && bookingDetails &&
                  bookingDetails.complete.complete === true && (
                    <PaymentDoneCard bookingDetails={bookingDetails}/>
                )}
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Card sx={{ minWidth: 275, borderRadius: 2, width: "100%" }}>
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          columnGap: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 16,
                          }}
                          color="text.secondary"
                          gutterBottom
                        >
                          {`Initial Enquiry`}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: 1,
                          }}
                        >
                          {bookingDetails && (
                            <Typography
                              sx={{ fontSize: 14 }}
                            >{`Number of services requested : ${bookingDetails.customer_request.services?.length}`}</Typography>
                          )}
                          {bookingDetails &&
                            bookingDetails.customer_request.services.map(
                              (item: any, index: number) => {
                                return (
                                  <Box key={item.name}>
                                    <Typography
                                      sx={{ fontSize: 14 }}
                                      color="text.primary"
                                      gutterBottom
                                    >
                                      {`${index + 1}. ${item.name}`}
                                    </Typography>
                                  </Box>
                                );
                              }
                            )}
                          {bookingDetails && (
                            <Typography
                              sx={{ fontSize: "14px" }}
                              color="text.primary"
                              gutterBottom
                            >
                              <ReadMore>{` Note: ${bookingDetails.customer_request.notes}`}</ReadMore>
                            </Typography>
                          )}
                        </Box>

                        <Box sx={{ display: "flex" }}>
                          <LocationOnIcon />
                          <Typography
                            sx={{ fontSize: 12, paddingLeft: 1 }}
                            color="text.primary.main"
                            gutterBottom
                          >
                            {bookingDetails
                              ? `${bookingDetails.customer_request.address.city} ${bookingDetails.customer_request.address.state} ${bookingDetails.customer_request.address.country}-${bookingDetails.customer_request.address.pincode}`
                              : ""}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
                <Card sx={{ minWidth: 275, borderRadius: 2, height: "100%" }}>
                  <CardContent
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    {completeButton()}
                    <VerticalStepper
                      steps={Steps({
                        bookingDetails,
                        timeSlotSlicedData,
                        role,
                        setSlot,
                        bgColor,
                        setAssignSlot,
                        employeeSlicedData,
                        setAssignEmployeeModal,
                        insecptionDetails,
                        setInsecptionDetails,
                        selectedService,
                        timeLineData,
                        getInitials,
                        setQuotation,
                        setEditQuotation,
                        setViewQuotation,
                        quotationDetailsData,
                        employeeWorkSlicedData,
                        bgWorkColor,
                        setAssignWorkEmployee,
                        setAssignWorkSlot,
                        setWorkSlot,
                        selectedFileDisplay,
                        isImageUpdated,
                        fileInputRef,
                        handleFileChange,
                        rejectWithReason,
                        isImageSliderModalOpen,
                        setIsImageSliderModalOpen,
                        startIndex,
                        setStartIndex,
                        deleteImage,
                        imagePreviewFlag,
                        setImagePreviewFlag,
                        selectedFile,
                        setSelectedFileDisplay,
                        setSelectedFile,
                        setIsImageUpdated,
                        imgInspectionSlider,
                        setImgInspectionSlider,
                        imgCompleteSlider,
                        setImgCompleteSlider,
                      })}
                      acceptRequest={(accept) => {
                        if (accept) {
                          acceptRequest(selectedService)
                            .then(() => {
                              successNotification(
                                "Bookings successfully accepted"
                              );
                              timeLineData(selectedService);
                            })
                            .catch(() => {
                              "Error in accpeting the bookinh.Please try again";
                            });
                        }
                      }}
                      acceptQuotation={(accept) => {
                        if (accept) {
                          acceptQuotation(selectedService)
                            .then((res) => {
                              successNotification(`Quotation Accepted`);
                              timeLineData(selectedService);
                            })
                            .catch(() => {
                              "Error in accpeting the quotation.Please try again";
                            });
                        }
                      }}
                      viewQuotation={(view) => {
                        if (view) {
                          setViewQuotation(true);
                        }
                      }}
                      rejectRequest={(reject) => {
                        if (reject) {
                          setRejectWithReason(true);
                          let body = { details: insecptionDetails };
                          if (
                            insecptionDetails &&
                            insecptionDetails?.length > 0
                          ) {
                            rejectProjectWithReasons(selectedService, body)
                              .then((res) => {
                                successNotification(
                                  "Successfully rejected the project"
                                );
                                timeLineData(selectedService);
                                setInsecptionDetails("");
                                setRejectWithReason(false);
                              })
                              .catch(() => {
                                "Error in rejecting the request.Please try again";
                                setInsecptionDetails("");
                              });
                          }
                        }
                      }}
                    />
                  </CardContent>
                </Card>
              </Box>

              <Box
                sx={{
                  gridRow: "1",
                  gridColumn: "span 1",
                  height: "100%",
                  flexDirection: "column",
                  rowGap: 2,
                  display : {
                    all: 'none',
                    xs: 'none',
                    sm: 'none',
                    md: 'none',
                    lg: 'none',
                    xl: 'flex',
                  },
                }}
              >
                {bookingDetails &&
                  bookingDetails.complete.complete === true && (
                    <PaymentDoneCard bookingDetails={bookingDetails}/>
                  )}
                <Card style={{  }}>
                  <CardContent>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 400,
                        textAlign: "center",
                      }}
                    >
                      Employees Assigned
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 16, fontWeight: 400, mb: 20 }}
                      >
                        {employeeSlicedData?.length > 0 ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: 5,
                            }}
                          >
                            {employeeSlicedData.map((item: any) => {
                              return (
                                <Box
                                  sx={{
                                    display: "flex",
                                    columnGap: "30px",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Box
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      rowGap: "10px",
                                      alignItems: "center",
                                      marginTop: "30px",
                                    }}
                                  >
                                    {item?.profile_picture_url &&
                                    item.profile_picture_url !== "" ? (
                                      <Avatar
                                        sx={{
                                          width: 50,
                                          height: 50,
                                          marginBottom: 1,
                                        }}
                                        src={item.profile_picture_url}
                                      ></Avatar>
                                    ) : (
                                      <Avatar
                                        sx={{
                                          bgcolor: "orange",
                                          width: 50,
                                          height: 50,
                                          marginBottom: 1,
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {getInitials(
                                          `${item.first_name} ${item.last_name}`
                                        )}
                                      </Avatar>
                                    )}

                                    <Typography
                                      sx={{ fontSize: "12px", fontWeight: 400 }}
                                    >
                                      {item?.first_name}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "#A7A7A7",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {item?.emp_code} | {item?.designation}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "#A7A7A7",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {item?.phone_number}
                                    </Typography>
                                  </Box>
                                </Box>
                              );
                            })}
                            {Object.keys(bookingDetails.scheduled).length > 0 &&
                              bookingDetails.scheduled.schedule_confirmed &&
                              employeeWorkSlicedData &&
                              employeeWorkSlicedData.map((item) => {
                                return (
                                  <Box
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      rowGap: "10px",
                                      alignItems: "center",
                                      marginBottom:10
                                    }}
                                  >
                                    {item.profile_picture_url ?
                                      <Avatar
                                        sx={{
                                          width: 50,
                                          height: 50,
                                          marginBottom: 1,
                                        }}
                                        src={item.profile_picture_url}
                                      ></Avatar> :
                                      <Avatar>
                                      {getInitials(
                                        `${item.first_name} ${item.last_name}`
                                      )}
                                    </Avatar>}
                                    <Typography
                                      sx={{ fontSize: "12px", fontWeight: 400 }}
                                    >
                                      {item?.first_name}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "#A7A7A7",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {item?.emp_code} | {item?.designation}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "#A7A7A7",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {item?.phone_number}
                                    </Typography>
                                  </Box>
                                );
                              })}
                          </Box>
                        ) : (
                          <Typography
                            sx={{ fontSize: "12px", textAlign: "center" }}
                          >
                            No Employees Assigned
                          </Typography>
                        )}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </div>
        </Box>
      );
    }
  }, [
    stepperProfile,
    bookingDetails,
    selectedService,
    projectId,
    bookingStatus,
    bgColor,
    insecptionDetails,
    role,
    timeSlotSlicedData,
    quotationDetailsData,
    employeeSlicedData,
    employeeWorkSlicedData,
    bgWorkColor,
    workSlot,
  ]);

  return (
    <>
      <ToastContainer />
      {!stepperProfile && (
        <Box
          sx={{
            px: 0,
            pb: 2,
            display: "flex",
            // rowGap: 4,
            flexDirection: "column",
          }}
        >
          <Header title={"Emp"} subtitle={"subtitle"}>
            <Typography
              component="div"
              sx={{ fontSize: "32px", color: "#000000" }}
            >
              Projects
            </Typography>
            <Typography
              component="div"
              sx={{ fontSize: "16px", color: "#A7A7A7" }}
            >
              List of all the work requests
            </Typography>
          </Header>

          <CardContent sx={{p: 0}}>
            <ProjectList
              projectList={projectList}
              setProjectList={setProjectList}
              addNewProject={addNewProject}
              setAddNewProject={setAddNewProject}
              setSelectedService={(id: string) => setSelectedService(id)}
              setStepperProfile={setStepperProfile}
              setProjectId={setProjectId}
              setbookingStatus={setbookingStatus}
              isSearch={isSearch}
              setIsSearch={setIsSearch}
              searchText={searchText}
              setSearchText={setSearchText}
              finalSearchValue={finalSearchValue}
              setFinalSearchValue={setFinalSearchValue}
            />
          </CardContent>
        </Box>
      )}
      {dashboardStepper()}

      <AssignQuotationModal
        quotation={quotation}
        setQuotation={(view) => setQuotation(view)}
        style={style}
        savedQuotation={savedQuotation}
        setSavedQuotation={setSavedQuotation}
        selectedService={selectedService}
        timeLineData={timeLineData}
      />
      <EditQuotationModal
        quotation={editQuotation}
        setQuotation={(view) => setEditQuotation(view)}
        style={style}
        data={quotationDetailsData ? quotationDetailsData : []}
        selectedService={selectedService}
        timeLineData={timeLineData}
      />
      <ScheduleInspection
        slot={slot}
        setSlot={(value) => setSlot(value)}
        style={style}
        selectedService={selectedService}
        timeLineData={timeLineData}
        workSlot={workSlot}
        setWorkSlot={setWorkSlot}
      />
      <AssignEmployeeModal
        assignEmployeeModal={assignEmployeeModal}
        setAssignEmployeeModal={(value) => {
          setAssignEmployeeModal(value);
          setAssignWorkEmployee(value);
        }}
        style={style}
        assignEmployee={assignEmployee ?? assignEmployee}
        setAssignEmployee={(item) => setAssignEmployee(item)}
        selectedService={selectedService}
        timeLineData={timeLineData}
        workAssignEmployee={assignWorkEmployee}
        getInitials={getInitials}
      />

      <ViewQuotationModal
        viewQuotation={viewQuotation}
        setViewQuotation={setViewQuotation}
        style={style}
        data={quotationDetailsData ? quotationDetailsData : []}
      />

      <FeedbackModal
        feedback={feedback}
        setFeedBack={setFeedBack}
        style={style}
        selectedService={selectedService}
        feedbackDetails={feedbackDetails}
        role={role || ""}
        feedbackDataApi={feedBackDataApi}
      />

      <CompleteWorkModal
        completeWorkModal={completeModal}
        setCompeleteWorkModal={setCompleteModal}
        style={style}
        selectedService={selectedService}
        timeLineData={timeLineData}
        handleFileChange={handleFileChange}
        setIsImageSliderModalOpen={setIsImageSliderModalOpen}
        setImagePreviewFlag={setImagePreviewFlag}
        fileInputRef={fileInputRef}
        isImageUpdated={isImageUpdated}
        selectedWorkFileDisplay={selectedWorkFileDisplay}
        setSelectedWorkFileDisplay={setSelectedWorkFileDisplay}
        selectedFile={selectedWorkFile}
        setSelectedFile={setSelectedWorkFile}
        setIsImageUpdated={setIsImageUpdated}
        isImageSliderModalOpen={isImageSliderModalOpen}
        startIndex={startIndex}
        setStartIndex={setStartIndex}
        deleteImage={deleteImage}
        imagePreviewFlag={imagePreviewFlag}
      />
    </>
  );
};

export default Dashboard;
