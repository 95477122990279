import {
  Typography,
  Box,
  Paper,
  Button,
  TextField,
  CardContent,
} from "@mui/material";
import styled from "@emotion/styled";
import { device } from "../../utils/constant";

export const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  box-shadow: none;
  font-family: "Kufam", sans-serif;
  width: 100% !important;
  flex-wrap: wrap;
  z-index: 9999;
`;

export const StyledCard = styled(Box)`
  width: 300px;
  display: flex;
  padding: 0px 15px;
  align-item: flex-start;
  height: 150px;
`;

export const CardContentWrapper = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  align-items: flex-start;
`;

export const LogoContainer = styled(Box)`
  display: flex;
  justify-content: "center";
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  @media ${device.laptop} {
    padding: 10px 15px;
  }
  @media ${device.tablet} {
    padding: 10px 10px;
  }
  @media ${device.mobileL} {
    padding: 0px;
  }
`;

export const LoginButton = styled(Button)`
  margin-left: 20px;
  color: #ffffff;
  background: #71a187;
  font-family: "Kufam", sans-serif;
  font-weight: 600;
  border-radius: 30px;
  padding: 5px 5px;
  cursor: pointer;
  width:35%
  height: 10px;
  font-size: 12px;
  &:hover {
    background: #568a60; /* Change the background color on hover */
  }
  &:active {
    background: #718875; /* Change the background color on click */
  }
   @media ${device.mobileS} {
    padding: 10px 5px;
    height: 28px;
    font-size: 13px;
  }
  @media ${device.mobileL} {
    padding: 10px 5px;
    height: 30px;
    font-size: 13px;
  }
  @media ${device.tablet} {
    padding: 10px 10px;
    height: 35px;
    font-size: 14px;
  }
  @media ${device.laptop} {
    padding: 10px 15px;
    height: 40px;
    font-size: 16px;
    width:20%
  }
`;

export const LogoText = styled(Typography)`
  font-size: 14px;
  font-family: "Kufam", sans-serif;
  @media ${device.laptop} {
    font-size: 16px;
  }
`;

export const LocationContainer = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 30px;
  margin-top: 25px;
  float: right;
`;

export const HeaderText = styled(Typography)`
  padding: 0px 10px;
  color: #71a187;
  font-family: "Kufam", sans-serif;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  cursor: pointer;
  font-size: 14px;
  @media ${device.tablet} {
    font-size: 12px;
  }
  @media ${device.laptop} {
    font-size: 16px;
  }
`;

export const GradientRectangle = styled.div`
  width: 100%;
  height: 395px;
  position: relative;
  background: linear-gradient(
    93.8deg,
    rgba(138, 231, 153, 0) 1.4%,
    rgba(138, 231, 153, 0.15) 62.59%
  );
 
`;

export const ViewDetailsLink = styled(Button)`
  padding: 0;
  align-items: flex-start;
  text-transform: capitalize;
  font-family: "Kufam", sans-serif;
  color: "#71A187";
  fontweight: 400;
  font-size: 14px;
  @media ${device.tablet} {
    font-size: 14px;
  }
  @media ${device.laptop} {
    font-size: 16px;
  }
  @media ${device.desktop} {
    font-size: 18px;
  }
`;

export const FooterText = styled(Typography)`
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 2.5;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "Kufam", sans-serif;
`;

export const InputField = styled(TextField)`
  width: "100%",
  margin-bottom: 20px;
  border-color: none;
  padding:"0px 0px";
  & .MuiOutlinedInput-root {
    border: 1px solid #a4d2b9;
    border-radius: 30px;
    padding: 0
    font-size: 10px;
    height: 35px;
    @media ${device.tablet} {
        font-size: 12px; 
    }
    @media ${device.laptop} {
        font-size: 14px;  
        height: 40px;
    }   
    @media ${device.desktop} {
        font-size: 16px;
        height: 48px;
    }
}
    &:hover fieldset {
      border-color: none;
      
    }
    &.Mui-focused fieldset {
      border: 1px solid #a4d2b9;
      border-radius: 30px;  
    }
  }
`;

export const MascotSubTitle = styled(Typography)`
  font-size: 12px;
  font-family: "Kufam", sans-serif;
  margin-top: 10px;

  @media ${device.tablet} {
    font-size: 12px;
  }
  @media ${device.laptop} {
    font-size: 16px;
  }
  @media ${device.laptopL} {
    font-size: 18px;
  }
  @media ${device.mobileL} {
    text-align: center;
  }
  @media ${device.mobileM} {
    text-align: center;
  }
`;

export const MascotBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-left: 2;
  padding-right: 2;
  @media ${device.mobileS} {
    row-gap: 0px;
    margin-top: 2px;
  }
  @media ${device.tablet} {
    row-gap: 4px;
    margin-top: 10px;
  }
  @media ${device.laptop} {
    row-gap: 8px;
    margin-top: 15px;
  }
  @media ${device.laptopL} {
    row-gap: 16px;
    margin-top: 25px;
  }
`;

export const InspectionButton = styled(LoginButton)`
  width: 60%;
  height: 45px;
  font-size: 12px;
  line-height: 1.2;
  @media ${device.mobileS} {
    width: 150px;
    height: 33px;
    font-size: 12px;
  }
  @media ${device.mobileL} {
    width: 150px;
    height: 33px;
    font-size: 12px;
  }
  @media ${device.tablet} {
    width: 220px;
    height: 40px;
    font-size: 18px;
  }
  @media ${device.laptop} {
    width: 220px;
  }
  @media ${device.laptopL} {
    width: 280px;
  }
`;

export const CustomerStasificationTitle = styled(Typography)`
  font-weight: 700;
  font-family: "Kufam", sans-serif;
  font-size: 26px;
  color: #36423c;
  margin-top: 10px;
  @media ${device.laptop} {
    font-size: 28px;
  }
  @media ${device.mobileL} {
    font-size: 18px;
    text-align: center;
  }
  @media ${device.mobileM} {
    font-size: 18px;
    text-align: center;
  }
  @media ${device.mobileS} {
    font-size: 18px;
    text-align: center;
  }
`;

export const CustomerStasificationSubTitle = styled(Typography)`
  font-weight: 400;
  font-family: "Kufam", sans-serif;
  font-size: 14px;
  color: #36423c;
  margin-top: 10px;
  @media ${device.mobileL} {
    font-size: 14px;
    text-align: center;
  }
  @media ${device.mobileM} {
    font-size: 14px;
    text-align: center;
  }
  @media ${device.mobileS} {
    font-size: 14px;
    text-align: center;
  }
  @media ${device.tablet} {
    font-size: 16px;
  }
  @media ${device.laptop} {
    font-size: 16px;
  }
  @media ${device.laptopL} {
    font-size: 18px;
  }
`;

export const CustomerStasificationSubscriptTitle = styled(Typography)`
  font-weight: 400;
  font-family: "Kufam", sans-serif;
  font-size: 14px;
  color: #71a187;
  @media ${device.mobileL} {
    font-size: 14px;
    text-align: center;
  }
  @media ${device.mobileM} {
    font-size: 14px;
    text-align: center;
  }
  @media ${device.mobileS} {
    font-size: 14px;
    text-align: center;
  }
  @media ${device.laptop} {
    font-size: 16px;
  }
  @media ${device.laptopL} {
    font-size: 18px;
  }
`;

export const FooterTitle = styled(Typography)`
  color: #fff;
  font-size: 12px;
  line-height: 30px;
  font-weight: 700;
  display: flex;
  align-items: center;
  font-family: "Kufam", sans-serif;
`;