import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import EmployeesList from './employee-list';
import EmployeeCard from './employee-card';
import Header from '../../components/core/header';
import './index.scss';
import AddEmployee from './add-employee';
import EditEmployee from './edit-employee';
import { ToastContainer, toast } from 'react-toastify';
import { RootState, setActiveProjectTabIndex } from '../../store';
import { useSelector } from 'react-redux';

const backArraow = require('../../assets/icons/icon-arrow-back-circle-outline.svg').default;

const Employees = () => {
  const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT;
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | null>(null);
  const [employeeList, setEmployeeList] = useState<any | null>([]);
  const [invidualEmpDetails, setInvidualEmpDetails] = useState<any | null>(null);
  const [addNewEmployee, setAddNewEmployee] = useState<boolean>(false);
  const [editEmployee, setEditEmployee] = useState<boolean>(false);

  const [defaultPageSize, setDefaultPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  // const [activeEmployeeTabIndex, setActiveProjectTabIndex] = useState<number>(0);

  const activeEmployeeTabIndex = useSelector((state: RootState) => state.activeEmployeeTabIndex);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const [finalSearchValue, setFinalSearchValue] = useState('');

  useEffect(() => {
    getEmployeeList();
  }, [finalSearchValue, isSearch, currentPage, activeEmployeeTabIndex, defaultPageSize]);

  useEffect(() => {
    if (selectedEmployeeId) {
      const EmpDetails = employeeList.find((employee: any) => employee.emp_code === selectedEmployeeId);
      setInvidualEmpDetails(EmpDetails);
    }
  }, [selectedEmployeeId]);

  const getEmployeeList = async () => {
    const accessToken = sessionStorage.getItem('access_token');

    let flag;
    let url = `${apiEndpoint}/vendors/employees?page=${currentPage}&page_size=${defaultPageSize}`;

    if (activeEmployeeTabIndex === 1) {
      flag = 'estimator';
    }

    if (activeEmployeeTabIndex === 2) {
      flag = 'handyman';
    }

    if (activeEmployeeTabIndex === 3) {
      flag = 'both';
    }

    if (activeEmployeeTabIndex > 0) {
      url = `${apiEndpoint}/vendors/employees?page=${currentPage}&page_size=${defaultPageSize}&designation=${flag}`;
    }

    if (isSearch) {
      url = `${url}&search_term=${finalSearchValue}`;
    }
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();

    if (data) {
      setTotalCount(data.count);
      setEmployeeList(data.results);
    }
  };

  return (
    <Box sx={{ px: 3, mb: 8 }}>
      <ToastContainer />
      <Header>
        <Typography component="div" sx={{ fontSize: '32px', color: '#000000' }}>
          {addNewEmployee && (
            <React.Fragment>
              <img
                src={backArraow}
                onClick={() => {
                  setAddNewEmployee(false);
                }}
                style={{
                  cursor: 'pointer',
                  position: 'relative',
                  top: '9px',
                  right: '8px',
                }}
              />
              Add new employee
            </React.Fragment>
          )}

          {!addNewEmployee && !editEmployee && <React.Fragment>Employees</React.Fragment>}
          {editEmployee && (
            <React.Fragment>
              <img
                src={backArraow}
                onClick={() => {
                  setEditEmployee(false);
                }}
                style={{
                  cursor: 'pointer',
                  position: 'relative',
                  top: '9px',
                  right: '8px',
                }}
              />
              Employees / {`${invidualEmpDetails.first_name} ${invidualEmpDetails.last_name}`} / Edit
            </React.Fragment>
          )}
        </Typography>
        {!addNewEmployee && !editEmployee && (
          <Typography component="div" sx={{ fontSize: '16px', color: '#A7A7A7' }}>
            View the list of all the employees or create a new employee.
          </Typography>
        )}
      </Header>
      {addNewEmployee && (
        <AddEmployee
          setAddNewEmployee={setAddNewEmployee}
          setEmployeeList={setEmployeeList}
          employeeList={employeeList}
        />
      )}

      {editEmployee && (
        <EditEmployee
          setEmployeeList={setEmployeeList}
          employeeList={employeeList}
          invidualEmpDetails={invidualEmpDetails}
          setInvidualEmpDetails={setInvidualEmpDetails}
          setEditEmployee={setEditEmployee}
        />
      )}

      {!addNewEmployee && !editEmployee && (
        <Grid container>
          {invidualEmpDetails && selectedEmployeeId && (
            <Grid lg={3}>
              <EmployeeCard
                employee={invidualEmpDetails}
                setSelectedEmployeeId={setSelectedEmployeeId}
                setEditEmployee={setEditEmployee}
              />
            </Grid>
          )}
          <Grid lg={invidualEmpDetails && selectedEmployeeId ? 9 : 12}>
            {/* <Grid container sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
              {cardDetails.map((card) => (
                <DashboardCard {...card} />
              ))}
            </Grid> */}
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
              <EmployeesList
                selectedEmployeeId={selectedEmployeeId}
                setSelectedEmployeeId={setSelectedEmployeeId}
                data={employeeList}
                addNewEmployee={addNewEmployee}
                setAddNewEmployee={setAddNewEmployee}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalCount={totalCount}
                setTotalCount={setTotalCount}
                defaultPageSize={defaultPageSize}
                setDefaultPageSize={setDefaultPageSize}
                isSearch={isSearch}
                setIsSearch={setIsSearch}
                searchText={searchText}
                setSearchText={setSearchText}
                setEmployeeList={setEmployeeList}
                finalSearchValue={finalSearchValue}
                setFinalSearchValue={setFinalSearchValue}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Employees;
