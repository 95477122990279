import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#568A60",
      light: "#E8EFE8",
      dark: "#F3F3F3",
      contrastText: "#C1D0C4",
    },
    secondary: {
      main: "#E8EFE8",
      light: "#A7A7A7",
    },
  },
  typography: {
    fontFamily: `'Lexend', 'sans-serif'`,
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: "white",
          textTransform: "capitalize",
        },
      },
    },
  },
});
