import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Box,
  Paper,
  TextField,
  Button,
  InputLabel,
} from "@mui/material";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { theme } from "../../utils/theme";
import { setRole, setUserDetail } from "../../store";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  errorNotification,
  successNotification,
} from "../../components/shared/Toast/toast";

const Logo = require("../../assets/Logo-hello-fixy-green.svg").default;
const LoginBg = require("../../assets/login-bg.svg").default;
const LoginImage = require("../../assets/login-image.svg").default;

// Styled components
const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  box-shadow: none;
`;

const Header = styled(AppBar)`
  flex-shrink: 0;
  background-color: transparent;
  width: 100%;
  color: #36423c;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: none;
  z-index: 9999;
`;

const HeaderContent = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #a4d2b9;
  padding: 0px !important;
`;

const LogoContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 15px;
  cursor: pointer;
`;

const LocationContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

const LeftPane = styled(Box)`
  flex: 1;
  color: #36423c;
`;

const RightPane = styled(Box)`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FormContainer = styled(Box)`
  padding-right: 50px;
  padding-top: 50px;
`;

const InputField = styled(TextField)`
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
  & .MuiOutlinedInput-root {
    fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &:hover fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &.Mui-focused fieldset {
      border-color: ${theme.palette.primary.main};
    }

    &.Mui-error fieldset {
      border-color: #your-error-color; /* Error state */
    }
  }
`;

const LoginButton = styled(Button)`
  margin-top: 20px;
  height: 40px;
  color: #ffffff;
  background: #71a187;
  font-size: 18px;
  font-weight: 600;
  font-family: "Kufam", sans-serif;
  border-radius: 30px;
  padding: 20px;
  &:hover {
    background: #568a60;
  }

  &:active {
    background: #718875; /* Change the background color on click */
  }
`;

const ErrorLabel = styled(Typography)(() => ({
  color: "red",
  fontSize: "0.875rem",
  fontWeight: 500,
  marginBottom: "15px",
  textAlign: "left",
}));

const CustomerLogin: React.FC = () => {
  const laptop = useMediaQuery(theme.breakpoints.up("lg"));

  const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const isValidEmail = (email: string) => {
    // Regular expression for a valid email format
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    return emailRegex.test(email);
  };

  const handleValidation = () => {
    const newErrors = {
      email: "",
      password: "",
    };

    if (!values.email) {
      newErrors.email = "Email is required";
    } else if (!isValidEmail(values.email)) {
      newErrors.email = "Invalid email format";
    }

    if (!values.password.length) {
      newErrors.password = "Password is required";
    }

    setErrors(newErrors);

    return !newErrors.email && !newErrors.password;
  };

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrors({ ...errors, [prop]: "" });
  };

  async function fetchData(accessToken: string) {
    try {
      const response = await fetch(`${apiEndpoint}/users/profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        dispatch(setUserDetail(data));
        return;
      } else {
        console.error("Login failed");
        return;
      }
    } catch (error) {
      console.error("Error during login:", error);
      return;
    }
  }

  const handleLogin = async (e: any) => {
    if (handleValidation()) {
      e.preventDefault();
      try {
        const response = await fetch(`${apiEndpoint}/users/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: values.email,
            password: values.password,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          const accessToken = data.access;
          const refreshToken = data.refresh;
          successNotification("Login successfully");

          // Store the access token in session storage
          sessionStorage.setItem("access_token", accessToken);
          sessionStorage.setItem("isAuthenticated", "true");
          sessionStorage.setItem("refresh_token", refreshToken);
          sessionStorage.setItem("role", "vendor");
          await fetchData(accessToken);
          // dispatch(setAuthenticated(true));
          dispatch(setRole("vendor"));
          navigate("/projects");
        } else {
          toast.error(data.detail, {
            position: "top-center",
            autoClose: 5000,
          });
          console.error("Login failed");
        }
      } catch (error) {
        console.error("Error during login:", error);
        errorNotification("Error in login .Please try again");
      }
    }
  };

  return (
    <Container elevation={3}>
      <ToastContainer />
      <Header position="static">
        <HeaderContent>
          <LogoContainer
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              src={Logo}
              alt="Company Logo"
              style={{ marginRight: 8, height: 40 }}
            />
            <Typography
              variant="h2"
              sx={{
                fontSize: "20px",
              }}
            >
              HelloFixy
            </Typography>
          </LogoContainer>
          <LocationContainer>
            {/* <IconButton color="inherit" aria-label="Location">
              <LocationOnIcon />
            </IconButton>
            <LocationText variant="body2">Chennai</LocationText> */}
          </LocationContainer>
        </HeaderContent>
      </Header>
      <Grid container sx={{ height: "1000px" }}>
        {laptop && (
          <Grid md={7}>
            <LeftPane>
              <img
                src={LoginBg}
                alt="Company Logo"
                style={{ position: "absolute", top: 0, left: 0, width: "50%" }}
              />
              <img
                src={LoginImage}
                alt="Background"
                style={{
                  objectFit: "cover",
                  position: "relative",
                  zIndex: 999,
                  paddingTop: 100,
                  paddingLeft: 70,
                }}
              />
            </LeftPane>
          </Grid>
        )}
        <Grid item xs={12} md={laptop ? 5 : 12}>
          <RightPane>
            <FormContainer>
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                sx={{
                  textAlign: "left",
                  color: "#36423c",
                  fontSize: "36px",
                  font: "kefa",
                  marginBottom: "20px",
                }}
              >
                Sign In
              </Typography>

              <>
                <InputLabel htmlFor="laptop">
                  Email <span>*</span>
                </InputLabel>
                <InputField
                  value={values.email}
                  id="laptop"
                  fullWidth
                  margin="normal"
                  onChange={handleChange("email")}
                />
                <ErrorLabel>{errors.email}</ErrorLabel>
                <InputLabel htmlFor="password">
                  Password <span>*</span>
                </InputLabel>
                <InputField
                  id="password"
                  type="password"
                  value={values.password}
                  fullWidth
                  margin="normal"
                  onChange={handleChange("password")}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleLogin(event);
                    }
                  }}
                />
                <ErrorLabel>{errors.password}</ErrorLabel>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: laptop ? "" : "center",
                    alignItems: laptop ? "" : "center",
                  }}
                >
                  <LoginButton
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                  >
                    Sign In
                  </LoginButton>
                </Box>
              </>
            </FormContainer>
          </RightPane>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CustomerLogin;
