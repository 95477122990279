const Endpoint = {
  GET_BOOKING_TIMELINE: (id: string) => `/bookings/${id}/get_booking_timeline/`,
  GET_BOOKING: `/bookings?page=1&page_size=100`,
  ACCEPT_VENDOR: (id: string) => `/bookings/${id}/accept_booking/`,
  POST_TIMESLOT: (id: string) => `/bookings/${id}/create_inspection_slots/`,
  GET_EMPLOYEE: `/vendors/employees?page=1&page_size=100`,
  SAVE_EMPLOYEE: (id: string) => `bookings/${id}/assign_employee/`,
  SAVE_TIMESLOT: (id: string) => `bookings/${id}/save_inspection_slot/`,
  CONFIRM_TIME_EMPLOYEE: (id: string) =>
    `bookings/${id}/confirm_slot_and_employee/`,
  CREATE_INSPECTION_DETAILS: (id: string) =>
    `bookings/${id}/create_inspection_details/`,
  PREPARE_QUOTATION: (id: string) => `bookings/${id}/prepare_quotation/`,
  SEND_QUOTATION: (id: string) => `bookings/${id}/send_quotation/`,
  EDIT_QUOTATION: (id: string) => `bookings/${id}/edit_quotation/`,
  ACCEPT_QUOTATION: (id: string) => `/bookings/${id}/accept_quotation/`,
  POST_WORK_SLOT: (id: string) => `bookings/${id}/create_work_slots/`,
  SAVE_WORK_SLOT: (id: string) => `bookings/${id}/save_work_slot/`,
  CONFIRM_WORK_SLOT: (id: string) =>
    `bookings/${id}/confirm_work_slot_and_employee/`,
  CREATE_WORK_DETAILS: (id: string) =>
    `bookings/${id}/create_work_complete_details/`,
  START_WORK: (id: string) => `bookings/${id}/start_work/`,
  COMPLETE_WORK: (id: string) => `bookings/${id}/complete_work/`,
  CLOSE_PROJECT: (id: string) => `bookings/${id}/close/`,
  REJECT_PROJECT: (id: string) => `bookings/${id}/reject_booking/`,
  POST_FEEDBACK: (id: string) => `bookings/${id}/save_feedback/`,
  GET_FEEDBACK: (id: string) => `bookings/${id}/get_feedback/`,
  POST_PAYMENT: (id: string) => `bookings/${id}/mark_payment_done/`,
  DELETE_QUOTATION: (id: string, quotaion_id: number) =>
    `bookings/${id}/delete_quotation_item/?quotation_details_id=${quotaion_id}`,
  DELETE_EMPLOYEE: (id: string, employee_id: number) =>
    `bookings/${id}/delete_assigned_employee/?assigned_employee_id=${employee_id}`,
};

export default Endpoint;
