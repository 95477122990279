import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  Modal,
} from "@mui/material";
import styled from "@emotion/styled";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../components/core/header";
import { theme } from "../../utils/theme";
import { useSelector } from "react-redux";
import { RootState, setUserDetail ,setRole} from "../../store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import CustomModal from "./delete_profile";

const ProfilePic = require("../../assets/profile-pic.svg").default;

const backArraow =
  require("../../assets/icons/icon-arrow-back-circle-outline.svg").default;

const BoxCard = styled(Box)`
  padding: 3rem 10rem;
  @media (max-width: 1124px) {
    padding: 2rem;
  }
  @media (max-width: 425px) {
    padding: 1rem;
  }
`;

const BoxCardProfile = styled(Box)`
  padding-left: 10rem;
  padding-right: 18rem;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const LoginButton = styled(Button)`
  text-align: left;
  color: #ffffff;
  background: #71a187;
  font: Kufam;
  font-weight: 600;
  border-radius: 30px;
  padding: 10px 20px;
  &:hover {
    background: #539b7b; /* Change the background color on hover */
  }

  &:active {
    background: #3b7257; /* Change the background color on click */
  }
  @media (max-width: 425px) {
    padding: 4px 10px;
  }
`;

const InputField = styled(TextField)`
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
  & .MuiOutlinedInput-root {
    fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &:hover fieldset {
      border-color: ${theme.palette.primary.contrastText};
    }

    &.Mui-focused fieldset {
      border-color: ${theme.palette.primary.main};
    }

    &.Mui-error fieldset {
      border-color: #your-error-color; /* Error state */
    }
  }
`;

const ErrorLabel = styled(Typography)(() => ({
  color: "red",
  fontSize: "0.875rem", // Adjust the font size as needed
  fontWeight: 500,
  marginBottom: "15px", // Add margin as needed
  textAlign: "left",
}));

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT;
  const role = useSelector((state: RootState) => state.role);
 
 
  const formatDate = (inputDate: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const formattedDate: string = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  const formatDobDate = (inputDate: string) => {
    const date = new Date(inputDate);

    // Get day, month, and year components
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    // Format the date as "yyyy-mm-dd"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const [profileDetails, setProfileDetails] = useState<any | null>(null);
  const [editProfileDetails, setEditProfileDetails] = useState<any | null>(
    null
  );
  const [editProfile, setEditProfile] = useState<boolean>(false);
  
  const [primaryAddress, setPrimaryAddress] = useState<any | null>(null);

  const [profilePicture, setProfilePicture] = useState(ProfilePic);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isProfilePicUpdated, setIsProfilePicUpdated] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    // phone_number_prefix: '',
    // image: Emp1,
    dob: "",
    gender: "",
    addresses: "",
    experience: "",
  });

  useEffect(() => {
    async function fetchData() {
      const accessToken = sessionStorage.getItem("access_token");
      try {
        const response = await fetch(`${apiEndpoint}/users/profile`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const data = await response.json();

        if (response.ok) {
          if (data && data.addresses.length > 0) {
            const primaryAddress = data?.addresses.find(
              (address: any) => address.address_type === "PRIMARY"
            );

            if (primaryAddress) {
              setPrimaryAddress(primaryAddress);
            } else {
              setPrimaryAddress(data?.addresses[0]);
            }
          }
          setProfileDetails(data);
          dispatch(setUserDetail(data));
        } else {
          console.error("Login failed");
        }
      } catch (error) {
        console.error("Error during login:", error);
      }
    }

    fetchData();
  }, []);
  
const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleConfirmDeactivation = async () => {
    try {
        const accessToken = sessionStorage.getItem('access_token');
        if (!accessToken) {
          toast.error("You need to be logged in to perform this action.");
          return;
        }
      
          const response = await fetch(`${apiEndpoint}/users/profile/profile_deactivate/`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`,
            },
          });
          if (response.ok) {
              // toast.success("Your profile has been deleted successfully.");
              setModalOpen(false);
              dispatch(setRole(""));
              dispatch(setUserDetail(null));
              
            } else {
              const data = await response.json();
              toast.error(data.message || "Failed to delete the account.");
            }
          } catch (error) {
            console.error("Error during deletion:", error);
            toast.error("An unexpected error occurred. Please try again.");
          }
    
  };

  const handleValidation = () => {
    const newErrors = {
      first_name: "",
      last_name: "",
      phone_number: "",
      // phone_number_prefix: '',
      dob: "",
      gender: "",
      addresses: "",
      experience: "",
    };

    if (!editProfileDetails.first_name) {
      newErrors.first_name = "First name is required";
    }

    if (!editProfileDetails.last_name) {
      newErrors.last_name = "Last name is required";
    }

    if (!editProfileDetails.dob) {
      newErrors.dob = "DOB is required";
    }

    if (!editProfileDetails.gender) {
      newErrors.gender = "Gender is required";
    }

    // if (!editProfileDetails.experience) {
    //   newErrors.experience = 'Experience is required';
    // }

    if (!editProfileDetails.phone_number) {
      newErrors.phone_number = "Mobile number is required";
    }

    // if (!validateMobileNumber(editProfileDetails.phone_number)) {
    //   newErrors.phone_number = 'Invalid mobile number';
    // }

    // if (!editProfileDetails.phone_number_prefix) {
    //   newErrors.phone_number_prefix = 'Prefix is required';
    // }

    setErrors(newErrors);

    return (
      !newErrors.first_name &&
      !newErrors.last_name &&
      !newErrors.dob &&
      !newErrors.gender &&
      !newErrors.experience &&
      !newErrors.first_name &&
      !newErrors.phone_number
    );
  };

  // const validateMobileNumber = (mobileNumber: string): boolean => {
  //   const mobileNumberRegex = /^[0-9]{10}$/;
  //   return mobileNumberRegex.test(mobileNumber);
  // };

  const saveProfileData = async (e: any) => {
    const accessToken = sessionStorage.getItem("access_token");
    if (handleValidation()) {
      e.preventDefault();

      try {
        const response = await fetch(
          `${apiEndpoint}/users/profile/${profileDetails.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              first_name: editProfileDetails.first_name,
              last_name: editProfileDetails.last_name,
              phone_number: editProfileDetails.phone_number,
              dob: editProfileDetails.dob,
              gender: editProfileDetails.gender,
              is_active: true,
            }),
          }
        );
        const data = await response.json();

        if (response.ok) {
          setEditProfile(false);

          dispatch(setUserDetail(data.data));

          if (isProfilePicUpdated) {
            const profileURL = `${apiEndpoint}/users/profile/${profileDetails.id}/update_profile_picture/`;

            const formData = new FormData();
            if (selectedFile) formData.append("image", selectedFile);

            try {
              const responseImage = await axios.put(profileURL, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${accessToken}`,
                },
              });

              const profileData = data.data;
              profileData.profile_picture_url = profilePicture;

              setProfileDetails(profileData);
              dispatch(setUserDetail(profileData));
            } catch (error) {
              console.error("Error uploading image:", error);
              setProfileDetails(data.data);
              dispatch(setUserDetail(data.data));
            }
          } else {
            setProfileDetails(data.data);
            dispatch(setUserDetail(data.data));
          }

          toast.success("Profile details saved ", {
            position: "top-right",
            autoClose: 5000,
          });
        } else {
          toast.warning("Unable to save details", {
            position: "top-right",
            autoClose: 5000,
          });
        }
      } catch (error) {
        console.error("Error during login:", error);
        toast.error("Someting went wrong", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    }
  };

  const handleChange = (prop: any) => (event: any) => {
    setEditProfileDetails({
      ...editProfileDetails,
      [prop]: event.target.value,
    });
    setErrors({ ...errors, [prop]: "" });
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrl = reader.result;
        setProfilePicture(dataUrl);
        setIsProfilePicUpdated(true);
      };

      reader.readAsDataURL(file);
    }
  };

  const isTabletScreen = useMediaQuery("(max-width: 768px)");
  const isMobileScreen = useMediaQuery("(max-width: 425px)");
  const isMobileMedium = useMediaQuery("(max-width:  375px)");
  const isDesktopScreen = useMediaQuery("(max-width: 1024px)");

  return (
    <Box sx={{ px: isMobileScreen ? "0px" : 3, mb: 8 }}>
      <ToastContainer />
      {profileDetails && (
        <React.Fragment>
          <Header>
            <Typography
              component="div"
              sx={{ fontSize: "32px", color: "#000000" }}
            >
              {!editProfile && <React.Fragment>Profile</React.Fragment>}
              {editProfile && (
                <React.Fragment>
                  <img
                    src={backArraow}
                    onClick={() => {
                      setEditProfile(false);
                    }}
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      top: "9px",
                      right: "8px",
                    }}
                  />
                  Profile / Edit
                </React.Fragment>
              )}
            </Typography>
          </Header>
          {editProfile && (
            <Grid container sx={{ mt: 3 }}>
              <Card sx={{ width: "100%" }}>
                <BoxCard>
                  <Typography
                    component="div"
                    sx={{
                      fontSize: "16px",
                      color: "#000000",
                      fontWeight: 600,
                      lineHeight: "20px",
                    }}
                  >
                    Basic details
                  </Typography>

                  <Box
                    className="dynamic-form"
                    sx={{
                      display: isTabletScreen ? "block" : "flex",
                      justifyContent: "space-between",
                      my: 2,
                    }}
                  >
                    <div
                      className={
                        !isProfilePicUpdated &&
                        !editProfileDetails?.profile_picture_url
                          ? "Rectangle-5624"
                          : "Rectangle-5626"
                      }
                    >
                      <img
                        src={
                          !isProfilePicUpdated &&
                          editProfileDetails?.profile_picture_url
                            ? editProfileDetails.profile_picture_url
                            : profilePicture
                        }
                        alt="Profile Picture"
                        style={
                          !isProfilePicUpdated &&
                          !editProfileDetails?.profile_picture_url
                            ? {
                                height: "96px",
                                width: "96px",
                                
                              }
                            : {
                                height: isTabletScreen ? "160px" : "200px",
                                maxWidth: "190px",
                                borderRadius: "8px",
                                // padding: '10px 0px',
                                border: "1px solid #568A60",
                                
                              }
                        }
                      />

                      <input
                        type="file"
                        id="file-input"
                        onChange={handleFileChange}
                        accept=".png, .jpg, .jpeg"
                        required
                      />
                      <label
                        htmlFor="file-input"
                        className="Drag-and-drop-or-Choose-file-to-upload label-style"
                        style={{ cursor: "pointer", textAlign: "center" }}
                      >
                        <Typography
                          variant="body1"
                          component="span"
                          px={1}
                          color="primary"
                        >
                          {!isProfilePicUpdated
                            ? "Upload Picture"
                            : "Change Picture"}
                        </Typography>
                      </label>
                    </div>

                    <Grid container sx={{ ml: isTabletScreen ? undefined : 3 , mt: isTabletScreen ? 8: undefined}}>
                      <Grid item xs={12} md={6} sx={{ padding: "0 10px" }}>
                        <InputLabel htmlFor="first-name">
                          {" "}
                          First Name *
                        </InputLabel>
                        <InputField
                          id="first-name"
                          fullWidth
                          margin="normal"
                          value={editProfileDetails.first_name}
                          onChange={handleChange("first_name")}
                        />
                        <ErrorLabel>{errors.first_name}</ErrorLabel>
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ padding: "0 10px" }}>
                        <InputLabel htmlFor="last-name">
                          {" "}
                          Last name *
                        </InputLabel>
                        <InputField
                          id="last-name"
                          type="text"
                          fullWidth
                          margin="normal"
                          value={editProfileDetails.last_name}
                          onChange={handleChange("last_name")}
                        />
                        <ErrorLabel>{errors.last_name}</ErrorLabel>
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ padding: "0 10px" }}>
                        <InputLabel htmlFor="dob"> DOB *</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            sx={{ width: "100%", mt: 1, mb: 2 }}
                            views={["year", "month", "day"]}
                            defaultValue={dayjs(editProfileDetails.dob)}
                            value={dayjs(editProfileDetails.dob)}
                            format="DD-MM-YYYY"
                            onChange={(date) => {
                              if (date) {
                                setEditProfileDetails({
                                  ...editProfileDetails,
                                  ["dob"]: dayjs(date),
                                });
                                setErrors({ ...errors, ["dob"]: "" });
                              } else {
                                setEditProfileDetails({
                                  ...editProfileDetails,
                                  ["dob"]: "",
                                });
                                setErrors({
                                  ...errors,
                                  ["dob"]: "DOB is required",
                                });
                              }
                            }}
                            disableFuture
                          />
                        </LocalizationProvider>
                        <ErrorLabel>{errors.dob}</ErrorLabel>
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ padding: "0 10px" }}>
                        <InputLabel htmlFor="gender"> Gender * </InputLabel>
                        <Select
                          value={editProfileDetails.gender}
                          onChange={handleChange("gender")}
                          // onChange={(e) => handleQuestionChange(questionIndex, 'type', e.target.value)}
                          required
                          sx={{ width: "100%", mt: 1, mb: 2 }}
                        >
                          <MenuItem value="MALE">Male</MenuItem>
                          <MenuItem value="FEMALE">Female</MenuItem>
                        </Select>
                        <ErrorLabel sx={{ mt: 2 }}>{errors.gender}</ErrorLabel>
                      </Grid>
                      {/* <Grid item xs={12} md={3} sx={{ padding: '0 10px' }}>
                        <InputLabel htmlFor="experience"> Experience</InputLabel>
                        <InputField
                          id="experience"
                          type="text"
                          fullWidth
                          margin="normal"
                          value={editProfileDetails.experience}
                          onChange={handleChange('experience')}
                        />
                        <ErrorLabel>{errors.experience}</ErrorLabel>
                      </Grid> */}
                    </Grid>
                  </Box>
                </BoxCard>
                {role !== "user" && (
                  <React.Fragment>
                    <Divider flexItem />
                    <BoxCard>
                      <Typography
                        component="div"
                        sx={{
                          fontSize: "16px",
                          color: "#000000",
                          fontWeight: 600,
                          lineHeight: "20px",
                        }}
                      >
                        Personal details
                      </Typography>
                      <Grid container sx={{ mt: 2 }}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sx={{ padding: "0 10px 0px 0px" }}
                        >
                          <InputLabel htmlFor="mobile-no">
                            {" "}
                            Mobile No
                          </InputLabel>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* <InputField
                          id="mobile-no"
                          fullWidth
                          margin="normal"
                          sx={{ width: '100px' }}
                          placeholder="+91"
                          value={editProfileDetails.phone_number_prefix}
                          onChange={handleChange('phone_number_prefix')}
                        /> */}

                            <InputField
                              disabled={role === "user" ? true : false}
                              id="mobile-no"
                              fullWidth
                              margin="normal"
                              sx={{
                                width: "100%",
                                //  ml: 2
                              }}
                              value={editProfileDetails.phone_number}
                              onChange={handleChange("phone_number")}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* <ErrorLabel>{errors.phone_number_prefix}</ErrorLabel> */}
                            <ErrorLabel>{errors.phone_number}</ErrorLabel>
                          </Box>
                        </Grid>
                      </Grid>
                    </BoxCard>
                  </React.Fragment>
                )}
                <Divider flexItem />

                <Box sx={{ textAlign: "right", py: 5, mr: 2 }}>
                  <Button
                    sx={{
                      borderRadius: "30px",
                      mr: 2,
                      padding: "10px 20px",
                      color: "#1F261F",
                    }}
                    onClick={() => {
                      setEditProfile(false);
                      setEditProfileDetails(null);
                      setErrors({
                        first_name: "",
                        last_name: "",
                        phone_number: "",
                        // phone_number_prefix: '',
                        // image: Emp1,
                        dob: "",
                        gender: "",
                        addresses: "",
                        experience: "",
                      });
                    }}
                  >
                    Cancel
                  </Button>

                  <LoginButton
                    variant="contained"
                    sx={{ borderRadius: "30px" }}
                    onClick={saveProfileData}
                  >
                    Save Changes
                  </LoginButton>
                </Box>
              </Card>
            </Grid>
          )}

          {!editProfile && (
            <Grid container sx={{ mt: 3 }}>
              <Card sx={{ width: "100%", padding: "0", }}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{ padding: "20px 10px", textAlign: "right", mt: 2 }}
                >
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: "30px", mr: 2, padding:isTabletScreen ? "1px 10px" : "10px 30px" }}
                    onClick={() => {
                      setEditProfile(true);
                      const editData = { ...profileDetails };
                      // editData.phone_number_prefix = profileDetails.phone_number.split(' ')[0];
                      // editData.phone_number = profileDetails.phone_number.split(' ')[1];
                      setEditProfileDetails(editData);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: "30px", mr: 2, padding:isTabletScreen ? "1px 10px" : "10px 30px" }}
                    onClick={handleOpenModal}
                    >
                    Delete
                  </Button>
                  <CustomModal
                       open={modalOpen}
                       handleClose={handleCloseModal}
                       handleConfirm={handleConfirmDeactivation}
                    />
                </Grid>
                <BoxCardProfile sx={{ display: isTabletScreen ?  "block" : "flex" }}>
                  <Grid item xs={12} md={4} sx={{ padding: "0 0 33px 30px", fontSize: "20px" }}>
                    <Typography
                      component="div"
                      sx={{
                        fontSize: "16px",
                        color: "#000000",
                        fontWeight: 600,
                        lineHeight: "20px",
                      }}
                    >
                      Basic details
                    </Typography>
                  </Grid>

                  <Box
                    className="dynamic-form"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Grid
                      container
                      sx={{ textAlign: "center", width: "400px" }}
                    >
                      <Grid item xs={12} md={12} sx={{ padding: "0 10px" }}>
                        {profileDetails.profile_picture_url &&
                        profileDetails.profile_picture_url !== "" ? (
                          <img
                            src={profileDetails.profile_picture_url}
                            alt="Profile Picture"
                            style={{
                              height: "200px",
                              width: "180px",
                              borderRadius: "8px",
                            }}
                          />
                        ) : (
                          <img
                            src={ProfilePic}
                            alt="Profile Picture"
                            style={{
                              height: "200px",
                              width: "250px",
                              borderRadius: "8px",
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} md={12} sx={{ padding: "10px" }}>
                        <Typography
                          component="div"
                          sx={{
                            fontSize: "24px",
                            color: "#36423C",
                            fontWeight: 500,
                            lineHeight: "30px",
                          }}
                        >
                          {profileDetails.first_name} {profileDetails.last_name}
                        </Typography>
                      </Grid>
                      {profileDetails.gender && (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sx={{
                            padding: "10px 10px 10px 0px",
                            textAlign: "center",
                          }}
                        >
                          <InputLabel htmlFor="gender">Gender</InputLabel>
                          <Typography
                            component="div"
                            sx={{
                              fontSize: "16px",
                              color: "#000000",
                              fontWeight: 500,
                              lineHeight: "30px",
                            }}
                          >
                            {profileDetails.gender}
                          </Typography>
                        </Grid>
                      )}
                      {profileDetails.dob && (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sx={{ padding: "10px", textAlign: "center" }}
                        >
                          <InputLabel htmlFor="dob"> DOB</InputLabel>
                          <Typography
                            component="div"
                            sx={{
                              fontSize: "16px",
                              color: "#000000",
                              fontWeight: 500,
                              lineHeight: "30px",
                            }}
                          >
                            {formatDate(profileDetails.dob)}
                          </Typography>
                        </Grid>
                      )}
                      {/* <Grid item xs={12} md={4} sx={{ padding: '10px', textAlign: 'center' }}>
                        <InputLabel htmlFor="experience"> Experience</InputLabel>
                        <Typography
                          component="div"
                          sx={{ fontSize: '16px', color: '#000000', fontWeight: 500, lineHeight: '30px' }}
                        >
                          {profileDetails.experience}
                        </Typography>
                      </Grid> */}
                    </Grid>
                  </Box>
                </BoxCardProfile>
                <Divider flexItem sx={{ my: 3 }} />
                <BoxCardProfile sx={{ display: isTabletScreen ?  "block" : "flex" , pt: 5 }}>
                  <Grid item xs={12} md={4} sx={{ padding: "0 0 33px 30px", fontSize: "20px" }}>
                    <Typography
                      component="div"
                      sx={{
                        fontSize: "16px",
                        color: "#000000",
                        fontWeight: 600,
                        lineHeight: "20px",
                      }}
                    >
                      Personal details
                    </Typography>
                  </Grid>

                  <Grid container sx={{ mb: 15, ml: isTabletScreen ? "2.2rem" : "7.5rem",
                                        width: isTabletScreen ? "85%" : "100%", }}>
                    <Grid item xs={12} md={12} sx={{ mb: 3 }}>
                      <InputLabel htmlFor="gender">Mobile Number</InputLabel>
                      <Typography
                        component="div"
                        sx={{
                          fontSize: "16px",
                          color: "#000000",
                          fontWeight: 500,
                          lineHeight: "30px",
                        }}
                      >
                        {profileDetails.username}
                      </Typography>
                    </Grid>

                    {/* <Grid item xs={12} md={12} sx={{ mb: 3 }}>
                      <InputLabel htmlFor="address"> Address</InputLabel>
                      {profileDetails &&
                        profileDetails.addresses &&
                        profileDetails.addresses.length > 0 &&
                        profileDetails.addresses.map((addresses: any) => {
                          return (
                            <Box>
                              <Typography
                                component="div"
                                sx={{ fontSize: '16px', color: '#36423C', fontWeight: 700, lineHeight: '30px', mb: 1 }}
                              >
                                {addresses.title}
                                {addresses &&
                                addresses.address_type &&
                                addresses.address_type.toUpperCase() === 'PRIMARY'
                                  ? '(DEFAULT)'
                                  : ''}
                              </Typography>
                              <Typography
                                component="div"
                                sx={{ fontSize: '14px', color: '#000000', fontWeight: 400, lineHeight: '30px', mb: 3 }}
                              >
                                {addresses.address}, {addresses.city}, {addresses.state}, {addresses.country},{' '}
                                {addresses.pincode}
                              </Typography>
                            </Box>
                          );
                        })}
                    </Grid> */}

                    {primaryAddress && (
                      <Grid item xs={12} md={12} sx={{ mt: 3 }}>
                        <InputLabel htmlFor="address"> Address</InputLabel>
                        <Typography
                          component="div"
                          sx={{
                            fontSize: "14px",
                            color: "#000000",
                            fontWeight: 400,
                            lineHeight: "30px",
                            mb: 1,
                          }}
                        >
                          {primaryAddress.title}
                        </Typography>
                        <Typography
                          component="div"
                          sx={{
                            fontSize: "14px",
                            color: "#000000",
                            fontWeight: 400,
                            lineHeight: "30px",
                            mb: 1,
                          }}
                        >
                          {primaryAddress.address}, {primaryAddress.city},{" "}
                          {primaryAddress.state}, {primaryAddress.country},
                          {primaryAddress.pincode}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                      <Button
                        variant="outlined"
                        sx={{
                          borderRadius: "30px",
                          mr: 2,
                          padding: isTabletScreen ? "1px 10px" : "10px 30px",
                        }}
                        onClick={() => {
                          navigate("/address");
                        }}
                      >
                        Manage Addresses
                      </Button>
                    </Grid>
                  </Grid>
                </BoxCardProfile>
              </Card>
            </Grid>
          )}
        </React.Fragment>
      )}
    </Box>
  );
};

export default Profile;
