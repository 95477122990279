import { useMemo, useState } from "react";
import {
  Box,
  Fade,
  Modal,
  Typography,
  Button,
  OutlinedInput,
} from "@mui/material";
import { quotation } from "../../types";
import { prepareQuotation } from "../../action/timeline";
import {
  errorNotification,
  successNotification,
} from "../../components/shared/Toast/toast";

type Props = {
  quotation: boolean;
  setQuotation: (quote: boolean) => void;
  style: object;
  savedQuotation: quotation;
  setSavedQuotation: any;
  selectedService: string;
  timeLineData: Function;
};
export default function AssignQuotationModal({
  quotation,
  setQuotation,
  style,
  savedQuotation,
  setSavedQuotation,
  selectedService,
  timeLineData,
}: Props) {
  const [addNewRow, setAddNewRow] = useState(1);
  const [addTax, setAddTax] = useState(false);
  const [taxValue, setTaxValue] = useState(0);
  const [quotationDetails, setQuotationDetails] = useState<string>("");
  const vendorQuotation: any =
    savedQuotation &&
    Object.keys(savedQuotation.item).map((key: any) => {
      const item = savedQuotation.item[key];
      const unit_price = savedQuotation.unit_price[key];
      const quantity = savedQuotation.quantity[key];
      const total = savedQuotation.total[key];
      return {
        item,
        unit_price,
        quantity: parseInt(quantity),
        total,
      };
    });

  const handleTaxChange = (e: any) => {
    const newValue = parseInt(e.target.value);
    setTaxValue(newValue);
  };

  const total = useMemo(() => {
    let value = vendorQuotation
      .filter((item: any) => !isNaN(parseFloat(item.total)))
      .reduce((acc: any, item: any) => acc + parseFloat(item.total), 0);
    return value;
  }, [vendorQuotation]);

  const validateQuotation = () => {
    let valid = vendorQuotation.every((ele: any) => (ele.item && ele.unit_price >= 0 && ele.quantity >= 0))
    if (!valid) errorNotification("Make sure item, quantity and price are populated in all rows.");
    return valid;
  }

  const handleSave = () => {
    if (!validateQuotation()) return;
    setQuotation(false);
    let body = {
      quotation_details: vendorQuotation,
      taxes: taxValue,
      total: vendorQuotation
        .filter((item: any) => !isNaN(parseFloat(item.total)))
        .reduce(
          (acc: any, item: any) => acc + parseFloat(item.total),
          0
        ),
      notes: quotationDetails.length > 0 ? quotationDetails : "",
    };

    prepareQuotation(selectedService, body)
      .then(() => {
        timeLineData(selectedService);
        successNotification("Quotation Prepared successfully");
      })
      .catch(() => {
        errorNotification(
          "Error in preparing the quotation.Please try again"
        );
      });
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={quotation}
      onClose={() => setQuotation(false)}
      closeAfterTransition
      style={{ overflow: "auto" }}
    >
      <Fade in={quotation}>
        <Box sx={{...style, maxHeight: '90vh', maxWidth: '95vw', width: '600px', overflow: 'auto'}}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Quotation
          </Typography>
          <Typography
            id="transition-modal-title"
            sx={{ fontSize: 16, fontWeight: 400, mt: 2 }}
          >
            Add new row to add items.
          </Typography>
          <Box>
            <Box
              sx={{
                display: "grid",
                columnGap: 1,
                rowGap: 1,
                gridTemplateColumns: "repeat(4, 4fr)",
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#1F261F",
                  textAlign: "center",
                }}
              >
                Item
              </Typography>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#1F261F",
                  textAlign: "center",
                }}
              >
                Price
              </Typography>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#1F261F",
                  textAlign: "center",
                }}
              >
                Quantity
              </Typography>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#1F261F",
                  textAlign: "center",
                }}
              >
                Total
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#1F261F",
                  textAlign: "center",
                }}
              >
                Delete
              </Typography> */}
            </Box>
            {[...Array(addNewRow)].map((_, i) => {
              return (
                <Box
                  sx={{
                    display: "grid",
                    columnGap: 1,
                    rowGap: 1,
                    gridTemplateColumns: "repeat(4, 4fr)",
                    mt: 2,
                  }}
                >
                  <OutlinedInput
                    name="item"
                    type="text"
                    sx={{ height: 30 }}
                    onChange={(event) => {
                      setSavedQuotation((prev: any) => ({
                        ...prev,
                        item: {
                          ...prev.item,
                          [i]: event.target.value,
                        },
                      }));
                    }}
                    value={savedQuotation.item[i]}
                  />
                  <OutlinedInput
                    name="unit_price"
                    type="number"
                    sx={{ height: 30 }}
                    onChange={(e) => {
                      setSavedQuotation((prev: any) => ({
                        ...prev,
                        unit_price: {
                          ...prev.unit_price,
                          [i]: e.target.value,
                        },
                        total: {
                          ...prev.total,
                          [i]:
                            parseInt(e.target.value, 10) *
                            parseInt(savedQuotation.quantity[i], 10),
                        },
                      }));
                    }}
                    value={savedQuotation.unit_price[i]}
                  />
                  <OutlinedInput
                    name="quantity"
                    type="number"
                    sx={{ height: 30 }}
                    onChange={(e) => {
                      setSavedQuotation((prev: any) => ({
                        ...prev,
                        quantity: {
                          ...prev.quantity,
                          [i]: e.target.value,
                        },
                        total: {
                          ...prev.total,
                          [i]:
                            parseInt(savedQuotation.unit_price[i], 10) *
                            parseInt(e.target.value, 10),
                        },
                      }));
                    }}
                    value={savedQuotation.quantity[i]}
                  />
                  <OutlinedInput
                    name="total"
                    type="number"
                    sx={{ height: 30 }}
                    value={
                      savedQuotation.unit_price[i] * savedQuotation.quantity[i]
                    }
                  />
                  {/* <p
                    style={{
                      color: "red",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={() => {}}
                  >
                    <DeleteIcon />
                  </p> */}
                </Box>
              );
            })}
            {addTax && (
              <Box sx={{ display: "flex", columnGap: "8px", marginTop: "8px" }}>
                <OutlinedInput type="text" sx={{ height: 30 }} value={"Tax"} />
                <OutlinedInput
                  name="taxes"
                  sx={{ height: 30 }}
                  type="number"
                  onChange={(e) => {
                    handleTaxChange(e);
                  }}
                  value={taxValue}
                />
              </Box>
            )}
            <Button
              variant="outlined"
              onClick={() => setAddNewRow(addNewRow + 1)}
              sx={{
                mt: 2,
              }}
            >
              + Add Items
            </Button>
          </Box>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 400,
              mt: 2,
              mb: 2,
              display: "flex",
              justifyContent: "end",
            }}
          >
            {vendorQuotation.every(
              (item: any) =>
                item.hasOwnProperty("total") && !isNaN(parseFloat(item.total))
            ) && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {`Total: ₹`}
                {total + taxValue}
              </Box>
            )}
          </Typography>
          <Box
            sx={{
              gridRow: "1",
              gridColumn: "span 5",
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
            }}
          >
            <Typography>Please type in your comments</Typography>
            <textarea
              style={{
                width: "100%",
                border: "1px solid #71A187",
                borderRadius: 2,
                height: 100,
                padding: 4,
                resize: "none",
              }}
              onChange={(e) => {
                setQuotationDetails(e.target.value);
              }}
              maxLength={5000}
              placeholder="Description must be 5000 characters or less."
            >
              {quotationDetails}
            </textarea>
            <div style={{ display: "-webkit-flex" }}>
              <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
                {quotationDetails &&
                  `${5000 - quotationDetails.length} characters remaining`}
              </Typography>
            </div>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt:4
            }}
          >
            <Box
              sx={{
                display: "flex",
                columnGap: 2,
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setAddTax(true);
                }}
                sx={{ width: "120px" }}
              >
                Add Tax
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setQuotation(false);
                }}
                sx={{ width: "120px" }}
              >
                Close
              </Button>
              <Button
                variant="outlined"
                sx={{ width: "120px" }}
                onClick={() => handleSave()}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
