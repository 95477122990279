import { Box, Button, Grid, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import ImageSliderModal from "../../components/shared/image-slider";
import { StepperType } from "../../types/StepperType";
import {
  errorNotification,
  successNotification,
} from "../../components/shared/Toast/toast";
import { postInspectionDetails } from "../../action/timeline";
import axios from "axios";

type Props = {
  setInsecptionDetails: (details: string) => void;
  insecptionDetails: string;
  selectedFile: any;
  setSelectedFile: any;
  selectedFileDisplay: any;
  setSelectedFileDisplay: any;
  imgInspectionSlider: boolean;
  setImgInspectionSlider: Dispatch<SetStateAction<boolean>>;
  startIndex: number;
  setStartIndex: any;
  imagePreviewFlag: string;
  setImagePreviewFlag: any;
  isImageUpdated: any;
  setIsImageUpdated: any;
  timeLineData: Function;
  selectedService: string;
  deleteImage: () => void;
  handleFileChange: any;
  fileInputRef: any;
};

export const InsepectionDetailsByVendor = ({
  setInsecptionDetails,
  insecptionDetails,
  selectedFileDisplay,
  setImgInspectionSlider,
  setStartIndex,
  setImagePreviewFlag,
  handleFileChange,
  fileInputRef,
  isImageUpdated,
  timeLineData,
  setSelectedFile,
  selectedService,
  imgInspectionSlider,
  startIndex,
  deleteImage,
  imagePreviewFlag,
  setSelectedFileDisplay,
  setIsImageUpdated,
  selectedFile,
}: Props) => {
  const handleUpload = async () => {
    const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT;
    const accessToken = sessionStorage.getItem("access_token");
    try {
      const formData = new FormData();

      const filteredArray = selectedFile.filter(
        (item: any) => typeof item === "object"
      );
      for (let i = 0; i < filteredArray.length; i++) {
        formData.append("images", filteredArray[i]);
      }
      const randomNo = Math.floor(Math.random() * 900000) + 100000;
      formData.append("job_progress_details", randomNo.toString());
      formData.append("detail_type", "inspection");

      const response = await axios.put(
        `${apiEndpoint}/bookings/${selectedService}/upload_booking_images/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response && response.data) {
        setSelectedFileDisplay(response.data.data);
        setSelectedFile([]);
        setIsImageUpdated(false);
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  let insepectionDetailsByVendor: StepperType = {
    label: (
      <Box>
        <Typography
          variant="caption"
          sx={{
            fontSize: "16px",
            color: "#568A60",
            fontWeight: 600,
            cursor: "pointer",
          }}
        >
          Inspection Details
        </Typography>
      </Box>
    ),
    roles: ["vendor"],
    description: (
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            gridAutoColumns: "1fr",
            gap: 3,
          }}
        >
          <Box
            sx={{
              gridRow: "1",
              gridColumn: "span 5",
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
            }}
          >
            <Grid container sx={{ mb: 1, bottom: 0 }}>
              <Grid item xs={9}>
                <Typography sx={{ fontSize: 16 }}>
                  Please type in your comments
                </Typography>
                <textarea
                  style={{
                    border: "1px solid #71A187",
                    borderRadius: 2,
                    height: 100,
                    padding: 4,
                    resize: "none",
                    width: "90%",
                    marginTop: 1,
                  }}
                  onChange={(e) => {
                    setInsecptionDetails(e.target.value);
                  }}
                  maxLength={5000}
                  placeholder="Description must be 5000 characters or less."
                >
                  {insecptionDetails}
                </textarea>
                <div style={{ display: "-webkit-flex" }}>
                  <Typography sx={{ fontWeight: 5000, fontSize: 14 }}>
                    {insecptionDetails &&
                      `${
                        5000 - insecptionDetails?.length
                      } characters remaining`}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} sx={{ px: 2, position: "relative" }}>
                <Box
                  sx={{
                    gridRow: "1",
                    gridColumn: "span 1",
                    alignContent: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    rowGap: 2,
                  }}
                >
                  <Typography>Photos</Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 2fr)",
                    }}
                  >
                    {selectedFileDisplay &&
                      selectedFileDisplay?.length > 0 &&
                      selectedFileDisplay
                        .slice(0, 3)
                        .map((file: any, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              <img
                                style={{
                                  width: "65px",
                                  height: "65px",
                                  margin: "5px",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setImgInspectionSlider(true);
                                  setStartIndex(index);
                                  setImagePreviewFlag("edit");
                                }}
                                src={file}
                                alt=""
                              />
                              {selectedFileDisplay?.length > 3 &&
                                index === 2 && (
                                  <label
                                    style={{
                                      width: "65px",
                                      height: "65px",
                                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                                      borderRadius: "8px",
                                      textAlign: "center",
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      color: "white",
                                      cursor: "pointer",
                                      margin: "5px",
                                      position: "absolute",
                                      top: "123px",
                                    }}
                                    onClick={() => {
                                      setImgInspectionSlider(true);
                                      setStartIndex(2);
                                      setImagePreviewFlag("edit");
                                    }}
                                  >
                                    + {selectedFileDisplay?.length - 3}
                                  </label>
                                )}
                            </React.Fragment>
                          );
                        })}

                    <label
                      htmlFor="fileInput"
                      style={{
                        width: "65px",
                        height: "65px",
                        backgroundColor: "#1F261F",
                        borderRadius: "8px",
                        textAlign: "center",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        color: "white",
                        cursor: "pointer",
                        margin: "5px",
                      }}
                    >
                      + Add
                    </label>
                    <input
                      id="fileInput"
                      type="file"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={(event) => handleFileChange(event)}
                      accept=".png, .jpg, .jpeg"
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid xs={12}>
                <Button
                  variant="contained"
                  sx={{ float: "right", marginLeft: "auto" }}
                  onClick={async () => {
                    if (isImageUpdated) await handleUpload();
                    let body = {
                      details: insecptionDetails,
                    };
                    await postInspectionDetails(selectedService, body)
                      .then(async () => {
                        successNotification(
                          "Inspection Details created successfully."
                        );
                        setInsecptionDetails("");
                        setSelectedFile([]);
                        timeLineData(selectedService);
                      })
                      .catch(() => {
                        errorNotification(
                          "Error in submitting the inspection details .Please try again"
                        );
                      });
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <ImageSliderModal
          isImageSliderModalOpen={imgInspectionSlider}
          onImageSliderModalClose={setImgInspectionSlider}
          images={selectedFileDisplay}
          startIndex={startIndex}
          setStartIndex={setStartIndex}
          deleteImage={deleteImage}
          imagePreviewFlag={imagePreviewFlag}
          flag="string"
        />
      </div>
    ),
  };
  return insepectionDetailsByVendor;
};
