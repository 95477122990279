import { useEffect, useState } from "react";
import { Box, Button, Toolbar, Typography, TextField, IconButton, Drawer } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { theme } from "../../../utils/theme";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRole, setUserDetail } from "../../../store";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import {HomeMenu} from "../../../components/core/sideBar/menu-list";
import { setSearchService } from "../../../store";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
const Logo = require("../../../assets/Logo-hello-fixy-green.svg").default;

const LoginButton = styled(Button)`
  margin-left: 20px;
  margin-top: 20px;
  text-align: left;
  height: 48px;
  color: #ffffff;
  background: #71a187;
  font-size: 16px;
  font: Kufam;
  font-weight: 600;
  border-radius: 30px;
  padding: 10px 20px;
  &:hover {
    background: #568a60; /* Change the background color on hover */
  }

  &:active {
    background: #718875; /* Change the background color on click */
  }
`;

const HeaderContent = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #a4d2b9;
  padding: 0px !important;
`;

const LogoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 1rem;
  cursor: pointer;
`;

const LoginContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const MiddleContainer = styled(Box)`
  display: none;
  flex-direction: row;
  flex-grow: 1;
  height: 60px;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
  justify-items: flex-end;
`;

const LinksContainer = styled(Box)`
  display: flex;
  padding-left: 2rem;
  align-items: flex-end;
`;

const Link = styled(Typography)`
  color: #71a187;
  font-family: "Kufam", sans-serif;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0em;
  cursor: pointer;
  font-size: 1rem;
`;

const MiddleRightContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const InputField = styled(TextField)`
& .MuiOutlinedInput-root {
    margin-top: 2px;
    border: 1px solid #a4d2b9;
    border-radius: 30px;
    padding: 18px 0;
    height: 45px;
    font-size: 1rem;
  }
`;

export const LogoText = styled(Typography)`
  font-size: 1rem;
  font-family: "Kufam", sans-serif;
`;

export const getInitials = (fullName: string): string => {
  const words = fullName.split(" ");

  if (words.length === 1) {
    return words[0].slice(0, 2);
  }

  return words
    .map((word) => word.charAt(0))
    .slice(0, 2)
    .join("");
};
const HeaderContainer = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated: boolean = useSelector((state: any) => state?.userDetail) ? true : false;

  const location = useLocation();

  const _md = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);

  const [searchText, setSearchText] = useState('');

  const { children } = props;

  const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT;

  async function fetchData(accessToken: string) {
    try {
      const response = await fetch(`${apiEndpoint}/users/profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        dispatch(setUserDetail(data));
        return;
      } else {
        console.error("Login failed");
        return;
      }
    } catch (error) {
      console.error("Error during login:", error);
      return;
    }
  }

  useEffect(() => {
    const accessToken = sessionStorage.getItem("access_token");
    const role = sessionStorage.getItem("role");
    if (accessToken) {
      dispatch(setRole(role ? role : ""));
      fetchData(accessToken);
    }
  }, []);

  return (
    <Box
      sx={{
        boxShadow: "none",
        borderBottom: "none",
      }}
    >
      {isAuthenticated !== true && (
        <>
        <AppBar
          navigate={navigate}
          location={location}
          searchText={searchText}
          setSearchText={setSearchText}
          dispatch={dispatch}
          setSearchService={setSearchService}
          medium={_md}
          open={open}
          setOpen={setOpen}
        />
        <Drawer
          PaperProps={{
            sx: {
              padding: 0,
              margin: 0,
              width: "250px",
              borderRight: "none",
              whiteSpace: "nowrap",
            }
          }}
            variant={"temporary"}
            anchor="left"
            open={_md && open}
            onClose={() => setOpen(false)}
          >
            <Box
              sx={{
                width: "250px",
                backgroundColor: theme.palette.primary.light,
                height: '100vh',
                whiteSpace: "nowrap",
                padding: 0,
                margin: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    paddingTop: 2,
                    borderBottom: `1px solid ${theme.palette.primary}`,
                    textAlign: 'center'
                  }}
                >
                  <LogoContainer>
                    <img
                      src={Logo}
                      alt="Company Logo"
                      style={{
                        marginRight: 8,
                        height: 40,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/");
                      }}
                    />
                      <Typography
                        variant="h2"
                        sx={{
                          fontSize: "1rem"
                        }}
                      >
                        HelloFixy
                      </Typography>
                  </LogoContainer>
                </Box>
                <List
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100vh",
                  }}
                >
                  <HomeMenu />
              </List>
            </Box>
          </Drawer>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "75%",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export const AppBar = ({
  navigate,
  location,
  searchText='',
  setSearchText=()=>{},
  dispatch=()=>{},
  setSearchService=()=>{},
  medium=true,
  open=true,
  setOpen=()=>{},
  active=true,
}: any) => {

  if (!active) return (<></>);

  return (
    <HeaderContent sx={{ display: 'flex', mt: 0.5 }}>
          <LogoContainer
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              src={Logo}
              alt="Company Logo"
              style={{ marginRight: 8, height: 40 }}
            />
            <Typography
              sx={{
                fontFamily: "Kufam, sans-serif",
                fontSize: "1rem",
              }}
            >
              HelloFixy
            </Typography>
          </LogoContainer>
          <MiddleContainer sx={{ display: {xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex'}}}>
            <LinksContainer columnGap={{md: 4, lg: 4, xl: 6}}>
              <Link onClick={() => navigate("/all-services")}>Services</Link>
              <Link onClick={() => navigate("/help-center")}>FAQs</Link>
              <Link onClick={() => navigate("/about")}>About Us</Link>
            </LinksContainer>
            <MiddleRightContainer>
              {['/'].includes(location.pathname) && <InputField
                placeholder="Search services..."
                id="search_services"
                name="services"
                autoComplete="off"
                variant="outlined"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  dispatch(setSearchService(e.target.value));
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    navigate("/all-services");
                  }
                }}
                InputProps={{
                  autoComplete: "off",
                  endAdornment: (
                    <>
                      {
                        <IconButton edge="end" sx={{ mr: 0.2, visibility: searchText ? 'block' : 'hidden'}} disabled={searchText===''} onClick={() => setSearchText('')}>
                          <CloseIcon style={{ fontSize: "1.25rem" }} />
                        </IconButton>
                      }
                      <IconButton edge="end" sx={{ color: "#A4D2B9", mr: 0.2 }}>
                        <SearchIcon
                          onClick={() => navigate("/all-services")}
                        />
                      </IconButton>
                    </>
                  ),
                }}
              />}
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'none',
                    lg: 'flex',
                    xl: 'flex',
                  },
                  padding: "0.5rem 2rem",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FmdGoodOutlinedIcon/>
                <LogoText>Chennai</LogoText>
              </Box>
            </MiddleRightContainer>
          </MiddleContainer>
          <LoginContainer>
            { medium ? 
            <IconButton onClick={() => setOpen(!open)}>
              <MenuIcon />
            </IconButton> :
            <LoginButton
              variant="contained"
              color="primary"
              onClick={() => {
                navigate("/login");
              }}
              sx={{ width: "136px" }}
            >
              Login
            </LoginButton>
            }
          </LoginContainer>
        </HeaderContent>
  );
}

export default HeaderContainer;
