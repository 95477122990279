import PropTypes from "prop-types";
import { toast, ToastOptions } from "react-toastify";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import InfoIcon from "@mui/icons-material/Info";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import BugReportIcon from "@mui/icons-material/BugReport";

export const displayIcon = (type: any) => {
  switch (type) {
    case "success":
      return <TaskAltIcon />;
    case "info":
      return <InfoIcon />;
    case "error":
      return <ErrorIcon />;
    case "warning":
      return <WarningIcon />;
    default:
      return <BugReportIcon />;
  }
};

interface ToastMessageProps {
  message: string;
  type: string;
}

const ToastMessage = ({ type, message }: ToastMessageProps): void => {
  const options: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
  };
  //@ts-ignore
  toast[type](message, options);
};

ToastMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

ToastMessage.dismiss = toast.dismiss;

export const successNotification = (message: string): void => {
  ToastMessage({
    type: "success",
    message,
  });
};

export const warningNotification = (message: string): void => {
  ToastMessage({
    type: "warning",
    message: message,
  });
};
export const errorNotification = (message: string): void => {
  ToastMessage({
    type: "error",
    message: message,
  });
};

export const infoNotification = (message: string): void => {
  ToastMessage({
    type: "info",
    message: message,
  });
};

export default ToastMessage;
