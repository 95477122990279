import { Typography, Box, Paper, AppBar } from "@mui/material";
import styled from "@emotion/styled";
import { device } from "../../utils/constant";

export const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  box-shadow: none;
`;

export const Header = styled(AppBar)`
  background-color: #96c19b5e;
  width: 100%;
  color: #36423c;
  box-shadow: none;
  justify-content: center;
  padding: 10px 10px @media ${device.desktop} {
    padding: 25px 100px;
  }
  @media ${device.laptop} {
    padding: 10px 15px;
  }
  @media ${device.tablet} {
    padding: 10px 10px;
  }
  @media ${device.mobileL} {
    padding: 10px 10px;
  }
  @media ${device.mobileM} {
    padding: 10px 10px;
  }
  @media ${device.mobileS} {
    padding: 10px 10px;
  }
`;

export const LogoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  cursor: pointer;
`;

export const Text = styled(Typography)`
  font-size: 14px;
  @media ${device.laptop} {
    font-size: 16px;
  }
  font-weight: 400;
`;

export const TextBox = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const Title = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
`;

export const SubTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: #36423c;
`;
