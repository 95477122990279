import { Box, Fade, Modal, Typography, Button, Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { employeeInitialValue } from ".";
import { saveEmployee } from "../../action/timeline";
import { successNotification } from "../../components/shared/Toast/toast";
import { RootState } from "../../store";
import { EmployeeResponse } from "../../types";

type Props = {
  assignEmployeeModal: boolean;
  setAssignEmployeeModal: (employee: boolean) => void;
  style: object;
  assignEmployee: EmployeeResponse | null;
  setAssignEmployee: (item: EmployeeResponse) => void;
  selectedService: string;
  timeLineData: Function;
  workAssignEmployee: boolean;
  getInitials: (fullName: string) => string;
};

export default function AssignEmployeeModal({
  assignEmployeeModal,
  setAssignEmployeeModal,
  style,
  assignEmployee,
  setAssignEmployee,
  selectedService,
  timeLineData,
  workAssignEmployee,
  getInitials,
}: Props) {
  const employeeList = useSelector((state: RootState) => state.getemployeeList);
  const workEmployeeData = useSelector(
    (state: RootState) => state.getWorkEmployee
  );

  let data = workAssignEmployee ? workEmployeeData : employeeList;
  return (
    <>
      <ToastContainer />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={assignEmployeeModal}
        onClose={() => setAssignEmployeeModal(false)}
        closeAfterTransition
        style={{ overflow: "auto" }}
      >
        <Fade in={assignEmployeeModal}>
          <Box sx={
            {
              ...style,
              maxHeight: '90vh',
              maxWidth: '95vw',
              width: '700px',
              overflowY: 'auto',
              background: 'white'
          }}>
            <Typography
              id="transition-modal-title"
              sx={{
                fontSize: 16,
                fontWeight: 400,
                alignItems: "center",
                textAlign: "center",
                mb: 4,
              }}
            >
              Please choose employee
            </Typography>
            <Box
              sx={{
                display: "grid",
                columnGap: 1,
                rowGap: 1,
                gridTemplateColumns: "repeat(3, 2fr)",
                maxHeight: "50vh",
                overflow: "auto"
              }}
            >
              {data &&
                data.length > 0 &&
                data?.map((item) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        style={{
                          fontSize: "14px",
                          fontWeight: 400,
                          display: "flex",
                          columnGap: "8px",
                          alignItems: "center",
                          border: "2px solid #C1D0C4",
                          borderRadius: "10px",
                          padding: 10,
                          backgroundColor:
                            assignEmployee?.email === item.email
                              ? "#EEFFF1"
                              : "white",
                        }}
                        onClick={() => {
                          setAssignEmployee(item);
                        }}
                      >
                        {item?.profile_picture_url &&
                        item.profile_picture_url !== "" ? (
                          <Avatar
                            sx={{
                              width: 50,
                              height: 50,
                              marginBottom: 1,
                            }}
                            src={item.profile_picture_url}
                          ></Avatar>
                        ) : (
                          <Avatar
                            sx={{
                              bgcolor: "orange",
                              width: 50,
                              height: 50,
                              marginBottom: 1,
                              textTransform: "capitalize",
                            }}
                          >
                            {getInitials(
                              `${item.first_name} ${item.last_name}`
                            )}
                          </Avatar>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "3px",
                            mt: 0.5,
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "12px", fontWeight: 400 }}
                          >
                            {item.first_name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 400,
                              color: "#A7A7A7",
                            }}
                          >
                            {item.emp_code} | {item.designation}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
            </Box>

            <Box
              sx={{
                display: "flex",
                columnGap: 1,
                mt: 2,
                justifyContent: "center"
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setAssignEmployeeModal(false);
                }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  let body = {
                    employee_id: assignEmployee?.id,
                    role: assignEmployee?.designation,
                  };

                  saveEmployee(selectedService, body)
                    .then((res) => {
                      successNotification(`Successfully assigned employee`);
                      setAssignEmployeeModal(false);
                      timeLineData(selectedService);
                      setAssignEmployee(employeeInitialValue);
                    })
                    .catch((err) => {});
                }}
              >
                Assign
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
