import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  TextField,
  Grid,
  styled,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

import Location from "./location";

interface Address {
  id: number;
  title: string;
  address: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  address_type: string;
}

interface AddressModalProps {
  open: boolean;
  setIsAddressModalOpen: Dispatch<SetStateAction<boolean>>;
  selectedAddress: any;
  setSelectedAddress: any;
  addressList: any;
  setAddressList: any;
}

interface FormData {
  title: string;
  street: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
}

interface FormErrorData {
  title: string;
  street: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  location: string;
}

interface Location {
  lat: number;
  lng: number;
}

const ErrorLabel = styled(Typography)(() => ({
  color: "red",
  fontSize: "0.875rem", // Adjust the font size as needed
  fontWeight: 500,
  marginBottom: "0px", // Add margin as needed
  textAlign: "left",
}));

const ActionButton = styled(Button)`
  margin-left: 20px;
  text-align: left;
  height: 48px;
  color: #ffffff;
  background: #71a187;
  font-size: 16px;
  font: Kufam;
  font-weight: 600;
  border-radius: 30px;
  padding: 10px 20px;
  &:hover {
    background: #568a60; /* Change the background color on hover */
  }

  &:active {
    background: #718875; /* Change the background color on click */
  }
`;

const AddressModal: React.FC<AddressModalProps> = ({
  open,
  setIsAddressModalOpen,
  selectedAddress,
  setSelectedAddress,
  addressList,
  setAddressList,
}) => {
  /**
   * Map related state values
   */
  const DefaultLocation = { lat: 13.067439, lng: 80.237617 };
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [location, setLocation] = useState(defaultLocation);

  const userDetail = useSelector((state: RootState) => state.userDetail);

  const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT;
  const accessToken = sessionStorage.getItem("access_token");

  const [isPrimary, setIsPrimary] = useState(false);

  const [formData, setFormData] = useState<FormData>({
    title: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  });
  const [errors, setErrors] = useState<FormErrorData>({
    title: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    location: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name as string]: value as string,
    }));
    setErrors({ ...errors, [name as string]: "" });
  };

  const handleSubmit = async () => {
    if (handleValidation()) {
      try {
        let url: string = `${apiEndpoint}/users/addresses`;

        const requestBody: any = {
          title: formData.title,
          address: formData.street,
          city: formData.city,
          state: formData.state,
          country: formData.country,
          pincode: formData.pincode,
          latitude: location.lat,
          longitude: location.lng,
          address_type: isPrimary ? "PRIMARY" : "SECONDARY",
        };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        if (data) {
          let existingAddressList = [...addressList];
          if (isPrimary) {
            existingAddressList.map((add) => {
              add.address_type = "SECONDARY";
              return add;
            });
          }
          const newAddressArrayList = [data.address, ...existingAddressList];

          setAddressList(newAddressArrayList);

          setFormData({
            title: "",
            street: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
          });
          toast.success(`New address added successfully`, {
            position: "top-right",
            autoClose: 5000,
          });

          setIsAddressModalOpen(false);
          setSelectedAddress(data.address);
        } else {
          toast.warning(`Unable to add new address. Please try again`, {
            position: "top-right",
            autoClose: 5000,
          });
        }
      } catch (error) {
        console.error("Error fetching Services:", error);
      }
    }
  };

  const handleValidation = () => {
    const newErrors = {
      title: "",
      street: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
      location: "",
    };

    if (!formData.title) {
      newErrors.title = "Title is required";
    }

    if (!formData.street) {
      newErrors.street = "Street is required";
    }

    if (!formData.city) {
      newErrors.city = "City is required";
    }

    if (!formData.state) {
      newErrors.state = "State is required";
    }
    if (!formData.country) {
      newErrors.country = "Country is required";
    }

    if (!formData.pincode) {
      newErrors.pincode = "Pincode is required";
    }

    if (
      location.lat === defaultLocation.lat ||
      location.lng === defaultLocation.lng
    ) {
      newErrors.location = "Map location is required";
    }
    setErrors(newErrors);

    return (
      !newErrors.pincode &&
      !newErrors.state &&
      !newErrors.city &&
      !newErrors.street &&
      !newErrors.title &&
      !newErrors.location
    );
  };

  const updatePrimary = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPrimary(event.target.checked);
  };

  useEffect(() => {
    //API call to fetch customer address list
    if (userDetail?.addresses.length > 0) {
      setAddressList(
        userDetail?.addresses && userDetail?.addresses?.length > 0
          ? userDetail.addresses.reverse()
          : []
      );
    }
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setIsAddressModalOpen(false);
      }}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
          Add new address
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsPrimary(false);
              setIsAddressModalOpen(false);
              setErrors({
                title: "",
                street: "",
                city: "",
                state: "",
                country: "",
                pincode: "",
                location: "",
              });
              setFormData({
                title: "",
                street: "",
                city: "",
                state: "",
                country: "",
                pincode: "",
              });
            }}
            sx={{ position: "absolute", right: 2, top: 8 }}
          >
            <CloseIcon sx={{ fontSize: "1.5rem" }} />
          </IconButton>
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ p: 3, mb: 1 }}>
          {/* <Typography variant="h6" sx={{ fontSize: '16px' }}>
            Add new address
          </Typography> */}
          <form>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  label="Address title *"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <ErrorLabel>{errors.title}</ErrorLabel>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      size="medium"
                      defaultChecked
                      onChange={updatePrimary}
                      checked={isPrimary}
                    />
                  }
                  label="Default"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Street *"
                  name="street"
                  value={formData.street}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <ErrorLabel>{errors.street}</ErrorLabel>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="City *"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <ErrorLabel>{errors.city}</ErrorLabel>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="State *"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <ErrorLabel>{errors.state}</ErrorLabel>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Country *"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <ErrorLabel>{errors.country}</ErrorLabel>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Pin Code *"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <ErrorLabel>{errors.pincode}</ErrorLabel>
              </Grid>
            </Grid>
          </form>
        </Box>
        <Grid container spacing={2} style={{ padding: "0px 20px" }}>
          <Grid item xs={12}>
            Select Location *<ErrorLabel>{errors.location}</ErrorLabel>
          </Grid>

          <Grid sx={{ width: "100%" }}>
            <Location
              address={formData}
              setDefaultLocation={setDefaultLocation}
              location={location}
              setLocation={setLocation}
              defaultLocation={defaultLocation}
              DefaultLocation={DefaultLocation}
              flag="new"
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ border: "none", mb: 3, mr: 2 }}>
        <Button
          variant="outlined"
          onClick={() => {
            setIsPrimary(false);
            setIsAddressModalOpen(false);
            setErrors({
              title: "",
              street: "",
              city: "",
              state: "",
              country: "",
              pincode: "",
              location: "",
            });
            setFormData({
              title: "",
              street: "",
              city: "",
              state: "",
              country: "",
              pincode: "",
            });
          }}
          color="primary"
          sx={{ padding: "10px 30px !important" }}
        >
          Cancel
        </Button>

        <ActionButton
          type="button"
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          sx={{ padding: "10px 30px !important" }}
        >
          Save
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddressModal;
