import {
  Box,
  Fade,
  Modal,
  Typography,
  Button,
  FormControl,
  Select,
  styled,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import { postTimeSlot, postWorkSlot } from "../../action/timeline";
import {
  errorNotification,
  successNotification,
} from "../../components/shared/Toast/toast";
import { selectedSlots } from "../../types";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

type Props = {
  slot: boolean;
  setSlot: (slot: boolean) => void;
  style: object;
  selectedService: string;
  timeLineData: Function;
  workSlot: boolean;
  setWorkSlot: (slot: boolean) => void;
};

export default function ScheduleInspection({
  slot,
  setSlot,
  style,
  selectedService,
  timeLineData,
  workSlot,
  setWorkSlot,
}: Props) {
  const [selectedSlots, setSelectedSlots] = useState<selectedSlots>({
    date: [],
    time: [],
  });
  const today = new Date(); // Get today's date
  today.setDate(today.getDate() + 1); // Add 1 day to get tomorrow's date
  const formattedDate = today.toISOString().split("T")[0];

  const PrimaryButton = styled(Button)`
    margin-left: 20px;
    text-align: left;
    color: #ffffff;
    background: #71a187;
    font-size: 16px;
    font: Kufam;
    font-weight: 600;
    border-radius: 30px;
    padding: 10px 20px;
    &:hover {
      background: #568a60; /* Change the background color on hover */
    }
    &:active {
      background: #718875; /* Change the background color on click */
    }
  `;

  const slots =
    selectedSlots &&
    Object.keys(selectedSlots.date).map((key: any) => {
      const date = selectedSlots.date[key];
      const timeSlot = selectedSlots.time[key];

      return {
        date,
        time_slot: timeSlot,
      };
    });

  const handleTimeChange = (index: number, value: string) => {
    setSelectedSlots((prev) => ({
      ...prev,
      time: {
        ...prev.time,
        [index]: value,
      },
    }));
  };

  const TimeSlot = [
    { label: "09:00 AM - 10:00 AM", value: "09:00 AM - 10:00 AM" },
    { label: "10:00 AM - 11:00 AM", value: "10:00 AM - 11:00 AM" },
    { label: "11:00 AM - 12:00 PM", value: "11:00 AM - 12:00 PM" },
    { label: "01:00 PM - 02:00 PM", value: "01:00 PM - 02:00 PM" },
    { label: "02:00 PM - 03:00 PM", value: "02:00 PM - 03:00 PM" },
    { label: "03:00 PM - 04:00 PM", value: "03:00 PM - 04:00 PM" },
    { label: "04:00 PM - 05:00 PM", value: "04:00 PM - 05:00 PM" },
    { label: "05:00 PM - 06:00 PM", value: "05:00 PM - 06:00 PM" },
    { label: "06:00 PM - 07:00 PM", value: "06:00 PM - 07:00 PM" },
  ];

  return (
    <>
      <ToastContainer />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={slot}
        onClose={() => {
          setSlot(false);
          setWorkSlot(false);
        }}
        closeAfterTransition
        style={{ overflow: "auto" }}
      >
        <Fade in={slot}>
          <Box sx={{
            ...style,
            padding: '1rem',
            maxHeight: '90vh',
            position: 'fixed',
            overflow: 'auto'
          }}>
            <Typography
              id="transition-modal-title"
              sx={{ fontSize: 32, fontWeight: 600 }}
            >
              {workSlot
                ? `Choose date slots to start work`
                : `Schedule Inspection`}
            </Typography>
            <Typography
              id="transition-modal-title"
              sx={{ fontSize: 16, fontWeight: 400 }}
            >
              {workSlot
                ? ` Please choose 3 date slots`
                : ` Please choose 3 date and time slots`}
            </Typography>
            {[1, 2, 3].map((index) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    columnGap: {
                      xs: 2, sm: 2, md: 6, lg: 6, xl: 6
                    },
                    flexDirection: {
                      xs: 'column', sm: 'row'
                    },
                    my: 4,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      maxWidth: {xs: '100%', sm: '180px'}
                    }}
                  >
                    <Typography sx={{ fontWeight: 400, fontSize: 16 }}>
                      Please select date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{ width: "100%", mt: 1, mb: 2 }}
                        format="DD-MM-YYYY"
                        views={["year", "month", "day"]}
                        onChange={(date: any) => {
                          if (date) {
                            const dayjsObject = dayjs(date);
                            const formatteDayjs =
                              dayjsObject.format("YYYY-MM-DD");
                            setSelectedSlots((prev: any) => ({
                              ...prev,
                              date: {
                                ...prev.date,
                                [index]: formatteDayjs,
                              },
                            }));
                          }
                        }}
                        disablePast
                      />
                    </LocalizationProvider>
                  </Box>

                  {!workSlot && (
                    <Box>
                      <Typography sx={{ fontWeight: 400, fontSize: 16 }}>
                        Please select time
                      </Typography>
                      <FormControl fullWidth sx={{ height: 30 }}>
                        <Select
                          value={selectedSlots.time[index]}
                          onChange={(e) => {
                            handleTimeChange(index, e.target.value);
                          }}
                          sx={{ mt: 1, height: "55px" }}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {TimeSlot.map((item) => {
                            return (
                              <MenuItem value={item.value}>
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                </Box>
              );
            })}

            <Box
              sx={{
                display: "flex",
                pt: 2,
                justifyContent: 'center'
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  minWidth: '125px',
                  p: 0
                }}
                onClick={() => {
                  setSlot(false);
                  setWorkSlot(false);
                  setSelectedSlots({ date: [], time: [] });
                }}
              >
                Close
              </Button>
              <PrimaryButton
                variant="contained"
                sx={{
                  minWidth: '125px',
                  p: 0
                }}
                onClick={() => {
                  if (workSlot) {
                    postWorkSlot(selectedService, {
                      slots,
                    })
                      .then(() => {
                        successNotification("Work slots created successfully.");
                        setSlot(false);
                        setWorkSlot(false);
                        timeLineData(selectedService);
                        setSelectedSlots({
                          date: [],
                          time: [],
                        });
                      })
                      .catch(() => {
                        errorNotification(
                          "Error in creating the work slots.Please try again"
                        );
                      });
                  } else
                    postTimeSlot(selectedService, {
                      slots,
                    })
                      .then(() => {
                        successNotification(
                          "Inspection slots created successfully."
                        );
                        setSlot(false);
                        setWorkSlot(false);
                        timeLineData(selectedService);
                        setSelectedSlots({
                          date: [],
                          time: [],
                        });
                      })
                      .catch(() => {
                        errorNotification(
                          "Error in creating the insecption slots.Please try again"
                        );
                      });
                }}
              >
                Confirm Slots
              </PrimaryButton>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
